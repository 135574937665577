<template>
  <div class="">
    <div v-if="this.$route.name === pageName" class="reports-cards">
      <div class="reports-card">
        <router-link :to="{ name: 'Activities' }" class="">
          <img src="@/assets/images/type.svg" />
          {{ $t("setting.others.activities") }}
        </router-link>
      </div>
      <div class="reports-card">
        <router-link :to="{ name: 'EmployeeRoles' }" class="">
          <img src="@/assets/images/employees.svg" />
          {{ $t("setting.others.employeeRoles") }}
        </router-link>
      </div>
      <!-- <div class="reports-card">
        <router-link :to="{ name: 'ClientRoles' }" class="">
          <img src="@/assets/images/clients.svg" />
          {{ $t("setting.others.clientRoles") }}
        </router-link>
      </div> -->
      <div class="reports-card">
        <router-link :to="{ name: 'Countries' }" class="">
          <img src="@/assets/images/country.svg" />
          {{ $t("setting.others.countries") }}
        </router-link>
      </div>
      <div class="reports-card">
        <router-link :to="{ name: 'Governates' }" class="">
          <img src="@/assets/images/governate.svg" />
          {{ $t("setting.others.governates") }}
        </router-link>
      </div>
      <div class="reports-card">
        <router-link :to="{ name: 'Cities' }" class="">
          <img src="@/assets/images/city.svg" />
          {{ $t("setting.others.cities") }}
        </router-link>
      </div>
      <div class="reports-card">
        <router-link :to="{ name: 'Districts' }" class="">
          <img src="@/assets/images/district.svg" />
          {{ $t("setting.others.districts") }}
        </router-link>
      </div>
      <!--  <div class="reports-card">
        <router-link :to="{ name: 'InsuranceCompanies' }" class="">
          <img src="@/assets/images/insurance-companies.svg" />
          {{ $t("setting.others.insuranceCompanies") }}
        </router-link>
      </div> -->

      <!-- <div class="reports-card">
        <router-link :to="{ name: 'ContractorRoles' }" class="">
          <img src="@/assets/images/contractors.svg" />
          {{ $t("setting.others.constractorRoles") }}
        </router-link>
      </div> -->
      <!-- <div class="reports-card">
        <router-link :to="{ name: 'NewsCategories' }" class="">
          <img src="@/assets/images/category.svg" />
          {{ $t("pages.NewsCategories") }}
        </router-link>
      </div> -->
      <div class="reports-card">
        <router-link :to="{ name: 'ExpensesTypes' }" class="">
          <img src="@/assets/images/expenses-types.svg" />
          {{ $t("expensesTypes") }}
        </router-link>
      </div>
      <div class="reports-card">
        <router-link :to="{ name: 'RevenuesTypes' }" class="">
          <img src="@/assets/images/revenues-types.svg" />
          {{ $t("revenuesTypes") }}
        </router-link>
      </div>
      <div class="reports-card">
        <router-link :to="{ name: 'PaymentMethodes' }" class="">
          <img src="@/assets/images/method-paids.svg" />
          {{ $t("methodPaids") }}
        </router-link>
      </div>
    </div>
    <router-view v-else :key="$router.path" />
  </div>
</template>

<script>
export default {
  name: "SettingsOther",
  components: {},
  data() {
    return {
      pageName: "SettingsOther",
    };
  },
  methods: {},
  watch: {
    $route() {},
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
