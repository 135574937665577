<template>
  <div class="col-md-6 col-lg-4">
    <figure
      class="media-card"
      @mouseover="showAllActions"
      @mouseleave="hideAllActions"
    >
      <router-link :to="`#`" :title="$t('info')" class="mainLink">
        <!-- :to="`/place-media-item/${placeMediaData.placeMediaToken}`" -->
        <img
          v-if="
            placeMediaData.placeMediaTypeToken == imageToken ||
            placeMediaData.placeMediaTypeToken == ''
          "
          class="mainImg"
          :src="mediaSrc"
          :onerror="`this.src='${defaultImg}'`"
          alt="Image"
        />
        <b-icon
          v-if="placeMediaData.placeMediaTypeToken == videoToken"
          icon="play-circle"
          animation="throb"
          variant="danger"
          scale="0.9"
          font-scale="5"
          id="play-icon"
        ></b-icon>

        <video
          v-if="placeMediaData.placeMediaTypeToken == videoToken"
          class="video-container"
        >
          <source
            :src="mediaSrc"
            type="video/mp4"
            :onerror="`this.src='${defaultImg}'`"
          />
          Your browser does not support the video tag.
        </video>
      </router-link>
      <footer v-show="showActions">
        <div class="actions">
          <router-link
            :to="`/places/edit-place-media/${placeMediaData.placeMediaToken}`"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <button
            v-b-modal.PlaceMediaDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setPlaceMediaData(placeMediaData)"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
        </div>
      </footer>
      <router-link
        v-if="placeMediaData.placeMediaNameCurrent"
        :to="`#`"
        :title="placeMediaData.placeMediaNameCurrent"
      >
        <!-- :to="`/place-media-item/${placeMediaData.placeMediaToken}`" -->
        <figcaption>
          <h3 class="cut-2line">{{ placeMediaData.placeMediaNameCurrent }}</h3>
        </figcaption>
      </router-link>
    </figure>
  </div>
</template>

<script>
import {
  BASE_URL,
  NEWS_MEDIA_TYPE_TOKEN_IMAGE,
  NEWS_MEDIA_TYPE_TOKEN_VIDEO,
} from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/media.svg";

export default {
  name: "PlaceMediaCard",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: DEFAULT_IMG,
      imageToken: NEWS_MEDIA_TYPE_TOKEN_IMAGE,
      videoToken: NEWS_MEDIA_TYPE_TOKEN_VIDEO,
      showActions: false,
    };
  },
  props: ["placeMediaData"],
  methods: {
    setPlaceMediaData() {
      this.$emit("setPlaceMediaData", this.placeMediaData);
    },
    showAllActions() {
      this.showActions = true;
    },
    hideAllActions() {
      this.showActions = false;
    },
  },
  computed: {
    mediaSrc: function () {
      if (this.placeMediaData.placeMediaPath) {
        return BASE_URL + this.placeMediaData.placeMediaPath;
      }
      return this.defaultImg;
    },
  },
};
</script>
