<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdatePlacesWorkPlan()"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'placeWorkPlanCode'"
            :value="placesWorkPlanData.placeWorkPlanCode"
            :title="$t('placesWorkPlans.placeWorkPlanCode')"
            :imgName="'code.svg'"
            v-on:changeValue="placesWorkPlanData.placeWorkPlanCode = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'placeWorkPlanNameAr'"
            :value="placesWorkPlanData.placeWorkPlanNameAr"
            :title="$t('placesWorkPlans.nameAr')"
            :imgName="'places-work-plans.svg'"
            v-on:changeValue="placesWorkPlanData.placeWorkPlanNameAr = $event"
          />
          <CustomInput
            ref="placeWorkPlanNameEn"
            :className="'col-md-6'"
            :id="'placeWorkPlanNameEn'"
            :value="placesWorkPlanData.placeWorkPlanNameEn"
            :title="$t('placesWorkPlans.nameEn')"
            :imgName="'places-work-plans.svg'"
            v-on:changeValue="placesWorkPlanData.placeWorkPlanNameEn = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'placeWorkPlanNameUnd'"
            :value="placesWorkPlanData.placeWorkPlanNameUnd"
            :title="$t('placesWorkPlans.nameUnd')"
            :imgName="'places-work-plans.svg'"
            v-on:changeValue="placesWorkPlanData.placeWorkPlanNameUnd = $event"
          />

          <CustomSelectBox
            :className="'col-12'"
            :id="'placeToken'"
            :value="placesWorkPlanData.placeToken"
            :options="placeTokenOptions"
            v-on:changeValue="placesWorkPlanData.placeToken = $event"
            :title="$t('places.select')"
            :imgName="'places.svg'"
          />

          <DatePicker
            :className="'col-md-6 col-lg-6'"
            :id="'startActiveDate'"
            :value="placesWorkPlanData.startActiveDate"
            :title="$t('placesWorkPlans.startActiveDate')"
            v-on:changeValue="placesWorkPlanData.startActiveDate = $event"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6 col-lg-6'"
            :id="'startActiveTime'"
            :value="placesWorkPlanData.startActiveTime"
            :title="$t('placesWorkPlans.startActiveTime')"
            v-on:changeValue="placesWorkPlanData.startActiveTime = $event"
            :language="language"
          />
          <DatePicker
            :className="'col-md-6 col-lg-6'"
            :id="'endActiveDate'"
            :value="placesWorkPlanData.endActiveDate"
            :title="$t('placesWorkPlans.endActiveDate')"
            v-on:changeValue="placesWorkPlanData.endActiveDate = $event"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6 col-lg-6'"
            :id="'endActiveTime'"
            :value="placesWorkPlanData.endActiveTime"
            :title="$t('placesWorkPlans.endActiveTime')"
            v-on:changeValue="placesWorkPlanData.endActiveTime = $event"
            :language="language"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'placeWorkPlanDescriptionAr'"
            :value="placesWorkPlanData.placeWorkPlanDescriptionAr"
            v-on:changeValue="
              placesWorkPlanData.placeWorkPlanDescriptionAr = $event
            "
            :title="$t('placesWorkPlans.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'placeWorkPlanDescriptionEn'"
            :value="placesWorkPlanData.placeWorkPlanDescriptionEn"
            v-on:changeValue="
              placesWorkPlanData.placeWorkPlanDescriptionEn = $event
            "
            :title="$t('placesWorkPlans.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'placeWorkPlanDescriptionUnd'"
            :value="placesWorkPlanData.placeWorkPlanDescriptionUnd"
            v-on:changeValue="
              placesWorkPlanData.placeWorkPlanDescriptionUnd = $event
            "
            :title="$t('placesWorkPlans.descriptionUnd')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'placeWorkPlanNotes'"
            :value="placesWorkPlanData.placeWorkPlanNotes"
            v-on:changeValue="placesWorkPlanData.placeWorkPlanNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'PlacesWorkPlans' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";
import { STATUS } from "@/utils/constants";
import Place from "@/models/places/places/Place";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";

export default {
  name: "PlacesWorkPlanForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
    DatePicker,
    TimePicker,
    CustomSelectBox,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      placeTokenOptions: [],
      placeData: new Place(),
    };
  },
  props: ["placesWorkPlanData", "submitName"],
  methods: {
    async getDialogOfPlaces() {
      this.isLoading = true;
      this.placeTokenOptions = [];
      this.placeTokenOptions.push({
        value: "",
        text: this.$t("places.select"),
      });
      try {
        const response = await this.placeData.getDialogOfPlaces(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.placeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.placeTokenOptions = null;
      }
      this.isLoading = false;
    },
    async addOrUpdatePlacesWorkPlan() {
      this.$emit("addOrUpdatePlacesWorkPlan");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfPlaces();
  },
};
</script>

<style lang="scss"></style>
