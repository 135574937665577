<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <CountryForm
      v-if="!isLoading && !isCatch"
      :countryData="countryData"
      :submitName="$t('edit')"
      v-on:addOrUpdateCountry="updateCountry"
    />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";
import CountryForm from "@/components/settings/settingsOther/countries/CountryForm.vue";
import Country from "@/models/settings/settingsOther/countries/Country";
export default {
  name: "CountryEdit",
  mixins: [createToastMixin],
  components: {
    CountryForm,
  },
  data() {
    return {
      countryData: new Country(),
      countryToken: this.$route.params.countryToken,
    };
  },
  methods: {
    async getCountryDetails() {
      this.isLoading = true;
      this.countryData.countryToken = this.countryToken;
      try {
        let response = await this.countryData.getCountryDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.countryData.fillData(response.data.countryData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.countryData = null;
        }
      } catch (error) {
        this.countryData = null;
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = "noInternet.svg";
      }
      this.isLoading = false;
    },
    async updateCountry() {
      this.isLoading = true;
      try {
        let response = await this.countryData.addOrUpdateCountry(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "Countries" });
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          if (response.data.errors) {
            console.log("from add method");
            console.log(response.data.errors);
            this.validatinoErrors = response.data.errors;
            console.log(this.validatinoErrors[0]);
          }
        }
      } catch (e) {
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getCountryDetails();
  },
};
</script>

<style scoped lang="scss"></style>
