<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateActivity"
    >
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          :onerror="`this.backgroundImage = url(${defaultImg})`"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'activityNameAr'"
            :value="activityData.activityNameAr"
            :title="$t('activities.nameAr')"
            :imgName="'type.svg'"
            v-on:changeValue="activityData.activityNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'activityNameEn'"
            :value="activityData.activityNameEn"
            :title="$t('activities.nameEn')"
            :imgName="'type.svg'"
            v-on:changeValue="activityData.activityNameEn = $event"
          />
          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'activityNameUnd'"
            :value="activityData.activityNameUnd"
            :title="$t('activities.nameUnd')"
            :imgName="'type.svg'"
            v-on:changeValue="activityData.activityNameUnd = $event"
          />
          <TextArea
            :className="'col-md-6 col-lg-6'"
            :id="'activityDescriptionAr'"
            :value="activityData.activityDescriptionAr"
            :title="$t('activities.descriptionAr')"
            :imgName="'description.svg'"
            v-on:changeValue="activityData.activityDescriptionAr = $event"
          />
          <TextArea
            :className="'col-md-6 col-lg-6'"
            :id="'activityDescriptionEn'"
            :value="activityData.activityDescriptionEn"
            :title="$t('activities.descriptionEn')"
            :imgName="'description.svg'"
            v-on:changeValue="activityData.activityDescriptionEn = $event"
          />
          <TextArea
            :className="'col-md-6 col-lg-6'"
            :id="'activityDescriptionUnd'"
            :value="activityData.activityDescriptionUnd"
            :title="$t('activities.descriptionUnd')"
            :imgName="'description.svg'"
            v-on:changeValue="activityData.activityDescriptionUnd = $event"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'activityNotes'"
            :value="activityData.activityNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="activityData.activityNotes = $event"
          />

          <CustomInput
            :className="'col-md-6 col-lg-6'"
            :id="'activityCode'"
            :value="activityData.activityCode"
            :title="$t('activities.code')"
            :imgName="'number.svg'"
            v-on:changeValue="activityData.activityCode = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Activities' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import DEFAULT_IMG from "@/assets/images/type.svg";
import { setDataMultiLang } from "@/utils/functions";

import CustomInput from "@/components/general/CustomInput.vue";

import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ActivityForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,

    CustomInput,

    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,
    };
  },
  props: ["activityData", "imageSrc", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.activityData.image = file[0];
      } else {
        this.imageSrc = DEFAULT_IMG;
        this.activityData.image = "";
      }
      // console.log(this.imageSrc);
    },

    async addOrUpdateActivity() {
      this.$emit("addOrUpdateActivity");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss"></style>
