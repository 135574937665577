import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class RevenueType {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.revenuesTypeToken = "";
    this.revenuesTypeNameCurrent = "";
    this.revenuesTypeNameEn = "";
    this.revenuesTypeNameAr = "";
    this.revenuesTypeNameUnd = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.revenuesTypeDescriptionCurrent = "";
    this.revenuesTypeDescriptionEn = "";
    this.revenuesTypeDescriptionAr = "";
    this.revenuesTypeDescriptionUnd = "";
    this.revenuesTypeNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.revenuesTypeArchiveStatus = "";
  }
  fillData(data) {
    this.revenuesTypeToken = data.revenuesTypeToken;
    this.revenuesTypeNameCurrent = data.revenuesTypeNameCurrent;
    this.revenuesTypeNameEn = data.revenuesTypeNameEn;
    this.revenuesTypeNameAr = data.revenuesTypeNameAr;
    this.revenuesTypeNameUnd = data.revenuesTypeNameUnd;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.revenuesTypeDescriptionCurrent = data.revenuesTypeDescriptionCurrent;
    this.revenuesTypeDescriptionEn = data.revenuesTypeDescriptionEn;
    this.revenuesTypeDescriptionAr = data.revenuesTypeDescriptionAr;
    this.revenuesTypeDescriptionUnd = data.revenuesTypeDescriptionUnd;
    this.revenuesTypeNotes = data.revenuesTypeNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.revenuesTypeArchiveStatus = data.revenuesTypeArchiveStatus;
  }

  async getAllRevenuesTypes(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch
  ) {
    try {
      let response = await axios.get(
        `/RevenuesTypes/GetAllRevenuesTypes/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getRevenueTypeDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/RevenuesTypes/GetRevenuesTypeDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&revenuesTypeToken=${this.revenuesTypeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfRevenuesTypes(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/RevenuesTypes/GetDialogOfRevenuesTypes?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateRevenueType(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      revenuesTypeToken: this.revenuesTypeToken,
      revenuesTypeNameCurrent: this.revenuesTypeNameCurrent,
      revenuesTypeNameEn: this.revenuesTypeNameEn,
      revenuesTypeNameAr: this.revenuesTypeNameAr,
      revenuesTypeNameUnd: this.revenuesTypeNameUnd,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      revenuesTypeDescriptionCurrent: this.revenuesTypeDescriptionCurrent,
      revenuesTypeDescriptionEn: this.revenuesTypeDescriptionEn,
      revenuesTypeDescriptionAr: this.revenuesTypeDescriptionAr,
      revenuesTypeDescriptionUnd: this.revenuesTypeDescriptionUnd,
      revenuesTypeNotes: this.revenuesTypeNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      revenuesTypeArchiveStatus: this.revenuesTypeArchiveStatus,
    };

    try {
      let response = "";
      if (this.revenuesTypeToken == "" || this.revenuesTypeToken == undefined) {
        response = await axios.post(`/RevenuesTypes/AddRevenuesType`, data);
      } else {
        response = await axios.post(`/RevenuesTypes/UpdateRevenuesType`, data);
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveRevenueType(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      revenuesTypeToken: this.revenuesTypeToken,
    };

    try {
      let response = await axios.post(
        `/RevenuesTypes/ArchiveRevenuesType`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
