export const EMPLOYEE_TYPE = {
  Employee: "EMT-1",
  Admin: "EMT-2",
  FounderOfSystem: "EMT-3",
  Receptionist: "EMT-4",
  Doctor: "EMT-5",
};

export const ROLE_TYPE = {
  Employee: "RTT-1",
  Client: "RTT-2",
  Contractor: "RTT-3",
};

export const USER_POINTS_TYPE = {
  Client: "UPT-1",
  Employee: "UPT-2",
  Contractor: "UPT-3",
};
export const PLACE_SCHEDULE_TYPE = {
  fixed: "PL_SH-1",
  flexible: "PL_SH-2",
};

export const RESERVATION_SCHEDULE_STATUS = {
  waitingApproval: "RSS-1", // waiting Approval في انتظار الموافقة
  Approved: "RSS-2", // Approved موافق عليها
  WaitingApprovalCancellation: "RSS-3", // Waiting Approval Cancellation في إنتظار قبول الإلغاء
  CanceledAfterApproval: "RSS-4", // Canceled After Approval تم الإلغاء بعد الموافقة
  CanceledBeforeApproval: "RSS-5", // Canceled Before Approval تم الإلغاء قبل الموافقة
};
