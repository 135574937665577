import PaginationData from "@/models/general/PaginationData";
import PlacePart from "./PlacePart";
import PlacesPartsFilter from "./PlacesPartsFilter";

export default class PlacesParts {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.placesPartsData = [];
    this.placePart = new PlacePart();
    this.filterData = new PlacesPartsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.placesPartsData = data.placePartsData;
  }
}

