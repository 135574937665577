<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="userRolesData">
      <UserRolesTable
        :userRolesData="userRolesData"
        :userRoleData="userRoleData"
        v-on:setUserRoleData="setUserRoleData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <UserRoleInfo :userRoleData="userRoleData" />
      <UserRoleDelete
        :userRoleData="userRoleData"
        v-on:refresh="getAllUserRoles()"
      />
    </div>

    <UserRoleBtns
      :textSearch="textSearch"
      v-on:search="search"
      :userRoleData="userRoleData"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import { ROLE_TYPE } from "@/utils/constantLists";
import UserRolesTable from "@/components/settings/settingsOther/userRoles/UserRolesTable.vue";
import UserRoleInfo from "@/components/settings/settingsOther/userRoles/UserRoleInfo.vue";
import UserRoleDelete from "@/components/settings/settingsOther/userRoles/UserRoleDelete.vue";
import UserRoleBtns from "@/components/settings/settingsOther/userRoles/UserRoleBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import UserRole from "@/models/settings/settingsOther/userRoles/UserRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ContractorRoles",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    UserRolesTable,
    UserRoleInfo,
    UserRoleDelete,
    UserRoleBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      filterStatus: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      roleTypeToken: ROLE_TYPE.Contractor,
      userRolesData: null,
      userRoleData: new UserRole(),
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllUserRoles();
    },
    search(text) {
      this.filterStatus = true;
      this.textSearch = text;
      this.getAllUserRoles();
    },
    async getAllUserRoles() {
      this.isLoading = true;
      this.filterStatus = true;
      try {
        const response = await this.userRoleData.getAllEstablishmentRoles(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.filterStatus,
          this.textSearch,
          this.roleTypeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.userRolesData = response.data.establishmentRolesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.userRolesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.userRolesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.userRolesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        console.log(error);
        this.showMsg(this.$t("errorCatch"));
        this.userRolesData = null;
      }
      this.isLoading = false;
    },
    setUserRoleData($event) {
      this.userRoleData.fillData($event);
      this.userRoleData.userType = "contractor";
    },
  },
  computed: {},
  async created() {
    this.getAllUserRoles();
    this.userRoleData.userType = "contractor";
  },
};
</script>
