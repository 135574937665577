import Vue from "vue";
import VueRouter from "vue-router";
import { ifAuthenticated, ifNotAuthenticated } from "../utils/functions";

import PageTestlayout from "../layout/page-Test-layout.vue";
import TestComponent from "../views/test/testComponent/TestComponent.vue";
import TestGeneral from "../views/test/testGeneral/TestGeneral.vue";
import Test from "../views/user/Test.vue";
import Home from "../views/home/Home.vue";
import Login from "../views/user/Login.vue";
import ClientLogin from "../views/user/ClientLogin.vue";
import SignUp from "../views/user/SignUp.vue";
import EmployeeLogin from "../views/user/EmployeeLogin.vue";
// import ForgetPassword from "../views/user/ForgetPassword.vue";

import Offers from "../views/offers/Offers.vue";
import OfferAdd from "../views/offers/OfferAdd.vue";
import OfferEdit from "../views/offers/OfferEdit.vue";
import OfferItem from "../views/offers/OfferItem.vue";

import Clients from "../views/clients/Clients.vue";
import ClientAdd from "../views/clients/ClientAdd.vue";
import ClientEdit from "../views/clients/ClientEdit.vue";

import PlacesLayout from "../layout/places-layout.vue";
import Places from "../views/places/places/Places.vue";
import PlaceAdd from "../views/places/places/PlaceAdd.vue";
import PlaceEdit from "../views/places/places/PlaceEdit.vue";

import PlacesMedias from "../views/places/placesMedias/PlacesMedias.vue";
import PlaceMediaAdd from "../views/places/placesMedias/PlaceMediaAdd.vue";
import PlaceMediaEdit from "../views/places/placesMedias/PlaceMediaEdit.vue";

import PlacesParts from "../views/places/placesParts/PlacesParts.vue";
import PlacePartAdd from "../views/places/placesParts/PlacePartAdd.vue";
// import PlacePartEdit from "../views/places/placesParts/PlacePartEdit.vue";

import PlacesWorkPlans from "../views/places/placesWorkPlans/PlacesWorkPlans.vue";
import PlacesWorkPlanAdd from "../views/places/placesWorkPlans/PlacesWorkPlanAdd.vue";
import PlacesWorkPlanEdit from "../views/places/placesWorkPlans/PlacesWorkPlanEdit.vue";

import PlacesWorkPlanDays from "../views/places/placesWorkPlanDays/PlacesWorkPlanDays.vue";

import Employees from "../views/employees/Employees.vue";
import EmployeeAdd from "../views/employees/EmployeeAdd.vue";
import EmployeeEdit from "../views/employees/EmployeeEdit.vue";

import FinanceLayout from "../layout/finance-layout.vue";
import Treasuries from "../views/finance/treasuries/Treasuries.vue";
import TreasuryAdd from "../views/finance/treasuries/TreasuryAdd.vue";
import TreasuryEdit from "../views/finance/treasuries/TreasuryEdit.vue";

import Expenses from "../views/finance/expenses/Expenses.vue";
import ExpenseAdd from "../views/finance/expenses/ExpenseAdd.vue";

import Revenues from "../views/finance/revenues/Revenues.vue";
import RevenueAdd from "../views/finance/revenues/RevenueAdd.vue";

import TreasuriesTransactions from "../views/finance/treasuriesTransactions/TreasuriesTransactions.vue";
import TreasuryTransactionAdd from "../views/finance/treasuriesTransactions/TreasuryTransactionAdd.vue";

import Settings from "../layout/settings-layout.vue";
import SettingsOther from "../views/settings/settingsOther/SettingsOther.vue";
import Countries from "../views/settings/settingsOther/countries/Countries.vue";
import CountryAdd from "../views/settings/settingsOther/countries/CountryAdd.vue";
import CountryEdit from "../views/settings/settingsOther/countries/CountryEdit.vue";

import Governates from "../views/settings/settingsOther/governates/Governates.vue";
import GovernateAdd from "../views/settings/settingsOther/governates/GovernateAdd.vue";
import GovernateEdit from "../views/settings/settingsOther/governates/GovernateEdit.vue";

import Cities from "../views/settings/settingsOther/cities/Cities.vue";
import CityAdd from "../views/settings/settingsOther/cities/CityAdd.vue";
import CityEdit from "../views/settings/settingsOther/cities/CityEdit.vue";

import Districts from "../views/settings/settingsOther/districts/Districts.vue";
import DistrictAdd from "../views/settings/settingsOther/districts/DistrictAdd.vue";
import DistrictEdit from "../views/settings/settingsOther/districts/DistrictEdit.vue";

import InsuranceCompanies from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanies.vue";
import InsuranceCompanyAdd from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanyAdd.vue";
import InsuranceCompanyEdit from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanyEdit.vue";

import EmployeeRoles from "../views/settings/settingsOther/userRoles/employeeRoles/EmployeeRoles.vue";
import EmployeeRoleAdd from "../views/settings/settingsOther/userRoles/employeeRoles/EmployeeRoleAdd.vue";
import EmployeeRoleEdit from "../views/settings/settingsOther/userRoles/employeeRoles/EmployeeRoleEdit.vue";

import ClientRoles from "../views/settings/settingsOther/userRoles/clientRoles/ClientRoles.vue";
import ClientRoleAdd from "../views/settings/settingsOther/userRoles/clientRoles/ClientRoleAdd.vue";
import ClientRoleEdit from "../views/settings/settingsOther/userRoles/clientRoles/ClientRoleEdit.vue";

import ContractorRoles from "../views/settings/settingsOther/userRoles/contractorRoles/ContractorRoles.vue";
import ContractorRoleAdd from "../views/settings/settingsOther/userRoles/contractorRoles/ContractorRoleAdd.vue";
import ContractorRoleEdit from "../views/settings/settingsOther/userRoles/contractorRoles/ContractorRoleEdit.vue";

import Activities from "../views/settings/settingsOther/activities/Activities.vue";
import ActivityAdd from "../views/settings/settingsOther/activities/ActivityAdd.vue";
import ActivityEdit from "../views/settings/settingsOther/activities/ActivityEdit.vue";

import ExpensesTypes from "../views/settings/settingsOther/expensesTypes/ExpensesTypes.vue";
import ExpenseTypeAdd from "../views/settings/settingsOther/expensesTypes/ExpenseTypeAdd.vue";
import ExpenseTypeEdit from "../views/settings/settingsOther/expensesTypes/ExpenseTypeEdit.vue";

import RevenuesTypes from "../views/settings/settingsOther/revenuesTypes/RevenuesTypes.vue";
import RevenueTypeAdd from "../views/settings/settingsOther/revenuesTypes/RevenueTypeAdd.vue";
import RevenueTypeEdit from "../views/settings/settingsOther/revenuesTypes/RevenueTypeEdit.vue";

import PaymentMethodes from "../views/settings/settingsOther/paymentMethodes/PaymentMethodes.vue";
import PaymentMethodeAdd from "../views/settings/settingsOther/paymentMethodes/PaymentMethodeAdd.vue";
import PaymentMethodeEdit from "../views/settings/settingsOther/paymentMethodes/PaymentMethodeEdit.vue";

import PrivilegesLayout from "../layout/privileges-layout.vue";
import UsersEmployeePrivileges from "../views/privileges/usersPrivileges/UsersEmployeePrivileges.vue";
import UsersClientPrivileges from "../views/privileges/usersPrivileges/UsersClientPrivileges.vue";

import ReportsRoute from "./reports/reports";

Vue.use(VueRouter);

const routes = [
  {
    path: "/test-page",
    component: PageTestlayout,
    children: [
      {
        name: "TestComponent",
        path: "test-component",
        component: TestComponent,
      },
      {
        name: "TestGeneral",
        path: "test-general",
        component: TestGeneral,
      },
      {
        path: "",
        redirect: { name: "TestComponent" },
      },
    ],
  },

  {
    path: "/places",
    component: PlacesLayout,
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "places",
        name: "Places",
        component: Places,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "add-place",
        name: "PlaceAdd",
        component: PlaceAdd,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "edit-place/:placeToken",
        name: "PlaceEdit",
        component: PlaceEdit,
        beforeEnter: ifNotAuthenticated,
      },

      {
        path: "places-medias/:placeToken",
        name: "PlacesMedias",
        component: PlacesMedias,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "add-place-media/:placeToken",
        name: "PlaceMediaAdd",
        component: PlaceMediaAdd,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "edit-place-media/:placeMediaToken",
        name: "PlaceMediaEdit",
        component: PlaceMediaEdit,
        beforeEnter: ifNotAuthenticated,
      },

      {
        path: "places-parts/:placeToken",
        name: "PlacesParts",
        component: PlacesParts,
        beforeEnter: ifNotAuthenticated,
        props: true,
      },
      {
        path: "add-place-part/:placeToken",
        name: "PlacePartAdd",
        component: PlacePartAdd,
        beforeEnter: ifNotAuthenticated,
        props: true,
      },
      // {
      //   path: "edit-place-part/:placePartToken",
      //   name: "PlacePartEdit",
      //   component: PlacePartEdit,
      //   beforeEnter: ifNotAuthenticated,
      // },

      {
        path: "places-work-plans/:placeToken?",
        name: "PlacesWorkPlans",
        component: PlacesWorkPlans,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "add-place-work-plan",
        name: "PlacesWorkPlanAdd",
        component: PlacesWorkPlanAdd,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "edit-place-work-plan/:placeWorkPlanToken",
        name: "PlacesWorkPlanEdit",
        component: PlacesWorkPlanEdit,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "place-work-plan-days/:placeWorkPlanToken/:placeToken?",
        name: "PlacesWorkPlanDays",
        component: PlacesWorkPlanDays,
        beforeEnter: ifNotAuthenticated,
      },
      // {
      //   path: "",
      //   redirect: { name: "Places" },
      // },
    ],
  },

  {
    path: "/privileges",
    component: PrivilegesLayout,
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "users-employee-privileges",
        name: "UsersEmployeePrivileges",
        component: UsersEmployeePrivileges,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "users-client-privileges",
        name: "UsersClientPrivileges",
        component: UsersClientPrivileges,
        beforeEnter: ifNotAuthenticated,
      },
    ],
  },

  {
    path: "/finance",
    component: FinanceLayout,
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "treasuries",
        name: "Treasuries",
        component: Treasuries,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "add-treasury",
        name: "TreasuryAdd",
        component: TreasuryAdd,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "edit-treasury/:establishmentTreasuryToken",
        name: "TreasuryEdit",
        component: TreasuryEdit,
        beforeEnter: ifNotAuthenticated,
      },

      {
        path: "expenses",
        name: "Expenses",
        component: Expenses,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "add-expense",
        name: "ExpenseAdd",
        component: ExpenseAdd,
        beforeEnter: ifNotAuthenticated,
      },

      {
        path: "revenues",
        name: "Revenues",
        component: Revenues,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "add-revenue",
        name: "RevenueAdd",
        component: RevenueAdd,
        beforeEnter: ifNotAuthenticated,
      },

      {
        path: "treasuries-transactions",
        name: "TreasuriesTransactions",
        component: TreasuriesTransactions,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: "add-treasury-transaction",
        name: "TreasuryTransactionAdd",
        component: TreasuryTransactionAdd,
        beforeEnter: ifNotAuthenticated,
      },
    ],
  },

  {
    path: "/settings",
    component: Settings,
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "settings-other",
        name: "SettingsOther",
        component: SettingsOther,
        beforeEnter: ifNotAuthenticated,
        children: [
          {
            path: "countries",
            name: "Countries",
            component: Countries,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-country",
            name: "CountryAdd",
            component: CountryAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-country/:countryToken",
            name: "CountryEdit",
            component: CountryEdit,
            beforeEnter: ifNotAuthenticated,
          },

          {
            path: "governates",
            name: "Governates",
            component: Governates,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-governate",
            name: "GovernateAdd",
            component: GovernateAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-governate/:governorateToken",
            name: "GovernateEdit",
            component: GovernateEdit,
            beforeEnter: ifNotAuthenticated,
          },

          {
            path: "cities",
            name: "Cities",
            component: Cities,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-city",
            name: "CityAdd",
            component: CityAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-city/:cityToken",
            name: "CityEdit",
            component: CityEdit,
            beforeEnter: ifNotAuthenticated,
          },

          {
            path: "districts",
            name: "Districts",
            component: Districts,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-district",
            name: "DistrictAdd",
            component: DistrictAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-district/:districtToken",
            name: "DistrictEdit",
            component: DistrictEdit,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "insurance-companies",
            name: "InsuranceCompanies",
            component: InsuranceCompanies,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-insurance-company",
            name: "InsuranceCompanyAdd",
            component: InsuranceCompanyAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-insurance-company/:insuranceCompanyToken",
            name: "InsuranceCompanyEdit",
            component: InsuranceCompanyEdit,
            beforeEnter: ifNotAuthenticated,
          },

          {
            path: "employee-roles",
            name: "EmployeeRoles",
            component: EmployeeRoles,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-employee-role",
            name: "EmployeeRoleAdd",
            component: EmployeeRoleAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-employee-role/:establishmentRoleToken",
            name: "EmployeeRoleEdit",
            component: EmployeeRoleEdit,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "client-roles",
            name: "ClientRoles",
            component: ClientRoles,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-client-role",
            name: "ClientRoleAdd",
            component: ClientRoleAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-client-role/:establishmentRoleToken",
            name: "ClientRoleEdit",
            component: ClientRoleEdit,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "contractor-roles",
            name: "ContractorRoles",
            component: ContractorRoles,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-contractor-role",
            name: "ContractorRoleAdd",
            component: ContractorRoleAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-contractor-role/:establishmentRoleToken",
            name: "ContractorRoleEdit",
            component: ContractorRoleEdit,
            beforeEnter: ifNotAuthenticated,
          },

          // {
          //   path: "news-categories",
          //   name: "NewsCategories",
          //   component: () => import("../views/settings/settingsOther/newsCategories/NewsCategories.vue"),
          //   beforeEnter: ifNotAuthenticated,
          // },
          // {
          //   path: "add-news-category",
          //   name: "NewsCategoryAdd",
          //   component: () => import("../views/settings/settingsOther/newsCategories/NewsCategoryAdd.vue"),
          //   beforeEnter: ifNotAuthenticated,
          // },
          // {
          //   path: "edit-news-category/:newsCategoryToken",
          //   name: "NewsCategoryEdit",
          //   component: () => import("../views/settings/settingsOther/newsCategories/NewsCategoryEdit.vue"),
          //   beforeEnter: ifNotAuthenticated,
          // },

          {
            path: "activities",
            name: "Activities",
            component: Activities,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-activity",
            name: "ActivityAdd",
            component: ActivityAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-activity/:activityToken",
            name: "ActivityEdit",
            component: ActivityEdit,
            beforeEnter: ifNotAuthenticated,
          },

          {
            path: "expense-types",
            name: "ExpensesTypes",
            component: ExpensesTypes,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-expense-type",
            name: "ExpenseTypeAdd",
            component: ExpenseTypeAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-expense-type/:expensesTypeToken",
            name: "ExpenseTypeEdit",
            component: ExpenseTypeEdit,
            beforeEnter: ifNotAuthenticated,
          },

          {
            path: "revenue-types",
            name: "RevenuesTypes",
            component: RevenuesTypes,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-revenue-type",
            name: "RevenueTypeAdd",
            component: RevenueTypeAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-revenue-type/:revenuesTypeToken",
            name: "RevenueTypeEdit",
            component: RevenueTypeEdit,
            beforeEnter: ifNotAuthenticated,
          },

          {
            path: "payment-methode",
            name: "PaymentMethodes",
            component: PaymentMethodes,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "add-payment-methode",
            name: "PaymentMethodeAdd",
            component: PaymentMethodeAdd,
            beforeEnter: ifNotAuthenticated,
          },
          {
            path: "edit-payment-methode/:paymentMethodeToken",
            name: "PaymentMethodeEdit",
            component: PaymentMethodeEdit,
            beforeEnter: ifNotAuthenticated,
          },
        ],
      },
      // {
      //   path: "",
      //   redirect: { name: "SettingsOther" },
      // },
    ],
  },

  // {
  //   path: "/news",
  //   name: "News",
  //   component: () => import("../views/news/News.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-news",
  //   name: "NewsAdd",
  //   component: () => import("../views/news/NewsAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-news/:newsToken",
  //   name: "NewsEdit",
  //   component: () => import("../views/news/NewsEdit.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/news-item/:newsToken",
  //   name: "NewsItem",
  //   component: () => import("../views/news/NewsItem.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },

  {
    path: "/offers/:placeToken?",
    name: "Offers",
    component: Offers,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-offer",
    name: "OfferAdd",
    component: OfferAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-offer/:offerToken",
    name: "OfferEdit",
    component: OfferEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/offer-item/:offerToken",
    name: "OfferItem",
    component: OfferItem,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-client",
    name: "ClientAdd",
    component: ClientAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-client/:clientToken",
    name: "ClientEdit",
    component: ClientEdit,
    beforeEnter: ifNotAuthenticated,
  },

  // {
  //   path: "/transaction-points",
  //   name: "TransactionPoints",
  //   component: () => import("../views/transactionPoints/TransactionPoints.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/add-transaction-point",
  //   name: "TransactionPointAdd",
  //   component: () => import("../views/transactionPoints/TransactionPointAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },

  {
    path: "/reservations/enquiry-reservations",
    name: "EnquiryReservations",
    component: () =>
      import(
        "../views/reservations/enquiryReservations/EnquiryReservations.vue"
      ),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/reservations/add-enquiry-reservation/:placeToken?/:placePartToken?",
    name: "EnquiryReservationAdd",
    component: () =>
      import(
        "../views/reservations/enquiryReservations/EnquiryReservationAdd.vue"
      ),
    beforeEnter: ifNotAuthenticated,
  },
  // {
  //   path: "/reservations/enquiry-reservation-details/:enquiryReservationToken",
  //   name: "EnquiryReservationDetails",
  //   component: () => import("../views/reservations/enquiryReservations/EnquiryReservationDetails.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },

  {
    path: "/reservations/reservations",
    name: "Reservations",
    component: () =>
      import("../views/reservations/reservations/Reservations.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  // {
  //   path: "/reservations/add-reservation/:enquiryReservationToken",
  //   name: "ReservationAdd",
  //   component: () => import("../views/reservations/reservations/ReservationAdd.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/reservations/reservation-details/:reservationToken",
  //   name: "ReservationDetails",
  //   component: () => import("../views/reservations/reservations/ReservationDetails.vue"),
  //   beforeEnter: ifNotAuthenticated,
  // },

  {
    path: "/reservations/payments",
    name: "Payments",
    component: () => import("../views/reservations/payments/Payments.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/reservations/add-payment",
    name: "PaymentAdd",
    component: () => import("../views/reservations/payments/PaymentAdd.vue"),
    beforeEnter: ifNotAuthenticated,
  },

  // {
  //   path: "/places",
  //   name: "Places",
  //   component: Places,
  // },
  // {
  //   path: "/add-place",
  //   name: "PlaceAdd",
  //   component: PlaceAdd,
  //   beforeEnter: ifNotAuthenticated,
  // },
  // {
  //   path: "/edit-place/:placeToken",
  //   name: "PlaceEdit",
  //   component: PlaceEdit,
  //   beforeEnter: ifNotAuthenticated,
  // },

  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee",
    name: "EmployeeAdd",
    component: EmployeeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-employee/:employeeToken",
    name: "EmployeeEdit",
    component: EmployeeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/client-login",
    name: "ClientLogin",
    component: ClientLogin,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/employee-login",
    name: "EmployeeLogin",
    component: EmployeeLogin,
    beforeEnter: ifAuthenticated,
  },

  ...ReportsRoute,
  // {
  //   path: "/forget-password",
  //   name: "ForgetPassword",
  //   component: ForgetPassword,
  //   beforeEnter: ifAuthenticated,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
