<template>
  <div class="">
    <b-button v-b-toggle.PlacesMedias class="btn btn-lg btn-collapse">
      {{ $t("pages.PlacesMedias") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="PlacesMedias">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeMediaView"
              v-model="usersPrivilegePlaceMedia.placeMediaView"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeMediaView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeMediaAdd"
              v-model="usersPrivilegePlaceMedia.placeMediaAdd"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeMediaAdd">{{ $t("add") }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeMediaUpdate"
              v-model="usersPrivilegePlaceMedia.placeMediaUpdate"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeMediaUpdate">{{
              $t("edit")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeMediaArchive"
              v-model="usersPrivilegePlaceMedia.placeMediaArchive"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeMediaArchive">{{
              $t("archive")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeMediaDelete"
              v-model="usersPrivilegePlaceMedia.placeMediaDelete"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeMediaDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeMediaRestore"
              v-model="usersPrivilegePlaceMedia.placeMediaRestore"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeMediaRestore">{{
              $t("restore")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="placeMediaApproved"
              v-model="usersPrivilegePlaceMedia.placeMediaApproved"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="placeMediaApproved">{{
              $t("approved")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegePlaceMedia",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegePlaceMedia"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
