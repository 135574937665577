<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateTreasury"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'establishmentTreasuryCode'"
            :value="treasuryData.establishmentTreasuryCode"
            :title="$t('treasuryCode')"
            :imgName="'number.svg'"
            v-on:changeValue="treasuryData.establishmentTreasuryCode = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'establishmentTreasuryNameAr'"
            :value="treasuryData.establishmentTreasuryNameAr"
            :title="$t('treasuryNameAr')"
            :imgName="'treasuries.svg'"
            v-on:changeValue="treasuryData.establishmentTreasuryNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'establishmentTreasuryNameEn'"
            :value="treasuryData.establishmentTreasuryNameEn"
            :title="$t('treasuryNameEn')"
            :imgName="'treasuries.svg'"
            v-on:changeValue="treasuryData.establishmentTreasuryNameEn = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'establishmentTreasuryNameUnd'"
            :value="treasuryData.establishmentTreasuryNameUnd"
            :title="$t('treasuryNameUnd')"
            v-on:changeValue="
              treasuryData.establishmentTreasuryNameUnd = $event
            "
            :imgName="'treasuries.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'establishmentTreasuryDescriptionEn'"
            :value="treasuryData.establishmentTreasuryDescriptionEn"
            :title="$t('treasuries.descriptionEn')"
            :imgName="'description.svg'"
            v-on:changeValue="
              treasuryData.establishmentTreasuryDescriptionEn = $event
            "
          />
          <TextArea
            :className="'col-md-6'"
            :id="'establishmentTreasuryDescriptionAr'"
            :value="treasuryData.establishmentTreasuryDescriptionAr"
            :title="$t('treasuries.descriptionAr')"
            :imgName="'description.svg'"
            v-on:changeValue="
              treasuryData.establishmentTreasuryDescriptionAr = $event
            "
          />
          <TextArea
            :className="'col-md-12'"
            :id="'establishmentTreasuryDescriptionUnd'"
            :value="treasuryData.establishmentTreasuryDescriptionUnd"
            :title="$t('treasuries.descriptionUnd')"
            :imgName="'description.svg'"
            v-on:changeValue="
              treasuryData.establishmentTreasuryDescriptionUnd = $event
            "
          />

          <TextArea
            :className="'col-md-12'"
            :id="'establishmentTreasuryNotes'"
            :value="treasuryData.establishmentTreasuryNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="treasuryData.establishmentTreasuryNotes = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Treasuries' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "TreasuryForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["treasuryData", "submitName"],
  methods: {
    async addOrUpdateTreasury() {
      this.$emit("addOrUpdateTreasury");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss"></style>
