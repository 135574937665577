<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li>
        <span class="fab-label">
          <router-link :to="{ name: 'DistrictAdd' }" class="">
            {{ $t("districts.add") }}
          </router-link>
        </span>
        <div class="fab-icon-holder">
          <router-link :to="{ name: 'DistrictAdd' }" class="">
            <img src="@/assets/images/plus.svg" />
          </router-link>
        </div>
      </li>
      <li>
        <span class="fab-label" @click="open">
          {{ $t("filter") }}
        </span>
        <div class="fab-icon-holder" @click="open">
          <img src="@/assets/images/filter.svg" />
        </div>
      </li>
    </ul>

    <vue-bottom-sheet ref="EmployeeBottomSheet">
      <div class="row">
        <div class="form-label-group col-12">
          <input
            name="textSearch"
            id="textSearch"
            type="text"
            v-model="text"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="textSearch">{{ $t("search") }}</label>
          <img src="@/assets/images/search.svg" />
        </div>
        <CustomSelectBox
          :className="'col-md-12'"
          :id="'countryToken'"
          :value="country"
          :options="countryTokenOptions"
          v-on:changeValue="changeGovernate($event)"
          :title="$t('countries.select')"
          :imgName="'country.svg'"
        />
        <CustomSelectBox
          :className="'col-md-12'"
          :id="'governateToken'"
          :value="governate"
          :options="governateTokenOptions"
          v-on:changeValue="changeCity($event)"
          :title="$t('governates.select')"
          :imgName="'governate.svg'"
        />
        <CustomSelectBox
          :className="'col-md-12'"
          :id="'cityToken'"
          :value="city"
          :options="cityTokenOptions"
          v-on:changeValue="city = $event"
          :title="$t('cities.select')"
          :imgName="'city.svg'"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="search"
        >
          {{ $t("search") }}
        </button>
        <button class="btn btn-cancel" @click.prevent="close">
          {{ $t("cancel") }}
        </button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import Country from "@/models/settings/settingsOther/countries/Country";
import Governate from "@/models/settings/settingsOther/governates/Governate";
import City from "@/models/settings/settingsOther/cities/City";
export default {
  name: "DistrictBtns",
  components: { CustomSelectBox },
  props: {
    textSearch: {
      type: String,
      default: "",
    },
    countryToken: {
      type: String,
      default: "",
    },
    governorateToken: {
      type: String,
      default: "",
    },
    cityToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      text: this.textSearch,
      country: this.countryToken,
      countryTokenOptions: [],
      countryData: new Country(),
      governate: this.governorateToken,
      governateTokenOptions: [],
      governateData: new Governate(),
      city: this.cityToken,
      cityTokenOptions: [],
      cityData: new City(),
    };
  },
  methods: {
    open() {
      this.$refs.EmployeeBottomSheet.open();
    },
    close() {
      this.$refs.EmployeeBottomSheet.close();
    },
    search() {
      this.close();
      this.$emit("search", this.text, this.country, this.governate, this.city);
    },
    async getDialogOfCountries() {
      this.countryTokenOptions = [];
      this.countryTokenOptions.push({
        value: "",
        text: this.$t("countries.select"),
      });
      try {
        const response = await this.countryData.getDialogOfCountries(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.countryTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.countryTokenOptions = null;
      }
    },
    async changeGovernate(country) {
      this.country = country;
      this.governate = "";
      this.getDialogOfGovernates(this.country);
    },
    async getDialogOfGovernates(country) {
      this.isLoading = true;
      this.governateTokenOptions = [];
      this.governateTokenOptions.push({
        value: "",
        text: this.$t("governates.select"),
      });
      try {
        const response = await this.governateData.getDialogOfGovernates(
          this.language,
          this.userAuthorizeToken,
          country
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.governateTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.governateTokenOptions = null;
      }
      this.isLoading = false;
    },
    async changeCity(governate) {
      this.governate = governate;
      this.city = "";
      this.getDialogOfCities(this.governate);
    },
    async getDialogOfCities(governateToken) {
      this.isLoading = true;
      this.cityTokenOptions = [];
      this.cityTokenOptions.push({
        value: "",
        text: this.$t("cities.select"),
      });
      try {
        const response = await this.cityData.getDialogOfCities(
          this.language,
          this.userAuthorizeToken,
          governateToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.cityTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.cityTokenOptions = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getDialogOfCountries();
    this.getDialogOfGovernates(this.country);
    this.getDialogOfCities(this.governate);
  },
};
</script>
