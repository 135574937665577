var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.placesWorkPlansDays",modifiers:{"placesWorkPlansDays":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("placesWorkPlansDays"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"placesWorkPlansDays"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysView
            ),expression:"\n              usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"placesWorkPlansDaysView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysView
            )?_vm._i(
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysView
            ,null)>-1:(
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegePlacesWorkPlansDays, "placesWorkPlansDaysView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegePlacesWorkPlansDays, "placesWorkPlansDaysView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegePlacesWorkPlansDays, "placesWorkPlansDaysView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"placesWorkPlansDaysView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysUpdate
            ),expression:"\n              usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"placesWorkPlansDaysUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysUpdate
            )?_vm._i(
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysUpdate
            ,null)>-1:(
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegePlacesWorkPlansDays.placesWorkPlansDaysUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegePlacesWorkPlansDays, "placesWorkPlansDaysUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegePlacesWorkPlansDays, "placesWorkPlansDaysUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegePlacesWorkPlansDays, "placesWorkPlansDaysUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"placesWorkPlansDaysUpdate"}},[_vm._v(_vm._s(_vm.$t("add")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }