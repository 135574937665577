<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <GovernateForm
      v-if="!isLoading && !isCatch"
      :governateData="governorateData"
      :submitName="$t('edit')"
      v-on:addOrUpdateGovernate="updateGovernorate"
    />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";
import GovernateForm from "@/components/settings/settingsOther/governates/GovernateForm.vue";
import Governate from "@/models/settings/settingsOther/governates/Governate";

export default {
  name: "GovernateEdit",
  mixins: [createToastMixin],
  components: {
    GovernateForm,
  },
  data() {
    return {
      governorateData: new Governate(),
      governorateToken: this.$route.params.governorateToken,
    };
  },
  methods: {
    async getGovernateDetails() {
      this.isLoading = true;
      this.governorateData.governorateToken = this.governorateToken;
      try {
        let response = await this.governorateData.getGovernateDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.governorateData.fillData(response.data.governorateData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.governorateData = null;
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.governorateData = null;
      }
      this.isLoading = false;
    },
    async updateGovernorate() {
      this.isLoading = true;
      try {
        let response = await this.governorateData.addOrUpdateGovernate(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "Governates" });
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getGovernateDetails();
  },
};
</script>

<style scoped lang="scss"></style>
