import { PAGE_SIZE } from "@/utils/constants";
import { date2slash } from "@/utils/functions";
import axios from "axios";

export default class TreasuryTransaction {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentTreasuryTransactionToken = "";
    this.establishmentTreasuryTransactionCode = "";
    this.establishmentTreasuryToken = "";
    this.establishmentTreasuryNameCurrent = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.userToken = "";
    this.userNameCurrent = "";
    this.establishmentTreasuryTransactionDate = "";
    this.establishmentTreasuryTransactionMoney = "";
    this.establishmentTreasuryTransactionSerialNumber = "";
    this.establishmentTreasuryTransactionNote = "";
    this.transactionTypeToken = "";
    this.transactionTypeNameCurrent = "";
    this.transactionTypeNameAr = "";
    this.transactionTypeNameEn = "";
    this.transactionTypeNameUnd = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.establishmentExpensesToken = "";
    this.establishmentRevenuesToken = "";
    this.establishmentTreasuryTransactionArchiveStatus = "";
  }
  fillData(data) {
    this.establishmentTreasuryTransactionToken =
      data.establishmentTreasuryTransactionToken;
    this.establishmentTreasuryTransactionCode =
      data.establishmentTreasuryTransactionCode;
    this.establishmentTreasuryToken = data.establishmentTreasuryToken;
    this.establishmentTreasuryNameCurrent =
      data.establishmentTreasuryNameCurrent;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.userToken = data.userToken;
    this.userNameCurrent = data.userNameCurrent;
    this.establishmentTreasuryTransactionDate =
      data.establishmentTreasuryTransactionDate;
    this.establishmentTreasuryTransactionMoney =
      data.establishmentTreasuryTransactionMoney;
    this.establishmentTreasuryTransactionSerialNumber =
      data.establishmentTreasuryTransactionSerialNumber;
    this.establishmentTreasuryTransactionNote =
      data.establishmentTreasuryTransactionNote;
    this.transactionTypeToken = data.transactionTypeToken;
    this.transactionTypeNameCurrent = data.transactionTypeNameCurrent;
    this.transactionTypeNameAr = data.transactionTypeNameAr;
    this.transactionTypeNameEn = data.transactionTypeNameEn;
    this.transactionTypeNameUnd = data.transactionTypeNameUnd;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.establishmentExpensesToken = data.establishmentExpensesToken;
    this.establishmentRevenuesToken = data.establishmentRevenuesToken;
    this.establishmentTreasuryTransactionArchiveStatus =
      data.establishmentTreasuryTransactionArchiveStatus;
  }

  async getTreasuriesTransactions(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    establishmentTreasuryToken,
    transactionTypeToken
  ) {
    try {
      let response = await axios.get(
        `/EstablishmentTreasuryTransactions/GetEstablishmentTreasuryTransactions/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&establishmentTreasuryToken=${establishmentTreasuryToken}&transactionTypeToken=${transactionTypeToken}&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getTreasuryTransactionDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentTreasuryTransactions/GetEstablishmentTreasuryTransactionDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentTreasuryTransactionToken=${this.establishmentTreasuryTransactionToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addTreasuryProcess(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentTreasuryTransactionToken:
        this.establishmentTreasuryTransactionToken,
      establishmentTreasuryTransactionCode:
        this.establishmentTreasuryTransactionCode,
      establishmentTreasuryToken: this.establishmentTreasuryToken,
      establishmentTreasuryNameCurrent: this.establishmentTreasuryNameCurrent,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      userToken: this.userToken,
      userNameCurrent: this.userNameCurrent,
      establishmentTreasuryTransactionDate: date2slash(
        this.establishmentTreasuryTransactionDate
      ),
      establishmentTreasuryTransactionMoney:
        this.establishmentTreasuryTransactionMoney,
      establishmentTreasuryTransactionSerialNumber:
        this.establishmentTreasuryTransactionSerialNumber,
      establishmentTreasuryTransactionNote:
        this.establishmentTreasuryTransactionNote,
      transactionTypeToken: this.transactionTypeToken,
      transactionTypeNameCurrent: this.transactionTypeNameCurrent,
      transactionTypeNameAr: this.transactionTypeNameAr,
      transactionTypeNameEn: this.transactionTypeNameEn,
      transactionTypeNameUnd: this.transactionTypeNameUnd,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      establishmentExpensesToken: this.establishmentExpensesToken,
      establishmentRevenuesToken: this.establishmentRevenuesToken,
      establishmentTreasuryTransactionArchiveStatus:
        this.establishmentTreasuryTransactionArchiveStatus,
    };

    try {
      let response = await axios.post(
        `/EstablishmentTreasuryTransactions/AddTreasuryProcess`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
