var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.transactionPointsHistory",modifiers:{"transactionPointsHistory":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("transactionPointsHistory"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"transactionPointsHistory"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryView
            ),expression:"\n              usersPrivilegeTransactionPointsHistory.transactionPointsHistoryView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"transactionPointsHistoryView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryView
            )?_vm._i(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryView
            ,null)>-1:(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"transactionPointsHistoryView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryIncrease
            ),expression:"\n              usersPrivilegeTransactionPointsHistory.transactionPointsHistoryIncrease\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"transactionPointsHistoryIncrease"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryIncrease
            )?_vm._i(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryIncrease
            ,null)>-1:(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryIncrease
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryIncrease
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryIncrease", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryIncrease", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryIncrease", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"transactionPointsHistoryIncrease"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryDecrease
            ),expression:"\n              usersPrivilegeTransactionPointsHistory.transactionPointsHistoryDecrease\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"transactionPointsHistoryDecrease"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryDecrease
            )?_vm._i(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryDecrease
            ,null)>-1:(
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryDecrease
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeTransactionPointsHistory.transactionPointsHistoryDecrease
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryDecrease", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryDecrease", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeTransactionPointsHistory, "transactionPointsHistoryDecrease", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"transactionPointsHistoryDecrease"}},[_vm._v(_vm._s(_vm.$t("discount")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }