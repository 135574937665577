import { STATUS, PAGE_SIZE } from "@/utils/constants";
import i18n from "@/i18n/index.js";
import axios from "axios";

export default class PlacePart {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.placePartToken = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.placeToken = "";
    this.placeNameCurrent = "";
    this.placePartCode = "";
    this.placePartNameCurrent = "";
    this.placePartNameEn = "";
    this.placePartNameAr = "";
    this.placePartNameUnd = "";
    this.placePartImagePath = "";
    this.placePartDescriptionCurrent = "";
    this.placePartDescriptionEn = "";
    this.placePartDescriptionAr = "";
    this.placePartDescriptionUnd = "";
    this.placePartNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.placePartArchiveStatus = false;
  }
  fillData(data) {
    if (data) {
      this.image = data.image || "";
      this.placePartToken = data.placePartToken || "";
      this.establishmentToken = data.establishmentToken || "";
      this.establishmentNameCurrent = data.establishmentNameCurrent || "";
      this.placeToken = data.placeToken || "";
      this.placeNameCurrent = data.placeNameCurrent || "";
      this.placePartCode = data.placePartCode || "";
      this.placePartNameCurrent = data.placePartNameCurrent || "";
      this.placePartNameEn = data.placePartNameEn || "";
      this.placePartNameAr = data.placePartNameAr || "";
      this.placePartNameUnd = data.placePartNameUnd || "";
      this.placePartImagePath = data.placePartImagePath || "";
      this.placePartDescriptionCurrent = data.placePartDescriptionCurrent || "";
      this.placePartDescriptionEn = data.placePartDescriptionEn || "";
      this.placePartDescriptionAr = data.placePartDescriptionAr || "";
      this.placePartDescriptionUnd = data.placePartDescriptionUnd || "";
      this.placePartNotes = data.placePartNotes || "";
      this.actionDateTime = data.actionDateTime || "";
      this.actionDate = data.actionDate || "";
      this.actionTime = data.actionTime || "";
      this.placePartArchiveStatus = data.placePartArchiveStatus || false;
    } else {
      this.setInitialValue();
    }
  }

  async getAllPlacesParts(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    if (filterData.placeToken == "" || filterData.placeToken == undefined) {
      let response = {
        data: {
          status: STATUS.ERROR,
          msg: i18n.t("placesParts.pleaseSelectPlace"),
          data: "",
        },
      };
      return response;
    }
    try {
      let response = await axios.get(
        `/PlaceParts/GetAllPlaceParts?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&placeToken=${filterData.placeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getPlacePartDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PlaceParts/GetPlacePartDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&placePartToken=${this.placePartToken}`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async getDialogOfPlaceParts(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PlaceParts/GetDialogOfPlaceParts?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&placeToken=${this.placeToken}`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async addOrUpdatePlacePart(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      image: this.image,
      placePartToken: this.placePartToken,
      placeToken: this.placeToken,
      placePartNameAr: this.placePartNameAr,
      placePartNameEn: this.placePartNameEn,
      placePartNameUnd: this.placePartNameUnd,
      placePartDescriptionAr: this.placePartDescriptionAr,
      placePartDescriptionEn: this.placePartDescriptionEn,
      placePartDescriptionUnd: this.placePartDescriptionUnd,
      placePartNotes: this.placePartNotes,
    };
    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.placePartToken == "" || this.placePartToken == undefined) {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/PlaceParts/AddPlacePartWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/PlaceParts/AddPlacePartWithoutImage`,
            data
          );
        }
      } else {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/PlaceParts/UpdatePlacePartWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/PlaceParts/UpdatePlacePartWithoutImage`,
            data
          );
        }
      }

      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archivePlacePart(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placePartToken: this.placePartToken,
    };

    try {
      let response = await axios.post(`/PlaceParts/ArchivePlacePart`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}

