<template>
  <div v-if="placesWorkPlansData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("placesWorkPlans.placeWorkPlanCode") }}</th>
            <th>{{ $t("placesWorkPlans.name") }}</th>
            <th>{{ $t("places.name") }}</th>
            <th>{{ $t("placesWorkPlans.description") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("placesWorkPlans.startActiveDateTime") }}</th>
            <th>{{ $t("placesWorkPlans.endActiveDateTime") }}</th>
            <th>{{ $t("pages.PlacesWorkPlanDays") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("edit") }}</th>
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(PlacesWorkPlan, index) in placesWorkPlansData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>
              {{ isDataExist(PlacesWorkPlan.placeWorkPlanCode) }}
            </td>
            <td>
              {{ isDataExist(PlacesWorkPlan.placeWorkPlanNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(PlacesWorkPlan.placeNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(PlacesWorkPlan.placeWorkPlanDescriptionCurrent) }}
            </td>
            <td>
              {{ isDataExist(PlacesWorkPlan.placeWorkPlanNotes) }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  PlacesWorkPlan.startActiveDate,
                  PlacesWorkPlan.startActiveTime
                )
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  PlacesWorkPlan.endActiveDate,
                  PlacesWorkPlan.endActiveTime
                )
              }}
            </td>
            <td>
              <router-link
                :to="{
                  name: 'PlacesWorkPlanDays',
                  params: {
                    placeWorkPlanToken: `${PlacesWorkPlan.placeWorkPlanToken}`,
                    placeToken: `${PlacesWorkPlan.placeToken}`,
                  },
                }"
                :title="$t('pages.PlacesWorkPlanDays')"
              >
                <img src="@/assets/images/date.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.PlacesWorkPlanInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setPlacesWorkPlanData(PlacesWorkPlan)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'PlacesWorkPlanEdit',
                  params: {
                    placeWorkPlanToken: `${PlacesWorkPlan.placeWorkPlanToken}`,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.PlacesWorkPlanDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setPlacesWorkPlanData(PlacesWorkPlan)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  setDataMultiLang,
  timeToLang,
  isDataExist,
  formateDateDMY,
  formateDateTimeLang,
} from "@/utils/functions";

export default {
  name: "PlacesWorkPlansTable",

  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["placesWorkPlansData", "placesWorkPlanData"],
  methods: {
    setPlacesWorkPlanData(placesWorkPlanData) {
      this.$emit("setPlacesWorkPlanData", placesWorkPlanData);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },

    formateDateDMY(date, lang = this.language) {
      return formateDateDMY(date, lang);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
