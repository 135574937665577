import { PAGE_SIZE } from "@/utils/constants";
import { PLACE_SCHEDULE_TYPE } from "@/utils/constantLists";
import axios from "axios";

export default class PlaceSchedule {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placeScheduleToken = "";
    this.placeScheduleCode = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.placeToken = "";
    this.placeNameCurrent = "";
    this.placePartToken = "";
    this.placePartCode = "";
    this.placePartImagePath = "";
    this.placePartNameCurrent = "";
    this.placeWorkPlanToken = "";
    this.placeWorkPlanNameCurrent = "";
    this.dayToken = "";
    this.dayNameCurrent = "";
    this.placeWorkPlanDayToken = "";
    this.placeWorkPlanDayNameCurrent = "";
    this.placeScheduleNameCurrent = "";
    this.placeScheduleNameEn = "";
    this.placeScheduleNameAr = "";
    this.placeScheduleNameUnd = "";
    this.placeScheduleTimeFrom = "";
    this.placeScheduleTimeTo = "";
    this.placeScheduleDuration = "";
    this.pricePerHour = "";
    this.placeScheduleNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.placeScheduleTypeToken = "";
    this.placeScheduleTypeNameAr = "";
    this.placeScheduleTypeNameEn = "";
    this.placeScheduleTypeNameUnd = "";
    this.placeScheduleTypeNameCurrent = "";
    this.approvedTypeToken = "";
    this.approvedTypeNameEn = "";
    this.approvedTypeNameAr = "";
    this.approvedTypeNameUnd = "";
    this.approvedTypeNameCurrent = "";
    this.employeeApprovedToken = "";
    this.employeeApprovedNameCurrent = "";
    this.contractorApprovedToken = "";
    this.contractorApprovedNameCurrent = "";
    this.approvedDateTime = "";
    this.approvedDate = "";
    this.approvedTime = "";
    this.placeScheduleArchiveStatus = "";
  }
  fillData(data) {
    this.placeScheduleToken = data.placeScheduleToken;
    this.placeScheduleCode = data.placeScheduleCode;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.placeToken = data.placeToken;
    this.placeNameCurrent = data.placeNameCurrent;
    this.placePartToken = data.placePartToken;
    this.placePartCode = data.placePartCode;
    this.placePartImagePath = data.placePartImagePath;
    this.placePartNameCurrent = data.placePartNameCurrent;
    this.placeWorkPlanToken = data.placeWorkPlanToken;
    this.placeWorkPlanNameCurrent = data.placeWorkPlanNameCurrent;
    this.dayToken = data.dayToken;
    this.dayNameCurrent = data.dayNameCurrent;
    this.placeWorkPlanDayToken = data.placeWorkPlanDayToken;
    this.placeWorkPlanDayNameCurrent = data.placeWorkPlanDayNameCurrent;
    this.placeScheduleNameCurrent = data.placeScheduleNameCurrent;
    this.placeScheduleNameEn = data.placeScheduleNameEn;
    this.placeScheduleNameAr = data.placeScheduleNameAr;
    this.placeScheduleNameUnd = data.placeScheduleNameUnd;
    this.placeScheduleTimeFrom = data.placeScheduleTimeFrom;
    this.placeScheduleTimeTo = data.placeScheduleTimeTo;
    this.placeScheduleDuration = data.placeScheduleDuration;
    this.pricePerHour = data.pricePerHour;
    this.placeScheduleNotes = data.placeScheduleNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.placeScheduleTypeToken = data.placeScheduleTypeToken;
    this.placeScheduleTypeNameAr = data.placeScheduleTypeNameAr;
    this.placeScheduleTypeNameEn = data.placeScheduleTypeNameEn;
    this.placeScheduleTypeNameUnd = data.placeScheduleTypeNameUnd;
    this.placeScheduleTypeNameCurrent = data.placeScheduleTypeNameCurrent;
    this.approvedTypeToken = data.approvedTypeToken;
    this.approvedTypeNameEn = data.approvedTypeNameEn;
    this.approvedTypeNameAr = data.approvedTypeNameAr;
    this.approvedTypeNameUnd = data.approvedTypeNameUnd;
    this.approvedTypeNameCurrent = data.approvedTypeNameCurrent;
    this.employeeApprovedToken = data.employeeApprovedToken;
    this.employeeApprovedNameCurrent = data.employeeApprovedNameCurrent;
    this.contractorApprovedToken = data.contractorApprovedToken;
    this.contractorApprovedNameCurrent = data.contractorApprovedNameCurrent;
    this.approvedDateTime = data.approvedDateTime;
    this.approvedDate = data.approvedDate;
    this.approvedTime = data.approvedTime;
    this.placeScheduleArchiveStatus = data.placeScheduleArchiveStatus;
  }

  async getAllPlacesSchedules(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    placeWorkPlanDayToken = ""
  ) {
    try {
      let response = await axios.get(
        `/PlacesSchedules/GetAllPlacesSchedules/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&textSearch=${textSearch}&placeWorkPlanDayToken=${placeWorkPlanDayToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfPlacesSchedules(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PlacesSchedules/GetDialogOfPlacesSchedules?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }
  async getPlaceScheduleDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PlacesSchedules/GetPlaceScheduleDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&placeScheduleToken=${this.placeScheduleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdatePlaceSchedule(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placeScheduleToken: this.placeScheduleToken,
      placeWorkPlanDayToken: this.placeWorkPlanDayToken,
      placePartToken: this.placePartToken,
      placeScheduleTypeToken: this.placeScheduleTypeToken,
      placeScheduleTimeFrom: this.placeScheduleTimeFrom,
      placeScheduleTimeTo: this.placeScheduleTimeTo,
      pricePerHour: this.pricePerHour,
      placeScheduleNotes: this.placeScheduleNotes,
      placeScheduleCode: this.placeScheduleCode,
    };

    try {
      let response = "";
      if (
        this.placeWorkPlanToken == "" ||
        this.placeWorkPlanToken == undefined
      ) {
        data.placeScheduleTypeToken = PLACE_SCHEDULE_TYPE.fixed; // Ahmed Omar requested it 2022/06/16
        response = await axios.post(`/PlacesSchedules/AddPlaceSchedule`, data);
      } else {
        response = await axios.post(
          `/PlacesSchedules/UpdatePlaceSchedule`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archivePlaceSchedule(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placeScheduleToken: this.placeScheduleToken,
    };

    try {
      let response = await axios.post(
        `/PlacesSchedules/ArchivePlaceSchedule`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
