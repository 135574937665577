var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.EnquiryReservations",modifiers:{"EnquiryReservations":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("pages.EnquiryReservations"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"EnquiryReservations"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsView
            ),expression:"\n              usersPrivilegeEnquiryReservations.enquiryReservationsView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"enquiryReservationsView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsView
            )?_vm._i(
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsView
            ,null)>-1:(
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"enquiryReservationsView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usersPrivilegeEnquiryReservations.enquiryReservationsAdd),expression:"usersPrivilegeEnquiryReservations.enquiryReservationsAdd"}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"enquiryReservationsAdd"},domProps:{"checked":Array.isArray(_vm.usersPrivilegeEnquiryReservations.enquiryReservationsAdd)?_vm._i(_vm.usersPrivilegeEnquiryReservations.enquiryReservationsAdd,null)>-1:(_vm.usersPrivilegeEnquiryReservations.enquiryReservationsAdd)},on:{"change":function($event){var $$a=_vm.usersPrivilegeEnquiryReservations.enquiryReservationsAdd,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"enquiryReservationsAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsDelete
            ),expression:"\n              usersPrivilegeEnquiryReservations.enquiryReservationsDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"enquiryReservationsDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsDelete
            )?_vm._i(
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsDelete
            ,null)>-1:(
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEnquiryReservations.enquiryReservationsDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEnquiryReservations, "enquiryReservationsDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"enquiryReservationsDelete"}},[_vm._v(_vm._s(_vm.$t("delete")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }