<template>
  <div class="">
    <b-button
      v-b-toggle.ReservationsSchedulesCancle
      class="btn btn-lg btn-collapse"
    >
      {{ $t("pages.ReservationsSchedulesCancle") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="ReservationsSchedulesCancle">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="reservationsSchedulesCancelView"
              v-model="
                usersPrivilegeReservationsSchedulesCancle.reservationsSchedulesCancelView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="reservationsSchedulesCancelView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="reservationsSchedulesCancelAdd"
              v-model="
                usersPrivilegeReservationsSchedulesCancle.reservationsSchedulesCancelAdd
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="reservationsSchedulesCancelAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="reservationsSchedulesCancelreservationsSchedulesCancelApprov"
              v-model="
                usersPrivilegeReservationsSchedulesCancle.reservationsSchedulesCancelreservationsSchedulesCancelApprov
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="reservationsSchedulesCancelreservationsSchedulesCancelApprov"
              >{{ $t("approved") }}</label
            >
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="reservationsSchedulesCancelActionCancel"
              v-model="
                usersPrivilegeReservationsSchedulesCancle.reservationsSchedulesCancelActionCancel
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="reservationsSchedulesCancelActionCancel"
              >{{ $t("cancelOfCancel") }}</label
            >
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegeReservationsSchedulesCancle",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeReservationsSchedulesCancle"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
