export const PAGENAME_ICONS = {
  TestComponent: "settings.svg",
  Settings: "settings.svg",
  SettingsOther: "settings-other.svg",
  Countries: "countries.svg",
  CountryAdd: "countries.svg",
  CountryEdit: "countries.svg",
  Governates: "governate.svg",
  GovernateAdd: "governate.svg",
  GovernateEdit: "governate.svg",
  Cities: "city.svg",
  CityAdd: "city.svg",
  CityEdit: "city.svg",
  Districts: "districts.svg",
  DistrictAdd: "districts.svg",
  DistrictEdit: "districts.svg",
  InsuranceCompanies: "svg",
  InsuranceCompanyAdd: "svg",
  InsuranceCompanyEdit: "svg",
  EmployeeRoles: "employees.svg",
  EmployeeRoleAdd: "employees.svg",
  EmployeeRoleEdit: "employees.svg",
  ClientRoles: "clients.svg",
  ClientRoleAdd: "clients.svg",
  ClientRoleEdit: "clients.svg",
  ContractorRoles: "contractors.svg",
  ContractorRoleAdd: "contractors.svg",
  ContractorRoleEdit: "contractors.svg",
  News: "news.svg",
  NewsAdd: "news.svg",
  NewsEdit: "news.svg",
  NewsItem: "news.svg",
  Offers: "offers.svg",
  OfferAdd: "offers.svg",
  OfferEdit: "offers.svg",
  OfferItem: "offers.svg",
  Clients: "clients.svg",
  ClientAdd: "clients.svg",
  ClientEdit: "clients.svg",
  Employees: "employees.svg",
  EmployeeAdd: "employees.svg",
  EmployeeEdit: "employees.svg",
  Places: "places.svg",
  PlaceAdd: "places.svg",
  PlaceEdit: "places.svg",
  PlacesWorkPlans: "places-work-plans.svg",
  PlacesWorkPlanAdd: "places-work-plans.svg",
  PlacesWorkPlanEdit: "places-work-plans.svg",
  PlacesWorkPlanDays: "work-schedule.svg",
  NewsCategories: "category.svg",
  NewsCategoryAdd: "category.svg",
  NewsCategoryEdit: "category.svg",
  Activities: "type.svg",
  ActivityAdd: "type.svg",
  ActivityEdit: "type.svg",
  Finance: "finances.svg",
  Treasuries: "treasuries.svg",
  TreasuryAdd: "treasuries.svg",
  TreasuryEdit: "treasuries.svg",
  Expenses: "expenses.svg",
  ExpenseAdd: "expenses.svg",
  ExpenseEdit: "expenses.svg",
  Revenues: "revenues.svg",
  RevenueAdd: "revenues.svg",
  RevenueEdit: "revenues.svg",
  TreasuriesTransactions: "treasuries-transactions.svg",
  TreasuryTransactionAdd: "treasuries-transactions.svg",
  ExpensesTypes: "expenses-types.svg",
  ExpenseTypeAdd: "expenses-types.svg",
  ExpenseTypeEdit: "expenses-types.svg",
  RevenuesTypes: "revenues-types.svg",
  RevenueTypeAdd: "revenues-types.svg",
  RevenueTypeEdit: "revenues-types.svg",
  TransactionPoints: "transaction-points.svg",
  TransactionPointAdd: "transaction-points.svg",
  Payments: "money.svg",
  PaymentAdd: "money.svg",
  Reservations: "reservations.svg",
  ReservationAdd: "confirm.svg",
  ReservationDetails: "reservations.svg",
  EnquiryReservations: "enquiry-reservations.svg",
  EnquiryReservationAdd: "reservations.svg",
  EnquiryReservationDetails: "enquiry-reservations.svg",
  PlacesMedias: "places-media.svg",
  PlaceMediaAdd: "places-media.svg",
  PlaceMediaEdit: "places-media.svg",
  PlacesParts: "PlacesParts.svg",
  PlacePartAdd: "PlacesParts.svg",
  PlacePartEdit: "PlacesParts.svg",
  PaymentMethodes: "method-paids.svg",
  PaymentMethodeAdd: "method-paids.svg",
  PaymentMethodeEdit: "method-paids.svg",
  Reports: "reports.svg",
  Privileges: "privileges.svg",
  UsersEmployeePrivileges: "privilege.svg",
  UsersClientPrivileges: "privilege.svg",
};
