<template>
  <div v-if="placesWorkPlanDaysData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("placesWorkPlans.placeWorkPlanCode") }}</th>
            <th>{{ $t("placesWorkPlans.name") }}</th>
            <th>{{ $t("placesWorkPlans.startActiveDateTime") }}</th>
            <th>{{ $t("placesWorkPlans.endActiveDateTime") }}</th>
            <th>{{ $t("places.name") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>#</td>
            <td>
              {{ isDataExist(placesWorkPlanData.placeWorkPlanCode) }}
            </td>
            <td>
              {{ isDataExist(placesWorkPlanData.placeWorkPlanNameCurrent) }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  placesWorkPlanData.startActiveDate,
                  placesWorkPlanData.startActiveTime
                )
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  placesWorkPlanData.endActiveDate,
                  placesWorkPlanData.endActiveTime
                )
              }}
            </td>
            <td>
              {{ isDataExist(placesWorkPlanData.placeNameCurrent) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-for="(placesWorkPlanDay, index) in placesWorkPlanDaysData"
      :key="index"
    >
      <b-button v-b-toggle="'id' + index" class="btn btn-lg btn-collapse">
        {{
          `${placesWorkPlanDay.dayNameCurrent} ( ${placesWorkPlanDay.dayStatusNameCurrent} )`
        }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse :id="'id' + index">
        <div class="table-container">
          <table class="my-table">
            <thead>
              <tr>
                <th>{{ $t("dayName") }}</th>
                <th>{{ $t("dayStatus") }}</th>
                <th>{{ $t("edit") }}</th>
                <th>{{ $t("placesSchedules.add") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ isDataExist(placesWorkPlanDay.dayNameCurrent) }}</td>
                <td>
                  {{ isDataExist(placesWorkPlanDay.dayStatusNameCurrent) }}
                </td>
                <td>
                  <button
                    v-b-modal.PlacesWorkPlanDayEdit
                    class="btn p-0"
                    :title="$t('info')"
                    @click="setPlacesWorkPlanDayData(placesWorkPlanDay)"
                  >
                    <img src="@/assets/images/pencil.svg" class="icon-lg" />
                  </button>
                </td>
                <td>
                  <button
                    v-b-modal.PlaceScheduleAdd
                    class="btn p-0"
                    :title="$t('placesSchedules.add')"
                    @click="setPlacesWorkPlanDayData(placesWorkPlanDay)"
                  >
                    <img src="@/assets/images/plus.svg" class="icon-lg" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="table-container"
          v-if="placesWorkPlanDay.placesSchedulesData.length != 0"
        >
          <table class="my-table">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("placesSchedules.code") }}</th>
                <th>{{ $t("placesParts.name") }}</th>
                <th>{{ $t("placesSchedules.pricePerHour") }}</th>
                <th>{{ $t("placesSchedules.timeFrom") }}</th>
                <th>{{ $t("placesSchedules.timeTo") }}</th>
                <th>{{ $t("notes") }}</th>
                <th>{{ $t("edit") }}</th>
                <th>{{ $t("delete") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  placeSchedule, index2
                ) in placesWorkPlanDay.placesSchedulesData"
                :key="index2"
              >
                <td>{{ ++index2 }}</td>
                <td>
                  {{ isDataExist(placeSchedule.placeScheduleCode) }}
                </td>
                <td>
                  {{ isDataExist(placeSchedule.placePartNameCurrent) }}
                </td>
                <td>
                  {{ isDataExist(moneyFormat(placeSchedule.pricePerHour)) }}
                </td>
                <td>{{ timeToLang(placeSchedule.placeScheduleTimeFrom) }}</td>
                <td>{{ timeToLang(placeSchedule.placeScheduleTimeTo) }}</td>
                <td>{{ isDataExist(placeSchedule.placeScheduleNotes) }}</td>
                <td>
                  <button
                    v-b-modal.PlaceScheduleEdit
                    class="btn p-0"
                    :title="$t('edit')"
                    @click="setPlaceScheduleData(placeSchedule)"
                  >
                    <img src="@/assets/images/pencil.svg" class="icon-lg" />
                  </button>
                </td>
                <td>
                  <button
                    v-b-modal.PlaceScheduleDelete
                    class="btn p-0"
                    :title="$t('delete')"
                    @click="setPlaceScheduleData(placeSchedule)"
                  >
                    <img src="@/assets/images/trash.svg" class="icon-lg" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";
import { moneyFormat } from "@/utils/functions";
import {
  setDataMultiLang,
  timeToLang,
  isDataExist,
  formateDateTimeLang,
} from "@/utils/functions";
import PlacesWorkPlan from "@/models/places/placesWorkPlans/PlacesWorkPlan";

export default {
  name: "PlacesWorkPlanDaysTable",
  mixins: [createToastMixin],
  data() {
    return {
      placesWorkPlanData: new PlacesWorkPlan(),
      placeWorkPlanToken: this.$route.params.placeWorkPlanToken,
      planDaysTokens: [],
    };
  },
  props: ["placesWorkPlanDaysData", "placesWorkPlanDayData"],
  methods: {
    async getPlacesWorkPlanDetails() {
      this.isLoading = true;
      this.placesWorkPlanData.placeWorkPlanToken = this.placeWorkPlanToken;
      try {
        let response = await this.placesWorkPlanData.getPlacesWorkPlansDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.placesWorkPlanData.fillData(response.data.placeWorkPlanData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.placesWorkPlanData = null;
        }
      } catch (error) {
        this.placesWorkPlanData = null;
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = "noInternet.svg";
      }
      this.isLoading = false;
    },
    setPlacesWorkPlanDayData(placesWorkPlanDayData) {
      this.$emit("setPlacesWorkPlanDayData", placesWorkPlanDayData);
    },
    setPlaceScheduleData(placeSchedule) {
      this.$emit("setPlaceScheduleData", placeSchedule);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    moneyFormat(data) {
      return moneyFormat(data);
    },
  },
  computed: {},
  async created() {
    this.getPlacesWorkPlanDetails();
  },
};
</script>

<style lang="scss"></style>
