<template>
  <b-modal id="PlacePartInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/transaction-points.svg" class="icon-lg" />
        {{ $t("placesParts.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="placePartData.placeNameCurrent"
        :title="$t('places.name')"
        :imgName="'places.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placePartData.placePartCode"
        :title="$t('placesParts.code')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placePartData.placePartNameAr"
        :title="$t('placesParts.nameAr')"
        :imgName="'PlacesParts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placePartData.placePartNameEn"
        :title="$t('placesParts.nameEn')"
        :imgName="'PlacesParts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placePartData.placePartNameUnd"
        :title="$t('placesParts.nameUnd')"
        :imgName="'PlacesParts.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placePartData.placePartDescriptionAr"
        :title="$t('placesParts.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placePartData.placePartDescriptionEn"
        :title="$t('placesParts.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placePartData.placePartDescriptionUnd"
        :title="$t('placesParts.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="placePartData.placePartNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          formateDateTimeLang(
            placePartData.actionDate,
            placePartData.actionTime
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { formateDateTimeLang } from "@/utils/functions";

export default {
  name: "PlacePartInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["placePartData"],
  methods: {
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>

