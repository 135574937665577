import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import Pagination from "@/components/general/Pagination.vue";

export default {
  async created() {
    this.userAuthorizeToken =
      this.$store.getters.userData != null
        ? this.$store.getters.userData.userPersonalData.userAuthorizeToken
        : "";
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  components: {
    PreLoader,
    ExceptionWithImg,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      isCatch: false,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken: "",
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      filterStatus: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
    };
  },
  methods: {
    // createToast(msg = "", isSuccess = false) {
    showMsg(msg = "", isSuccess = false) {
      let variant = isSuccess ? "success" : "danger";
      this.$bvToast.toast(`${msg}`, {
        title: this.$t("alert"),
        variant: variant,
        toaster: "b-toaster-bottom-center",
        autoHideDelay: 5000,
      });
    },
    errorCatch() {
      this.isCatch = true;
      this.exceptionMsg = this.$t("errorCatch");
      this.exceptionImg = "noInternet.svg";
    },
  },
};
