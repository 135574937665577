<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <PlaceMediaForm
      v-if="!isLoading"
      :placeMediaData="placeMediaData"
      :mediaSrc="mediaSrc"
      :submitName="$t('edit')"
      v-on:addOrUpdatePlaceMedia="updatePlaceMedia"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS, BASE_URL } from "@/utils/constants";
import PlaceMediaForm from "@/components/places/placesMedias/PlaceMediaForm.vue";
import PlaceMedia from "@/models/places/placesMedias/PlaceMedia";
import DEFAULT_IMG from "@/assets/images/places-media.svg";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "PlaceMediaEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    PlaceMediaForm,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      placeMediaData: new PlaceMedia(),
    };
  },
  methods: {
    async getPlaceMediaDetails() {
      this.isLoading = true;
      try {
        let response = await this.placeMediaData.getPlaceMediaDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.placeMediaData.fillData(response.data.placeMediaData);
          this.placeMediaData.media = "";
          if (this.placeMediaData.placeMediaPath) {
            this.mediaSrc = BASE_URL + this.placeMediaData.placeMediaPath;
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.placeMediaData.fillData(null);
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.placeMediaData.fillData(null);
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    async updatePlaceMedia() {
      this.isLoading = true;
      try {
        let response = await this.placeMediaData.addOrUpdatePlaceMedia(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push(
            `/places/places-medias/${this.placeMediaData.placeToken}`
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  computed: {
    mediaSrc: function () {
      if (this.placeMediaData.placeMediaPath) {
        return BASE_URL + this.placeMediaData.placeMediaPath;
      }
      return DEFAULT_IMG;
    },
  },
  async created() {
    this.placeMediaData.placeMediaToken = this.$route.params.placeMediaToken;
    this.getPlaceMediaDetails();
  },
};
</script>

<style scoped lang="scss"></style>
