<template>
  <b-modal id="EmployeeInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/employees.svg" class="icon-lg" />
        {{ $t("employeeData") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNameAr"
        :title="$t('employeeNameAr')"
        :imgName="'employees.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNameEn"
        :title="$t('employeeNameEn')"
        :imgName="'employees.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNameUnd"
        :title="$t('employeeNameUnd')"
        :imgName="'employees.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeUserName"
        :title="$t('userName')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeEmail"
        :title="$t('email')"
        :imgName="'email.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6 phone-number'"
        :value="employeeData.employeePhoneWithCC"
        :title="$t('phoneNumber')"
        :imgName="'phone.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeAddressAr"
        :title="$t('addressAr')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeAddressEn"
        :title="$t('addressEn')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeAddressUnd"
        :title="$t('addressUnd')"
        :imgName="'address.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.countryNameCurrent"
        :title="$t('country')"
        :imgName="'country.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.governorateNameCurrent"
        :title="$t('governate')"
        :imgName="'governate.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.cityNameCurrent"
        :title="$t('city')"
        :imgName="'city.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.districtNameCurrent"
        :title="$t('district')"
        :imgName="'district.svg'"
      />

      <!-- <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.qualificationNameCurrent"
        :title="$t('qualificationName')"
        :imgName="'qualifications.svg'"
      /> -->
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeBirthdate"
        :title="$t('birthdate')"
        :imgName="'date.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeGenderNameCurrent"
        :title="$t('gender')"
        :imgName="'gender.svg'"
      />

      <!-- <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.insuranceCompanyNameCurrent"
        :title="$t('insuranceCompany')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeInsuranceNumber"
        :title="$t('insuranceNumber')"
        :imgName="'number.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeBankNameCurrent"
        :title="$t('bankName')"
        :imgName="'banks.svg'"
      /> -->
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="timeToLang(employeeData.employeeDateTimeCreated)"
        :title="$t('employeeAddingDateToSystem')"
        :imgName="'date.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeCitizenShipTypeNameCurrent"
        :title="$t('citizenship')"
        :imgName="'citizenship.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeResidencyNumber"
        :title="$t('employeeResidencyNumber')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeResidencyStartDate"
        :title="$t('employeeResidencyStartDate')"
        :imgName="'date.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeResidencyEndDate"
        :title="$t('employeeResidencyEndDate')"
        :imgName="'date.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeMaritalNameCurrent"
        :title="$t('maritalStatus')"
        :imgName="'maritalStatus.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeMaritalStatusNotes"
        :title="$t('maritalStatusNotes')"
        :imgName="'notes.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeMilitaryNameCurrent"
        :title="$t('militaryStatus')"
        :imgName="'militaryStatus.svg'"
      />
      <!-- <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.bloodTypeNameCurrent"
        :title="$t('bloodTypeName')"
        :imgName="'blood-types.svg'"
      /> -->
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeMilitaryStatusNotes"
        :title="$t('militaryStatusNotes')"
        :imgName="'notes.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeCode"
        :title="$t('employeeCode')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNationalNumber"
        :title="$t('nationalNumber')"
        :imgName="'nationalNumber.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeNationalNumberExpireDate"
        :title="$t('nationalNumberExpireDate')"
        :imgName="'date.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeDrivingLicenceNumber"
        :title="$t('drivingLicenceNumber')"
        :imgName="'drivingLicense.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeeDrivingLicenceExpireDate"
        :title="$t('drivingLicenceExpireDate')"
        :imgName="'date.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeePassportNumber"
        :title="$t('passportNumber')"
        :imgName="'passportNumber.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeData.employeePassportExpireDate"
        :title="$t('passportExpireDate')"
        :imgName="'date.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeData.employeeActiveStatus === true ? $t('yes') : $t('no')
        "
        :title="$t('employeeActiveStatus')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeData.employeeNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { setDataMultiLang, timeToLang } from "@/utils/functions";

export default {
  name: "EmployeeInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["employeeData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
};
</script>

<style scoped lang="scss"></style>
