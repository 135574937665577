<template>
  <div class="flip-card col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img :src="imgSrc" :onerror="`this.src='${defaultImg}'`" alt="Image" />
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="placeData.placeNameCurrent">
            {{ placeData.placeNameCurrent }}
          </h4>
        </div>
      </div>
      <div class="flip-card-back">
        <div class="flip-card-back-icons">
          <button
            v-b-modal.PlaceInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setPlaceData"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button>
          <router-link
            :to="{
              name: 'PlaceEdit',
              params: { placeToken: `${placeData.placeToken}` },
            }"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <!-- <br /> -->
          <button
            v-b-modal.PlaceDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setPlaceData"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
          <!-- <button @click="goToFamily()" :title="$t('family')">
            <img src="@/assets/images/family.svg" class="icon-lg" />
          </button> -->
          <router-link
            :to="{
              name: 'PlacesWorkPlans',
              params: { placeToken: `${placeData.placeToken}` },
            }"
            :title="$t('pages.PlacesWorkPlans')"
          >
            <img src="@/assets/images/places-work-plans.svg" class="icon-lg" />
          </router-link>
          <router-link
            :to="{
              name: 'EnquiryReservationAdd',
              params: { placeToken: `${placeData.placeToken}` },
            }"
            :title="$t('pages.EnquiryReservationAdd')"
          >
            <img src="@/assets/images/reservations.svg" class="icon-lg" />
          </router-link>

          <router-link
            :to="{
              name: 'Offers',
              params: { placeToken: `${placeData.placeToken}` },
            }"
            :title="$t('pages.Offers')"
          >
            <img src="@/assets/images/offers.svg" class="icon-lg" />
          </router-link>
          <router-link
            :to="{
              name: 'PlacesParts',
              params: { placeToken: `${placeData.placeToken}` },
            }"
            :title="$t('placesParts.modelName')"
          >
            <img src="@/assets/images/PlacesParts.svg" class="icon-lg" />
          </router-link>
          <router-link
            :to="{
              name: 'PlacesMedias',
              params: { placeToken: `${placeData.placeToken}` },
            }"
            :title="$t('placesMedias.modelName')"
          >
            <img src="@/assets/images/places-media.svg" class="icon-lg" />
          </router-link>
        </div>

        <div class="flip-card-title">
          <h4 class="cut-1line" :title="placeData.placeNameCurrent">
            {{ placeData.placeNameCurrent }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@/utils/constants";
import DEFAULT_IMG_CLIENT from "@/assets/images/places.svg";

export default {
  name: "PlaceCard",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: DEFAULT_IMG_CLIENT,
    };
  },
  props: ["placeData"],
  methods: {
    setPlaceData() {
      this.$emit("setPlaceData", this.placeData);
    },
    goToFamily() {
      this.$store.dispatch("updatePlaceToken", this.placeData.placeToken);
      this.$router.push("/place-family").catch(() => {});
    },
  },
  computed: {
    imgSrc: function () {
      return BASE_URL + this.placeData.placeImagePath;
    },
  },
};
</script>

<style lang="scss"></style>
