<template>
  <b-modal id="PlaceScheduleEdit" scrollable centered hide-footer size="xl">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/pencil.svg" class="icon-lg" />
        {{ $t("placesSchedules.edit") }}
      </h3>
    </template>
    <div class="row">
      <!-- <div class="form-label-group col-6">
        <b-form-select
          v-model="placeScheduleData.placeScheduleTypeToken"
          :options="placeScheduleTypeTokenOptions"
          class="form-control"
        ></b-form-select>
        <img src="@/assets/images/type.svg" />
      </div> -->

      <CustomInput
        :className="'col-lg-6'"
        :id="'placeScheduleCode'"
        :value="placeScheduleData.placeScheduleCode"
        :title="$t('placesSchedules.code')"
        :imgName="'number.svg'"
        v-on:changeValue="placeScheduleData.placeScheduleCode = $event"
      />
      <CustomInput
        :className="'col-lg-6'"
        :id="'pricePerHour'"
        :value="placeScheduleData.pricePerHour"
        :title="$t('placesSchedules.pricePerHour')"
        :imgName="'money.svg'"
        v-on:changeValue="placeScheduleData.pricePerHour = $event"
      />

      <CustomSelectBox
        :className="'col-12'"
        :id="'placePartToken'"
        :value="placeScheduleData.placePartToken"
        :options="placePartTokenOptions"
        v-on:changeValue="placeScheduleData.placePartToken = $event"
        :title="$t('placesParts.select')"
        :imgName="'PlacesParts.svg'"
      />

      <TimePicker
        :className="'col-lg-6'"
        :id="'placeScheduleTimeFrom'"
        :value="placeScheduleData.placeScheduleTimeFrom"
        :title="$t('placesSchedules.timeFrom')"
        v-on:changeValue="placeScheduleData.placeScheduleTimeFrom = $event"
        :language="language"
      />
      <TimePicker
        :className="'col-lg-6'"
        :id="'placeScheduleTimeTo'"
        :value="placeScheduleData.placeScheduleTimeTo"
        :title="$t('placesSchedules.timeTo')"
        v-on:changeValue="placeScheduleData.placeScheduleTimeTo = $event"
        :language="language"
      />

      <TextArea
        :className="'col-12'"
        :id="'placeScheduleNotes'"
        :value="placeScheduleData.placeScheduleNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
        v-on:changeValue="placeScheduleData.placeScheduleNotes = $event"
      />
    </div>

    <div class="form-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="updatePlaceSchedule"
      >
        {{ $t("edit") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('PlaceScheduleEdit')"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import PlacePart from "@/models/places/placesParts/PlacePart";
import createToastMixin from "@/utils/create-toast-mixin";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import CustomInput from "@/components/general/CustomInput.vue";

export default {
  name: "PlaceScheduleEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomSelectBox,
    TextArea,
    TimePicker,
    CustomInput,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      // placeScheduleTypeTokenOptions: [],
      placePartData: new PlacePart(),
      placePartTokenOptions: [],
    };
  },
  methods: {
    // async getPlaceScheduleTypes() {
    //   this.isLoading = true;
    //   this.placeScheduleTypeTokenOptions = [];
    //   this.placeScheduleTypeTokenOptions.push({
    //     value: "",
    //     text: this.$t("placesSchedules.selectType"),
    //   });
    //   let scheduleTypes =
    //     this.$store.getters.userData.constantsLists.listPlaceScheduleType;
    //   for (let item in scheduleTypes) {
    //     this.placeScheduleTypeTokenOptions.push({
    //       value: scheduleTypes[item]["itemToken"],
    //       text: this.setDataMultiLang(
    //         this.language,
    //         scheduleTypes[item]["itemNameAr"],
    //         scheduleTypes[item]["itemNameEn"]
    //       ),
    //     });
    //   }
    //   this.isLoading = false;
    // },
    async updatePlaceSchedule() {
      this.isLoading = true;

      try {
        let response = await this.placeScheduleData.addOrUpdatePlaceSchedule(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("PlaceScheduleEdit");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfPlaceParts() {
      this.isLoading = true;
      this.placePartTokenOptions = [];
      this.placePartTokenOptions.push({
        value: "",
        text: this.$t("placesParts.select"),
      });
      try {
        this.placePartData.placeToken = this.placeToken;
        const response = await this.placePartData.getDialogOfPlaceParts(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.placePartTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.placePartTokenOptions = null;
      }
      this.isLoading = false;
    },
  },
  props: ["placeScheduleData", "placeToken"],
  computed: {},
  async created() {
    this.getDialogOfPlaceParts();
  },
};
</script>
