export default class UsersPrivilegeActivities {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.activityView = false;
    this.activityAdd = false;
    this.activityUpdate = false;
    this.activityArchive = false;
    this.activityRestore = false;
    this.activityDelete = false;
  }
  fillData(data) {
    if (data) {
      this.activityView = data.activityView;
      this.activityAdd = data.activityAdd;
      this.activityUpdate = data.activityUpdate;
      this.activityArchive = data.activityArchive;
      this.activityRestore = data.activityRestore;
      this.activityDelete = data.activityDelete;
    } else {
      this.setInitialValue();
    }
  }
}
