<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="revenuesData">
      <RevenuesTable
        :revenuesData="revenuesData"
        :revenueData="revenueData"
        v-on:setRevenueData="revenueData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <RevenueInfo :revenueData="revenueData" />
      <RevenueRefund
        :revenueData="revenueData"
        v-on:refresh="getAllRevenues()"
      />
    </div>

    <RevenueFloatBtns :textSearch="textSearch" v-on:search="search" />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import RevenuesTable from "@/components/finance/revenues/RevenuesTable.vue";
import RevenueInfo from "@/components/finance/revenues/RevenueInfo.vue";
import RevenueRefund from "@/components/finance/revenues/RevenueRefund.vue";
import RevenueFloatBtns from "@/components/finance/revenues/RevenueFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Revenue from "@/models/finance/revenues/Revenue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "Revenues",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    RevenuesTable,
    RevenueInfo,
    RevenueRefund,
    RevenueFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      treasury: "",
      revenuesType: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      revenuesData: null,
      revenueData: new Revenue(),
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllRevenues();
    },
    search(text, treasury, revenuesType) {
      this.textSearch = text;

      this.treasury = treasury;
      this.revenuesType = revenuesType;

      this.getAllRevenues();
    },
    async getAllRevenues() {
      this.isLoading = true;
      try {
        const response = await this.revenueData.getAllRevenues(
          this.language,
          this.userAuthorizeToken,
          this.selfPage,
          this.textSearch,
          this.treasury,
          this.revenuesType
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.revenuesData = response.data.establishmentRevenuesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.revenuesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.revenuesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.revenuesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
        this.revenuesData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllRevenues();
  },
};
</script>
