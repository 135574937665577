var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flip-card col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"flip-card-inner"},[_c('div',{staticClass:"flip-card-front"},[_c('img',{attrs:{"src":_vm.imgSrc,"onerror":("this.src='" + _vm.defaultImg + "'"),"alt":"Image"}}),_c('div',{staticClass:"flip-card-title"},[_c('h4',{staticClass:"cut-1line",attrs:{"title":_vm.placeData.placeNameCurrent}},[_vm._v(" "+_vm._s(_vm.placeData.placeNameCurrent)+" ")])])]),_c('div',{staticClass:"flip-card-back"},[_c('div',{staticClass:"flip-card-back-icons"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.PlaceInfo",modifiers:{"PlaceInfo":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('info')},on:{"click":_vm.setPlaceData}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})]),_c('router-link',{attrs:{"to":{
            name: 'PlaceEdit',
            params: { placeToken: ("" + (_vm.placeData.placeToken)) },
          },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.PlaceDelete",modifiers:{"PlaceDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":_vm.setPlaceData}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})]),_c('router-link',{attrs:{"to":{
            name: 'PlacesWorkPlans',
            params: { placeToken: ("" + (_vm.placeData.placeToken)) },
          },"title":_vm.$t('pages.PlacesWorkPlans')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/places-work-plans.svg")}})]),_c('router-link',{attrs:{"to":{
            name: 'EnquiryReservationAdd',
            params: { placeToken: ("" + (_vm.placeData.placeToken)) },
          },"title":_vm.$t('pages.EnquiryReservationAdd')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/reservations.svg")}})]),_c('router-link',{attrs:{"to":{
            name: 'Offers',
            params: { placeToken: ("" + (_vm.placeData.placeToken)) },
          },"title":_vm.$t('pages.Offers')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/offers.svg")}})]),_c('router-link',{attrs:{"to":{
            name: 'PlacesParts',
            params: { placeToken: ("" + (_vm.placeData.placeToken)) },
          },"title":_vm.$t('placesParts.modelName')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/PlacesParts.svg")}})]),_c('router-link',{attrs:{"to":{
            name: 'PlacesMedias',
            params: { placeToken: ("" + (_vm.placeData.placeToken)) },
          },"title":_vm.$t('placesMedias.modelName')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/places-media.svg")}})])],1),_c('div',{staticClass:"flip-card-title"},[_c('h4',{staticClass:"cut-1line",attrs:{"title":_vm.placeData.placeNameCurrent}},[_vm._v(" "+_vm._s(_vm.placeData.placeNameCurrent)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }