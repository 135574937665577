<template>
  <div v-if="treasuriesTransactionsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("treasuryName") }}</th>
            <th>{{ $t("transactionCode") }}</th>
            <th>{{ $t("transactionSerialNumber") }}</th>
            <th>{{ $t("money") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
            <th>{{ $t("userMakeAction") }}</th>
            <th>{{ $t("notes") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(treasuryTransaction, index) in treasuriesTransactionsData"
            :key="treasuryTransaction.transactionToken"
          >
            <td>{{ ++index }}</td>
            <td>
              {{
                isDataExist(
                  treasuryTransaction.establishmentTreasuryNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  treasuryTransaction.establishmentTreasuryTransactionCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  treasuryTransaction.establishmentTreasuryTransactionSerialNumber
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  treasuryTransaction.establishmentTreasuryTransactionMoney +
                    $t("EGP")
                )
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  treasuryTransaction.actionDate,
                  treasuryTransaction.actionTime
                )
              }}
            </td>
            <td>{{ isDataExist(treasuryTransaction.userNameCurrent) }}</td>
            <td>
              {{
                isDataExist(
                  treasuryTransaction.establishmentTreasuryTransactionNote
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  setDataMultiLang,
  timeToLang,
  isDataExist,
  formateDateTimeLang,
} from "@/utils/functions";

export default {
  name: "TreasuriesTable",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["treasuriesTransactionsData", "treasuryData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
