<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div v-if="governoratesData">
      <GovernatesTable
        :governatesData="governoratesData"
        :governateData="governorateData"
        v-on:setGovernateData="governorateData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <GovernateInfo :governateData="governorateData" />
      <GovernateDelete
        :governateData="governorateData"
        v-on:refresh="getAllGovernates()"
      />
    </div>

    <GovernateBtns :textSearch="textSearch" v-on:search="search" />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";

import GovernatesTable from "@/components/settings/settingsOther/governates/GovernatesTable.vue";
import GovernateInfo from "@/components/settings/settingsOther/governates/GovernateInfo.vue";
import GovernateDelete from "@/components/settings/settingsOther/governates/GovernateDelete.vue";
import GovernateBtns from "@/components/settings/settingsOther/governates/GovernateBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Governate from "@/models/settings/settingsOther/governates/Governate";

export default {
  name: "Governates",
  mixins: [createToastMixin],
  components: {
    GovernatesTable,
    GovernateInfo,
    GovernateDelete,
    GovernateBtns,
    Pagination,
  },
  data() {
    return {
      governoratesData: null,
      governorateData: new Governate(),
      filter: {
        country: "",
      },
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllGovernates();
    },
    search(text, country) {
      this.textSearch = text;
      this.filter.country = country;
      this.getAllGovernates();
    },
    async getAllGovernates() {
      this.isLoading = true;
      try {
        const response = await this.governorateData.getAllGovernates(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.textSearch,
          this.filter.country
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.governoratesData = response.data.governoratesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.governoratesData = null;
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.governoratesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.governoratesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.governoratesData = null;
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllGovernates();
  },
};
</script>
