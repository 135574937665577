<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdatePaymentMethode()"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'paymentMethodeNameAr'"
            :value="paymentMethodeData.paymentMethodeNameAr"
            :title="$t('nameAr')"
            :imgName="'method-paids.svg'"
            v-on:changeValue="paymentMethodeData.paymentMethodeNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'paymentMethodeNameEn'"
            :value="paymentMethodeData.paymentMethodeNameEn"
            :title="$t('nameEn')"
            :imgName="'method-paids.svg'"
            v-on:changeValue="paymentMethodeData.paymentMethodeNameEn = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'paymentMethodeNameUnd'"
            :value="paymentMethodeData.paymentMethodeNameUnd"
            :title="$t('nameUnd')"
            :imgName="'method-paids.svg'"
            v-on:changeValue="paymentMethodeData.paymentMethodeNameUnd = $event"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'paymentMethodeDescriptionEn'"
            :value="paymentMethodeData.paymentMethodeDescriptionEn"
            :title="$t('descriptionEn')"
            :imgName="'description.svg'"
            v-on:changeValue="
              paymentMethodeData.paymentMethodeDescriptionEn = $event
            "
          />
          <TextArea
            :className="'col-md-6'"
            :id="'paymentMethodeDescriptionAr'"
            :value="paymentMethodeData.paymentMethodeDescriptionAr"
            :title="$t('descriptionAr')"
            :imgName="'description.svg'"
            v-on:changeValue="
              paymentMethodeData.paymentMethodeDescriptionAr = $event
            "
          />
          <TextArea
            :className="'col-md-6'"
            :id="'paymentMethodeDescriptionUnd'"
            :value="paymentMethodeData.paymentMethodeDescriptionUnd"
            :title="$t('descriptionUnd')"
            :imgName="'description.svg'"
            v-on:changeValue="
              paymentMethodeData.paymentMethodeDescriptionUnd = $event
            "
          />
          <TextArea
            :className="'col-md-12'"
            :id="'paymentMethodeNotes'"
            :value="paymentMethodeData.paymentMethodeNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="paymentMethodeData.paymentMethodeNotes = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'PaymentMethodes' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "PaymentMethodeForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["paymentMethodeData", "submitName"],
  methods: {
    async addOrUpdatePaymentMethode() {
      this.$emit("addOrUpdatePaymentMethode");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss"></style>
