<template>
  <nav>
    <PreLoader v-if="isLoading" />
    <input type="checkbox" id="show-menu" @click="changeMenuStatus" />
    <label for="show-menu" class="menu-icon"><i class="fa fa-bars"></i></label>
    <!-- <span class="logo-icon" :title="$t('projectName')">
      <img src="@/assets/images/logo.png" class="logo" />
    </span> -->
    <div class="page-name">
      <!-- <img src="@/assets/images/homepage.svg" /> -->
      <img :src="require('@/assets/images/' + pageIcon)" />
      <h3>{{ pageName }}</h3>
    </div>
    <div class="user-icon" v-if="userData">
      <b-dropdown id="dropdown-user">
        <template #button-content>
          <img
            :src="userImage"
            :onerror="`this.src='${defaultImg}'`"
            alt="images"
          />
        </template>
        <div class="text-center">
          <img
            :src="userImage"
            :onerror="`this.src='${defaultImg}'`"
            alt="images"
            class="img-profile"
          />
          <b-dropdown-item>{{ userName }}</b-dropdown-item>
        </div>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-if="language == 'en'" @click="changeLanguage('ar')">
          <img src="@/assets/images/lang-ar.svg" />
          العربية
        </b-dropdown-item>
        <b-dropdown-item v-if="language == 'ar'" @click="changeLanguage('en')">
          <img src="@/assets/images/lang-en.svg" />
          English
        </b-dropdown-item>
        <!-- <b-dropdown-item v-if="theme == 'dark'" @click="changeTheme('light')">
          <img src="@/assets/images/mode-light.svg" />
          {{ $t("lightMode") }}
        </b-dropdown-item>
        <b-dropdown-item v-if="theme == 'light'" @click="changeTheme('dark')">
          <img src="@/assets/images/mode-dark.svg" />
          {{ $t("darkMode") }}
        </b-dropdown-item> -->
        <b-dropdown-item v-b-modal.UserChangePassword>
          <img src="@/assets/images/changePassword.svg" />
          {{ $t("changePassword") }}
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.UserLogOut>
          <img src="@/assets/images/logout.svg" />
          {{ $t("logout") }}
        </b-dropdown-item>
        <!-- <b-dropdown-item active>Active action</b-dropdown-item> -->
      </b-dropdown>
    </div>

    <UserChangePassword v-on:logout="logout" />
    <UserLogOut v-on:logout="logout" />
  </nav>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
// import DEFAULT_IMG from "@/assets/images/user-profile.png";
import DEFAULT_IMG from "@/assets/images/employee.svg";
import { PAGENAME_ICONS } from "@/utils/pageNameIcons";
import { mapGetters } from "vuex";
import UserChangePassword from "@/components/user/UserChangePassword.vue";
import UserLogOut from "@/components/user/UserLogOut.vue";
import { BASE_URL } from "@/utils/constants";

export default {
  name: "Navbar",
  components: {
    PreLoader,
    UserChangePassword,
    UserLogOut,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      theme: localStorage.getItem("userTheme") || "light",
      checked: false,
      pageName: this.$t("homePage"),
      pageIcon: "homepage.svg",
      defaultImg: DEFAULT_IMG,
    };
  },
  methods: {
    logout() {
      this.isLoading = true;
      this.$store.dispatch("logoutUser");
      this.$router.push("/").catch(() => {});
    },
    changeLanguage(lang) {
      this.isLoading = true;
      localStorage.setItem("userLanguage", lang);
      window.location.reload();
    },
    changeTheme(theme) {
      this.isLoading = true;
      localStorage.setItem("userTheme", theme);
      window.location.reload();
    },
    changeMenuStatus() {
      this.$emit("changeMenuStatus");
    },
    getPageName(name) {
      let pageName = "pages." + name;
      this.pageName = this.$t(`${pageName}`);
      let pageIcon = PAGENAME_ICONS[name];
      this.pageIcon = pageIcon;
    },
  },
  computed: {
    ...mapGetters(["userData", "userType"]),
    userName: function () {
      if (this.userData) {
        if (this.userType == "employee") {
          return this.language == "ar"
            ? this.userData.employeePersonalData.employeeNameAr
            : this.userData.employeePersonalData.employeeNameEn;
        }
        if (this.userType == "client") {
          return this.language == "ar"
            ? this.userData.clientPersonalData.clientNameAr
            : this.userData.clientPersonalData.clientNameEn;
        }
        return null;
      } else {
        return null;
      }
    },
    userImage: function () {
      if (this.userData) {
        if (this.userType == "employee") {
          return (
            BASE_URL + this.userData.employeePersonalData.employeeImagePath
          );
        }
        if (this.userType == "client") {
          return BASE_URL + this.userData.clientPersonalData.clientImagePath;
        }
        return null;
      } else {
        return this.defaultImg;
      }
    },
  },
  // async created() {
  //   this.employeeProfileData = JSON.parse(
  //     localStorage.getItem("employeeProfileData")
  //   );
  //   console.log(this.employeeProfileData);
  // },
  watch: {
    $route() {
      this.getPageName(this.$route.name);
    },
  },
  async created() {
    this.getPageName(this.$route.name);
  },
};
</script>

<style lang="scss">
#dropdown-user button {
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: inherit;
  position: relative;
  border: initial;
  // top: -6px;
  // left: 6px;
}
#dropdown-user button::after {
  border: initial;
}
// #dropdown-user button::after {
//   background-image: url("../../assets/images/man.svg");
//   background-size: 35px;
//   display: inline-block;
//   width: 35px;
//   height: 35px;
//   content: "";
// }
#dropdown-user ul {
  width: 220px;
  // transform: translate3d(0px, 29px, 0px) !important;
  // transform: translate3d(-190px, 29px, 0px) !important;
  // right: var(--position-right-status) !important;
  // left: var(--position-left-status) !important;
}
.user-icon .dropdown-menu li img {
  width: 28px;
  height: 28px;
  border-radius: inherit;
  position: absolute;
  right: 6px;
}
.user-icon .img-profile {
  width: 150px;
  height: initial;
  min-height: 150px;
  max-height: 190px;
  border-radius: inherit;
  margin-bottom: 1rem;
}
</style>
