<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <DistrictForm
      v-if="!isLoading"
      :districtData="districtData"
      :submitName="$t('edit')"
      v-on:addOrUpdateDistrict="updateDistrict"
    />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";
import DistrictForm from "@/components/settings/settingsOther/districts/DistrictForm.vue";
import District from "@/models/settings/settingsOther/districts/District";

export default {
  name: "DistrictEdit",
  mixins: [createToastMixin],
  components: {
    DistrictForm,
  },
  data() {
    return {
      districtData: new District(),
      districtToken: this.$route.params.districtToken,
    };
  },
  methods: {
    async getDistrictDetails() {
      this.isLoading = true;
      this.districtData.districtToken = this.districtToken;
      try {
        let response = await this.districtData.getDistrictDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.districtData.fillData(response.data.districtData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.districtData = null;
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.districtData = null;
      }
      this.isLoading = false;
    },
    async updateDistrict() {
      this.isLoading = true;
      try {
        let response = await this.districtData.addOrUpdateDistrict(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "Districts" });
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getDistrictDetails();
  },
};
</script>

<style scoped lang="scss"></style>
