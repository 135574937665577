export default class PlacesPartsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placeToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    if (data) {
      this.placeToken = data.placeToken || "";
      this.textSearch = data.textSearch || "";
    } else {
      this.setInitialValue();
    }
  }
}

