import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
export default class PlacesWorkPlan {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placeWorkPlanToken = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.placeToken = "";
    this.placeNameCurrent = "";
    this.placeWorkPlanCode = "";
    this.placeWorkPlanNameCurrent = "";
    this.placeWorkPlanNameEn = "";
    this.placeWorkPlanNameAr = "";
    this.placeWorkPlanNameUnd = "";
    this.placeWorkPlanDescriptionCurrent = "";
    this.placeWorkPlanDescriptionEn = "";
    this.placeWorkPlanDescriptionAr = "";
    this.placeWorkPlanDescriptionUnd = "";
    this.startActiveDate = "";
    this.startActiveTime = "";
    this.startActiveDateTime = "";
    this.endActiveDate = "";
    this.endActiveTime = "";
    this.endActiveDateTime = "";
    this.placeWorkPlanNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.placeWorkPlanActiveStatus = true;
    this.placeWorkPlanArchiveStatus = "";
  }
  fillData(data) {
    this.placeWorkPlanToken = data.placeWorkPlanToken;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.placeToken = data.placeToken;
    this.placeNameCurrent = data.placeNameCurrent;
    this.placeWorkPlanCode = data.placeWorkPlanCode;
    this.placeWorkPlanNameCurrent = data.placeWorkPlanNameCurrent;
    this.placeWorkPlanNameEn = data.placeWorkPlanNameEn;
    this.placeWorkPlanNameAr = data.placeWorkPlanNameAr;
    this.placeWorkPlanNameUnd = data.placeWorkPlanNameUnd;
    this.placeWorkPlanDescriptionCurrent = data.placeWorkPlanDescriptionCurrent;
    this.placeWorkPlanDescriptionEn = data.placeWorkPlanDescriptionEn;
    this.placeWorkPlanDescriptionAr = data.placeWorkPlanDescriptionAr;
    this.placeWorkPlanDescriptionUnd = data.placeWorkPlanDescriptionUnd;
    this.startActiveDate = data.startActiveDate;
    this.startActiveTime = data.startActiveTime;
    this.startActiveDateTime = data.startActiveDateTime;
    this.endActiveDate = data.endActiveDate;
    this.endActiveTime = data.endActiveTime;
    this.endActiveDateTime = data.endActiveDateTime;
    this.placeWorkPlanNotes = data.placeWorkPlanNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.placeWorkPlanActiveStatus = data.placeWorkPlanActiveStatus;
    this.placeWorkPlanArchiveStatus = data.placeWorkPlanArchiveStatus;
  }

  async getAllPlacesWorkPlans(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    placeToken = ""
  ) {
    try {
      let response = await axios.get(
        `/PlacesWorkPlans/GetAllPlacesWorkPlans?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&textSearch=${textSearch}&placeToken=${placeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getPlacesWorkPlansDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PlacesWorkPlans/GetPlaceWorkPlanDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&placeWorkPlanToken=${this.placeWorkPlanToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdatePlacesWorkPlan(language, userAuthorizeToken) {
    this.startActiveDateTime =
      this.startActiveDate + " " + this.startActiveTime;
    this.endActiveDateTime = this.endActiveDate + " " + this.endActiveTime;

    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placeWorkPlanToken: this.placeWorkPlanToken,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      placeToken: this.placeToken,
      placeNameCurrent: this.placeNameCurrent,
      placeWorkPlanCode: this.placeWorkPlanCode,
      placeWorkPlanNameCurrent: this.placeWorkPlanNameCurrent,
      placeWorkPlanNameEn: this.placeWorkPlanNameEn,
      placeWorkPlanNameAr: this.placeWorkPlanNameAr,
      placeWorkPlanNameUnd: this.placeWorkPlanNameUnd,
      placeWorkPlanDescriptionCurrent: this.placeWorkPlanDescriptionCurrent,
      placeWorkPlanDescriptionEn: this.placeWorkPlanDescriptionEn,
      placeWorkPlanDescriptionAr: this.placeWorkPlanDescriptionAr,
      placeWorkPlanDescriptionUnd: this.placeWorkPlanDescriptionUnd,
      startActiveDateTime: this.startActiveDateTime,
      endActiveDateTime: this.endActiveDateTime,
      placeWorkPlanNotes: this.placeWorkPlanNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      placeWorkPlanActiveStatus: this.placeWorkPlanActiveStatus,
      placeWorkPlanArchiveStatus: this.placeWorkPlanArchiveStatus,
    };

    try {
      let response = "";
      if (
        this.placeWorkPlanToken == "" ||
        this.placeWorkPlanToken == undefined
      ) {
        response = await axios.post(`/PlacesWorkPlans/AddPlaceWorkPlan`, data);
      } else {
        response = await axios.post(
          `/PlacesWorkPlans/UpdatePlaceWorkPlan`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archivePlacesWorkPlan(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placeWorkPlanToken: this.placeWorkPlanToken,
    };

    try {
      let response = await axios.post(
        `/PlacesWorkPlans/ArchivePlaceWorkPlan`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
