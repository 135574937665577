export default class PlacesMediasFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.placeToken = "";
    this.placeMediaToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.placeToken = data.placeToken;
    this.placeMediaToken = data.placeMediaToken;
    this.textSearch = data.textSearch;
  }
}
