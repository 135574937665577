<template>
  <div v-if="placesPartsData" class="">
    <!-- new edit m -->
    <div>
      <PlacePartsDelete
        @deletePlacePart="deletePlacePart()"
        :deletePlaceParts="deletePlaceParts"
        @CloseDelete="CloseDelete()"
        v-show="deletePlaceParts"
        :placePartToken="placePartToken"
      />
      <placePartEdit
        v-show="editPart"
        v-on:hideNow="hideNow()"
        :editPart="editPart"
        :itemObjectDetails="itemObjectDetails"
        :placePartToken="placePartToken"
        :placeToken="placeToken"
      />
    </div>
    <!-- end edit m -->
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("placesParts.code") }}</th>
            <th>{{ $t("placesParts.name") }}</th>
            <th>{{ $t("places.name") }}</th>
            <th>{{ $t("placesParts.description") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
            <th>{{ $t("info") }}</th>
            <!-- <th>{{ $t("edit") }}</th> -->
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in placesPartsData" :key="index">
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(item.placePartCode) }}</td>
            <td>{{ isDataExist(item.placePartNameCurrent) }}</td>
            <td>{{ isDataExist(item.placeNameCurrent) }}</td>
            <td>{{ isDataExist(item.placePartDescriptionCurrent) }}</td>
            <td>{{ isDataExist(item.placePartNotes) }}</td>
            <td>
              {{ formateDateTimeLang(item.actionDate, item.actionTime) }}
            </td>
            <td>
              <button
                v-b-modal.PlacePartInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setPlacePartData(item)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <!-- Edit PlacePart
            <td>
              <button v-b-modal.PlacePartEdit @click="showEdit(item)">
                <img src="/img/pencil.afad546c.svg" class="icon-lg" />
              </button>
            </td> -->
            <td>
              <!-- deletePlacePart(item.placePartToken); -->
              <button @click="showDelete(item.placePartToken)">
                <img src="/img/trash.e8ae5ee4.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { isDataExist, formateDateTimeLang } from "@/utils/functions";

import placePartEdit from "@/components/places/placesParts/placePartEdit.vue";
import PlacePartsDelete from "./PlacePartsDelete.vue";
import { BASE_URL } from "@/utils/constants.js";
import axios from "axios";
// import { STATUS } from "@/utils/constants";

export default {
  name: "PlacesPartsTable",
  components: {
    placePartEdit,
    PlacePartsDelete,
  },
  data() {
    return {
      editPart: false,
      deletePlaceParts: false,
      placePartToken: null,
      placeToken: null,
      itemObjectDetails: {},
      place: {},

      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["placesPartsData"],
  methods: {
    hideNow() {
      this.editPart = false;
    },
    setPlacePartData(item) {
      // console.log(item);
      this.$emit("setPlacePartData", item);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    // ==========================
    showEdit(place) {
      this.editPart = true;
      this.placePartToken = place.placePartToken;
      this.placeToken = place.placeToken;
      this.place = place;
      const item = this.placesPartsData.find(
        (item) => item.placePartToken === place.placePartToken
      );
      this.itemObjectDetails = item;
    },
    showDelete(placePartToken) {
      this.deletePlaceParts = true;
      this.placePartToken = placePartToken;
    },
    CloseDelete() {
      this.deletePlaceParts = false;
    },
    async deletePlacePart() {
      console.log("delete");
      this.deletePlaceParts = false;
      try {
        const response = await axios.post(
          `${BASE_URL}/PlaceParts/FinalDeletePlacePart`,
          {
            placePartToken: this.placePartToken,
            language: this.language,
            userAuthorizeToken: this.userAuthorizeToken,
          }
        );
        if (response.status === 200) {
          // console.log(response);
          this.$emit("reloadData");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editPlacePart() {
      this.editPart = false;
      try {
        const response = await axios.get(
          `${BASE_URL}/PlaceParts/GetPlacePartDetails`,
          {
            params: {
              placePartToken: this.placePartToken,
              language: this.language,
              userAuthorizeToken: this.userAuthorizeToken,
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          this.$emit("reloadData");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  computed: {
    itemObjectDetailsObject() {
      return this.itemObjectDetails;
    },
  },
  async created() {
    // this.editPlacePart();
  },
};
</script>

