import { render, staticRenderFns } from "./UsersPrivilegePlaces.vue?vue&type=template&id=16fa19bc&scoped=true&"
import script from "./UsersPrivilegePlaces.vue?vue&type=script&lang=js&"
export * from "./UsersPrivilegePlaces.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16fa19bc",
  null
  
)

export default component.exports