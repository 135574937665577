import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class Governate {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.governorateToken = "";
    this.countryToken = "";
    this.countryNameCurrent = "";
    this.governorateNameCurrent = "";
    this.governorateNameEn = "";
    this.governorateNameAr = "";
    this.governorateNameUnd = "";
    this.governorateDescriptionCurrent = "";
    this.governorateDescriptionEn = "";
    this.governorateDescriptionAr = "";
    this.governorateDescriptionUnd = "";
    this.governorateNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.governorateArchiveStatus = "";
  }
  fillData(data) {
    this.governorateToken = data.governorateToken;
    this.countryToken = data.countryToken;
    this.countryNameCurrent = data.countryNameCurrent;
    this.governorateNameCurrent = data.governorateNameCurrent;
    this.governorateNameEn = data.governorateNameEn;
    this.governorateNameAr = data.governorateNameAr;
    this.governorateNameUnd = data.governorateNameUnd;
    this.governorateDescriptionCurrent = data.governorateDescriptionCurrent;
    this.governorateDescriptionEn = data.governorateDescriptionEn;
    this.governorateDescriptionAr = data.governorateDescriptionAr;
    this.governorateDescriptionUnd = data.governorateDescriptionUnd;
    this.governorateNotes = data.governorateNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.governorateArchiveStatus = data.governorateArchiveStatus;
  }

  async getAllGovernates(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    countryToken = ""
  ) {
    try {
      let response = await axios.get(
        `/Governorates/GetAllGovernorates?language=${language}&userAuthorizeToken=${userAuthorizeToken}&governorateArchiveStatus=false&page=${selfPage}&paginationStatus=&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}&sortToken=&countryToken=${countryToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getGovernateDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Governorates/GetGovernorateDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&governorateToken=${this.governorateToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfGovernates(language, userAuthorizeToken, countryToken = "") {
    try {
      let response = await axios.get(
        `/Governorates/GetDialogOfGovernorates?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=&filterStatus=true&pageSize=${PAGE_SIZE}&countryToken=${countryToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateGovernate(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      governorateToken: this.governorateToken,
      countryToken: this.countryToken,
      governorateNameEn: this.governorateNameEn,
      governorateNameAr: this.governorateNameAr,
      governorateNameUnd: this.governorateNameUnd,
      governorateDescriptionEn: this.governorateDescriptionEn,
      governorateDescriptionAr: this.governorateDescriptionAr,
      governorateDescriptionUnd: this.governorateDescriptionUnd,
      governorateNotes: this.governorateNotes,
    };

    try {
      let response = "";
      if (this.governorateToken == "" || this.governorateToken == undefined) {
        response = await axios.post(`/Governorates/AddGovernorate`, data);
      } else {
        response = await axios.post(`/Governorates/UpdateGovernorate`, data);
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveGovernate(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      governorateToken: this.governorateToken,
    };

    try {
      let response = await axios.post(`/Governorates/ArchiveGovernorate`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
