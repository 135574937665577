<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div v-if="districtsData">
      <DistrictsTable
        :districtsData="districtsData"
        :districtData="districtData"
        v-on:setDistrictData="districtData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <DistrictInfo :districtData="districtData" />
      <DistrictDelete
        :districtData="districtData"
        v-on:refresh="getAllDistricts()"
      />
    </div>

    <DistrictBtns :textSearch="textSearch" v-on:search="search" />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";

import DistrictsTable from "@/components/settings/settingsOther/districts/DistrictsTable.vue";
import DistrictInfo from "@/components/settings/settingsOther/districts/DistrictInfo.vue";
import DistrictDelete from "@/components/settings/settingsOther/districts/DistrictDelete.vue";
import DistrictBtns from "@/components/settings/settingsOther/districts/DistrictBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import District from "@/models/settings/settingsOther/districts/District";

export default {
  name: "Districts",
  mixins: [createToastMixin],
  components: {
    DistrictsTable,
    DistrictInfo,
    DistrictDelete,
    DistrictBtns,
    Pagination,
  },
  data() {
    return {
      districtsData: null,
      districtData: new District(),
      filter: {
        country: "",
        governate: "",
        city: "",
      },
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllDistricts();
    },
    search(text, country, governate, city) {
      this.textSearch = text;
      this.filter.country = country;
      this.filter.governate = governate;
      this.filter.city = city;
      this.getAllDistricts();
    },
    async getAllDistricts() {
      this.isLoading = true;
      try {
        const response = await this.districtData.getAllDistricts(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.textSearch,
          this.filter.country,
          this.filter.governate,
          this.filter.city
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.districtsData = response.data.districtsData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.districtsData = null;
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.districtsData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.districtsData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.districtsData = null;
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllDistricts();
    // console.log(this.districtsData);
  },
};
</script>
