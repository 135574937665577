import { checkLoginForShowSidebar } from "@/utils/functions";
import UsersEstablishmentPrivilege from "@/models/privileges/usersPrivileges/UsersEstablishmentPrivilege";
let userPrivilege = new UsersEstablishmentPrivilege();

export default {
  state: {
    userData: null,
    userType: null,
    usersEstablishmentPrivilegeData: null,
  },
  getters: {
    userData: (state) => state.userData,
    userType: (state) => state.userType,
    usersEstablishmentPrivilegeData: (state) =>
      state.usersEstablishmentPrivilegeData,
  },
  mutations: {
    UPDATE_USER_DATA: (state, userData) => {
      userPrivilege.fillData(userData.usersEstablishmentPrivilegeData);

      state.userData = userData;
      state.usersEstablishmentPrivilegeData = userPrivilege;
      checkLoginForShowSidebar();
    },
    UPDATE_USER_TYPE: (state, userType) => {
      state.userType = userType;
    },
    LOGOUT_USER: (state) => {
      state.userData = null;
      state.usersEstablishmentPrivilegeData = null;
      // state.employeePersonalData = null;
      // state.clientPersonalData = null;
      // state.userPersonalData = null;
      // state.constantsLists = null;
      // state.usersVersionData = null;
      checkLoginForShowSidebar();
    },
  },
  actions: {
    loginUser(context, userData) {
      context.commit("UPDATE_USER_DATA", userData);
    },
    logoutUser(context) {
      context.commit("LOGOUT_USER");
    },
    updateUserData(context, userData) {
      context.commit("UPDATE_USER_DATA", userData);
    },
    updateUserType(context, userType) {
      context.commit("UPDATE_USER_TYPE", userType);
    },
  },
  modules: {},
};
