<template>
  <div class="home">
    <SignUpForm />
  </div>
</template>

<script>
import SignUpForm from "@/components/user/SignUpForm.vue";

export default {
  name: "Login",
  components: {
    SignUpForm,
  },
};
</script>
