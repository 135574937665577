<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <TreasuryForm
      v-if="!isLoading"
      :treasuryData="treasuryData"
      :submitName="$t('edit')"
      v-on:addOrUpdateTreasury="updateTreasury"
    />
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import createToastMixin from "@/utils/create-toast-mixin";
import TreasuryForm from "@/components/finance/treasuries/TreasuryForm.vue";
import Treasury from "@/models/finance/treasuries/Treasury";

export default {
  name: "TreasuryEdit",
  mixins: [createToastMixin],
  components: {
    TreasuryForm,
  },
  data() {
    return {
      treasuryData: new Treasury(),
      establishmentTreasuryToken: this.$route.params.establishmentTreasuryToken,
    };
  },
  methods: {
    async getTreasuryDetails() {
      this.isLoading = true;
      this.treasuryData.establishmentTreasuryToken =
        this.establishmentTreasuryToken;
      try {
        let response = await this.treasuryData.getTreasuryDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.treasuryData.fillData(response.data.establishmentTreasuryData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.treasuryData = null;
        }
      } catch (error) {
        this.treasuryData = null;
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = "noInternet.svg";
      }
      this.isLoading = false;
    },
    async updateTreasury() {
      this.isLoading = true;
      try {
        let response = await this.treasuryData.addOrUpdateTreasury(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "Treasuries" });
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getTreasuryDetails();
  },
};
</script>

<style scoped lang="scss"></style>
