<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdatePlaceMedia()"
    >
      <!-- <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          :onerror="`this.backgroundImage = url(${defaultImg})`"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div> -->
      <div class="row">
        <div class="col-md-12 image-container">
          <div class="imgView" @click="selectMedia">
            <img
              v-if="placeMediaData.placeMediaTypeToken == imageToken"
              :src="src"
              :onerror="`this.src='${defaultImg}'`"
              alt="Image"
              class="fit-container"
            />
            <video
              v-if="placeMediaData.placeMediaTypeToken == videoToken"
              class="fit-container"
            >
              <source
                :src="src"
                type="video/mp4"
                :onerror="`this.src='${defaultImg}'`"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <input
            ref="fileInput"
            type="file"
            v-on:change="pickFile"
            class="d-none"
            accept=".jpg, .jpeg, .png, .mov, .avi, .wmv, .mp4, .m4p, .m4v, .ogg, .mpg, .mp2, .mpeg, .mpe, .mpv, .3gp, .flv"
          />
        </div>
      </div>

      <div class="my-card">
        <div class="row">
          <TextArea
            :className="'col-md-12'"
            :id="'placeMediaNameAr'"
            :value="placeMediaData.placeMediaNameAr"
            v-on:changeValue="placeMediaData.placeMediaNameAr = $event"
            :title="$t('placesMedias.nameAr')"
            :imgName="'places-media.svg'"
          />
          <TextArea
            :className="'col-md-12'"
            :id="'placeMediaNameEn'"
            :value="placeMediaData.placeMediaNameEn"
            v-on:changeValue="placeMediaData.placeMediaNameEn = $event"
            :title="$t('placesMedias.nameEn')"
            :imgName="'places-media.svg'"
          />
          <TextArea
            :className="'col-md-12'"
            :id="'placeMediaNameUnd'"
            :value="placeMediaData.placeMediaNameUnd"
            v-on:changeValue="placeMediaData.placeMediaNameUnd = $event"
            :title="$t('placesMedias.nameUnd')"
            :imgName="'places-media.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link
          :to="`/places/places-medias/${placeMediaData.placeToken}`"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import {
  VIDEO_EXTENSIONS,
  NEWS_MEDIA_TYPE_TOKEN_IMAGE,
  NEWS_MEDIA_TYPE_TOKEN_VIDEO,
} from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/places-media.svg";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "PlaceMediaForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,
      videoExtensions: VIDEO_EXTENSIONS,
      imageToken: NEWS_MEDIA_TYPE_TOKEN_IMAGE,
      videoToken: NEWS_MEDIA_TYPE_TOKEN_VIDEO,
      src: this.mediaSrc,
    };
  },
  props: ["placeMediaData", "mediaSrc", "submitName"],
  methods: {
    selectMedia() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let extension = input.files[0].name.split(".").pop().toLowerCase(); //file extension from input file
        let isVideo = this.videoExtensions.indexOf(extension) > -1;
        let reader = new FileReader();
        reader.onload = (e) => {
          if (isVideo) {
            this.placeMediaData.placeMediaTypeToken = this.videoToken;
          } else {
            this.placeMediaData.placeMediaTypeToken = this.imageToken;
          }
          this.src = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.placeMediaData.media = file[0];
      } else {
        this.src = DEFAULT_IMG;
        this.placeMediaData.media = "";
      }
    },
    async addOrUpdatePlaceMedia() {
      this.$emit("addOrUpdatePlaceMedia");
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.fit-container {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  height: -moz-available;
  width: -moz-available;
}
.videoInsert {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
}
</style>
