import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class City {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.cityToken = "";
    this.cityNameEn = "";
    this.cityNameAr = "";
    this.cityNameUnd = "";
    this.cityNameCurrent = "";
    this.governorateToken = "";
    this.governorateNameCurrent = "";
    this.countryToken = "";
    this.countryNameCurrent = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.cityDescriptionCurrent = "";
    this.cityDescriptionEn = "";
    this.cityDescriptionAr = "";
    this.cityDescriptionUnd = "";
    this.cityNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.cityArchiveStatus = "";
  }
  fillData(data) {
    this.cityToken = data.cityToken;
    this.cityNameEn = data.cityNameEn;
    this.cityNameAr = data.cityNameAr;
    this.cityNameUnd = data.cityNameUnd;
    this.cityNameCurrent = data.cityNameCurrent;
    this.governorateToken = data.governorateToken;
    this.governorateNameCurrent = data.governorateNameCurrent;
    this.countryToken = data.countryToken;
    this.countryNameCurrent = data.countryNameCurrent;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.cityDescriptionCurrent = data.cityDescriptionCurrent;
    this.cityDescriptionEn = data.cityDescriptionEn;
    this.cityDescriptionAr = data.cityDescriptionAr;
    this.cityDescriptionUnd = data.cityDescriptionUnd;
    this.cityNotes = data.cityNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.cityArchiveStatus = data.cityArchiveStatus;
  }

  async getAllCities(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    countryToken = "",
    governateToken = ""
  ) {
    try {
      let response = await axios.get(
        `/Cities/GetAllCities?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${selfPage}&paginationStatus=&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}&sortToken=&countryToken=${countryToken}&governorateToken=${governateToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getCityDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Cities/GetCityDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityToken=${this.cityToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfCities(language, userAuthorizeToken, governateToken = "") {
    try {
      let response = await axios.get(
        `/Cities/GetDialogOfCities?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&governorateToken=${governateToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateCity(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      cityToken: this.cityToken,
      cityNameEn: this.cityNameEn,
      cityNameAr: this.cityNameAr,
      cityNameUnd: this.cityNameUnd,
      cityNameCurrent: this.cityNameCurrent,
      governorateToken: this.governorateToken,
      governorateNameCurrent: this.governorateNameCurrent,
      countryToken: this.countryToken,
      countryNameCurrent: this.countryNameCurrent,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      cityDescriptionCurrent: this.cityDescriptionCurrent,
      cityDescriptionEn: this.cityDescriptionEn,
      cityDescriptionAr: this.cityDescriptionAr,
      cityDescriptionUnd: this.cityDescriptionUnd,
      cityNotes: this.cityNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      cityArchiveStatus: this.cityArchiveStatus,
    };

    try {
      let response = "";
      if (this.cityToken == "" || this.cityToken == undefined) {
        response = await axios.post(`/Cities/AddCity`, data);
      } else {
        response = await axios.post(`/Cities/UpdateCity`, data);
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveCity(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      cityToken: this.cityToken,
    };

    try {
      let response = await axios.post(`/Cities/ArchiveCity`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
