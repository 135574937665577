import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class Place {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.placeToken = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.activityToken = "";
    this.activityNameCurrent = "";
    this.countryToken = "";
    this.countryNameCurrent = "";
    this.governorateToken = "";
    this.governorateNameCurrent = "";
    this.cityToken = "";
    this.cityNameCurrent = "";
    this.districtToken = "";
    this.districtNameCurrent = "";
    this.placeCode = "";
    this.placeNameCurrent = "";
    this.placeNameEn = "";
    this.placeNameAr = "";
    this.placeNameUnd = "";
    this.placeImagePath = "";
    this.placeDescriptionCurrent = "";
    this.placeDescriptionEn = "";
    this.placeDescriptionAr = "";
    this.placeDescriptionUnd = "";
    this.placeNotes = "";
    this.placePhone1 = "";
    this.placePhone1CountryCodeName = "";
    this.placePhone1CountryCode = "";
    this.placePhone1WithCC = "";
    this.placePhone2 = "";
    this.placePhone2CountryCodeName = "";
    this.placePhone2CountryCode = "";
    this.placePhone2WithCC = "";
    this.placePhone3 = "";
    this.placePhone3CountryCodeName = "";
    this.placePhone3CountryCode = "";
    this.placePhone3WithCC = "";
    this.placePhone4 = "";
    this.placePhone4CountryCodeName = "";
    this.placePhone4CountryCode = "";
    this.placePhone4WithCC = "";
    this.mapLink = "";
    this.minimumReservationTime = "";
    this.minimumReservationTimeStatus = "";
    this.maximumReservationTime = "";
    this.maximumReservationTimeStatus = "";
    this.placeLatitude = "";
    this.placeLongitude = "";
    this.placeEndLatitude = "";
    this.placeEndLongitude = "";
    this.placeAddressEn = "";
    this.placeAddressAr = "";
    this.placeAddressUnd = "";
    this.placeAddressCurrent = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.placeActiveStatus = true;
    this.placeArchiveStatus = "";
  }
  fillData(data) {
    this.image = data.image;
    this.placeToken = data.placeToken;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.activityToken = data.activityToken;
    this.activityNameCurrent = data.activityNameCurrent;
    this.countryToken = data.countryToken;
    this.countryNameCurrent = data.countryNameCurrent;
    this.governorateToken = data.governorateToken;
    this.governorateNameCurrent = data.governorateNameCurrent;
    this.cityToken = data.cityToken;
    this.cityNameCurrent = data.cityNameCurrent;
    this.districtToken = data.districtToken;
    this.districtNameCurrent = data.districtNameCurrent;
    this.placeCode = data.placeCode;
    this.placeNameCurrent = data.placeNameCurrent;
    this.placeNameEn = data.placeNameEn;
    this.placeNameAr = data.placeNameAr;
    this.placeNameUnd = data.placeNameUnd;
    this.placeImagePath = data.placeImagePath;
    this.placeDescriptionCurrent = data.placeDescriptionCurrent;
    this.placeDescriptionEn = data.placeDescriptionEn;
    this.placeDescriptionAr = data.placeDescriptionAr;
    this.placeDescriptionUnd = data.placeDescriptionUnd;
    this.placeNotes = data.placeNotes;
    this.placePhone1 = data.placePhone1;
    this.placePhone1CountryCodeName = data.placePhone1CountryCodeName;
    this.placePhone1CountryCode = data.placePhone1CountryCode;
    this.placePhone1WithCC = data.placePhone1WithCC;
    this.placePhone2 = data.placePhone2;
    this.placePhone2CountryCodeName = data.placePhone2CountryCodeName;
    this.placePhone2CountryCode = data.placePhone2CountryCode;
    this.placePhone2WithCC = data.placePhone2WithCC;
    this.placePhone3 = data.placePhone3;
    this.placePhone3CountryCodeName = data.placePhone3CountryCodeName;
    this.placePhone3CountryCode = data.placePhone3CountryCode;
    this.placePhone3WithCC = data.placePhone3WithCC;
    this.placePhone4 = data.placePhone4;
    this.placePhone4CountryCodeName = data.placePhone4CountryCodeName;
    this.placePhone4CountryCode = data.placePhone4CountryCode;
    this.placePhone4WithCC = data.placePhone4WithCC;
    this.mapLink = data.mapLink;
    this.minimumReservationTime = data.minimumReservationTime;
    this.minimumReservationTimeStatus = data.minimumReservationTimeStatus;
    this.maximumReservationTime = data.maximumReservationTime;
    this.maximumReservationTimeStatus = data.maximumReservationTimeStatus;
    this.placeLatitude = data.placeLatitude;
    this.placeLongitude = data.placeLongitude;
    this.placeEndLatitude = data.placeEndLatitude;
    this.placeEndLongitude = data.placeEndLongitude;
    this.placeAddressEn = data.placeAddressEn;
    this.placeAddressAr = data.placeAddressAr;
    this.placeAddressUnd = data.placeAddressUnd;
    this.placeAddressCurrent = data.placeAddressCurrent;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.placeActiveStatus = data.placeActiveStatus;
    this.placeArchiveStatus = data.placeArchiveStatus;
  }

  async getAllPlaces(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    filter = {}
  ) {
    try {
      let activityToken = filter.activityToken ?? "";
      let countryToken = filter.countryToken ?? "";
      let governorateToken = filter.governorateToken ?? "";
      let cityToken = filter.cityToken ?? "";
      let districtToken = filter.districtToken ?? "";

      let response = await axios.get(
        `/Places/GetAllPlaces?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}&activityToken=${activityToken}&countryToken=${countryToken}&governorateToken=${governorateToken}&cityToken=${cityToken}&districtToken=${districtToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getPlaceDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Places/GetPlaceDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&placeToken=${this.placeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfPlaces(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Places/GetDialogOfPlaces?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdatePlace(language, userAuthorizeToken) {
    if (this.placePhone1WithCC) {
      let ccNameWithCC1 = this.placePhone1WithCC.split(" ");
      this.placePhone1CountryCodeName = ccNameWithCC1[0];
      this.placePhone1CountryCode = ccNameWithCC1[1];
    }
    if (this.placePhone2WithCC) {
      let ccNameWithCC2 = this.placePhone2WithCC.split(" ");
      this.placePhone2CountryCodeName = ccNameWithCC2[0];
      this.placePhone2CountryCode = ccNameWithCC2[1];
    }

    if (this.placePhone3WithCC) {
      let ccNameWithCC3 = this.placePhone3WithCC.split(" ");
      this.placePhone3CountryCodeName = ccNameWithCC3[0];
      this.placePhone3CountryCode = ccNameWithCC3[1];
    }

    if (this.placePhone4WithCC) {
      let ccNameWithCC4 = this.placePhone4WithCC.split(" ");
      this.placePhone4CountryCodeName = ccNameWithCC4[0];
      this.placePhone4CountryCode = ccNameWithCC4[1];
    }

    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      image: this.image,
      placeToken: this.placeToken,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      activityToken: this.activityToken,
      activityNameCurrent: this.activityNameCurrent,
      countryToken: this.countryToken,
      countryNameCurrent: this.countryNameCurrent,
      governorateToken: this.governorateToken,
      governorateNameCurrent: this.governorateNameCurrent,
      cityToken: this.cityToken,
      cityNameCurrent: this.cityNameCurrent,
      districtToken: this.districtToken,
      districtNameCurrent: this.districtNameCurrent,
      placeCode: this.placeCode,
      placeNameCurrent: this.placeNameCurrent,
      placeNameEn: this.placeNameEn,
      placeNameAr: this.placeNameAr,
      placeNameUnd: this.placeNameUnd,
      placeImagePath: this.placeImagePath,
      placeDescriptionCurrent: this.placeDescriptionCurrent,
      placeDescriptionEn: this.placeDescriptionEn,
      placeDescriptionAr: this.placeDescriptionAr,
      placeDescriptionUnd: this.placeDescriptionUnd,
      placeNotes: this.placeNotes,
      placePhone1: this.placePhone1,
      placePhone1CountryCodeName: this.placePhone1CountryCodeName,
      placePhone1CountryCode: this.placePhone1CountryCode,
      placePhone1WithCC: this.placePhone1WithCC,
      placePhone2: this.placePhone2,
      placePhone2CountryCodeName: this.placePhone2CountryCodeName,
      placePhone2CountryCode: this.placePhone2CountryCode,
      placePhone2WithCC: this.placePhone2WithCC,
      placePhone3: this.placePhone3,
      placePhone3CountryCodeName: this.placePhone3CountryCodeName,
      placePhone3CountryCode: this.placePhone3CountryCode,
      placePhone3WithCC: this.placePhone3WithCC,
      placePhone4: this.placePhone4,
      placePhone4CountryCodeName: this.placePhone4CountryCodeName,
      placePhone4CountryCode: this.placePhone4CountryCode,
      placePhone4WithCC: this.placePhone4WithCC,
      mapLink: this.mapLink,
      minimumReservationTime: this.minimumReservationTime,
      minimumReservationTimeStatus: this.minimumReservationTimeStatus,
      maximumReservationTime: this.maximumReservationTime,
      maximumReservationTimeStatus: this.maximumReservationTimeStatus,
      placeLatitude: this.placeLatitude,
      placeLongitude: this.placeLongitude,
      placeEndLatitude: this.placeEndLatitude,
      placeEndLongitude: this.placeEndLongitude,
      placeAddressEn: this.placeAddressEn,
      placeAddressAr: this.placeAddressAr,
      placeAddressUnd: this.placeAddressUnd,
      placeAddressCurrent: this.placeAddressCurrent,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      placeActiveStatus: this.placeActiveStatus,
      placeArchiveStatus: this.placeArchiveStatus,
    };
    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.placeToken == "" || this.placeToken == undefined) {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(`/Places/AddPlaceWithImage`, formData);
        } else {
          response = await axios.post(`/Places/AddPlaceWithoutImage`, data);
        }
      } else {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(`/Places/UpdatePlaceWithImage`, formData);
        } else {
          response = await axios.post(`/Places/UpdatePlaceWithoutImage`, data);
        }
      }

      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archivePlace(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      placeToken: this.placeToken,
    };

    try {
      let response = await axios.post(`/Places/ArchivePlace`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
