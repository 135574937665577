import { date2slash } from "@/utils/functions";
import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class Employee {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.employeeToken = "";
    this.employeeCode = "";
    this.employeeNameCurrent = "";
    this.employeeNameEn = "";
    this.employeeNameAr = "";
    this.employeeNameUnd = "";
    this.establishmentRoleToken = "";
    this.establishmentRoleNameCurrent = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.branchToken = "";
    this.employeeSectorToken = "";
    this.sectorNameCurrent = "";
    this.employeeJobToken = "";
    this.jobNameCurrent = "";
    this.employeeAddressCurrent = "";
    this.employeeAddressEn = "";
    this.employeeAddressAr = "";
    this.employeeAddressUnd = "";
    this.employeeCountryToken = "";
    this.countryNameCurrent = "";
    this.employeeGovernorateToken = "";
    this.governorateNameCurrent = "";
    this.employeeCityToken = "";
    this.cityNameCurrent = "";
    this.employeeDistrictToken = "";
    this.districtNameCurrent = "";
    this.employeeQualificationToken = "";
    this.qualificationNameCurrent = "";
    this.employeeBankToken = "";
    this.bankNameCurrent = "";
    this.employeeSpecialSpecialityToken = "";
    this.specialSpecialityNameCurrent = "";
    this.employeeBloodTypeToken = "";
    this.bloodTypeNameCurrent = "";
    this.employeeScientificDegreeToken = "";
    this.scientificDegreeNameCurrent = "";
    this.employeeCountryCodeName = "";
    this.employeeCountryCode = "";
    this.employeePhone = "";
    this.employeePhoneWithCC = "";
    this.employeeBirthdate = "";
    this.employeeUserName = "";
    this.employeeEmail = "";
    this.employeeDateTimeCreated = "";
    this.employeeNationalNumber = "";
    this.employeeNationalNumberExpireDate = "";
    this.employeeGenderToken = "";
    this.employeeGenderNameCurrent = "";
    this.employeeGenderNameAr = "";
    this.employeeGenderNameEn = "";
    this.employeeGenderNameUnd = "";
    this.employeeImagePath = "";
    this.employeeDrivingLicenceNumber = "";
    this.employeeDrivingLicenceExpireDate = "";
    this.employeePassportNumber = "";
    this.employeePassportExpireDate = "";
    this.employeeMaritalToken = "";
    this.employeeMaritalNameCurrent = "";
    this.employeeMaritalNameAr = "";
    this.employeeMaritalNameEn = "";
    this.employeeMaritalNameUnd = "";
    this.employeeMilitaryToken = "";
    this.employeeMilitaryNameCurrent = "";
    this.employeeMilitaryNameAr = "";
    this.employeeMilitaryNameEn = "";
    this.employeeMilitaryNameUnd = "";
    this.employeeMaritalStatusNotes = "";
    this.employeeMilitaryStatusNotes = "";
    this.employeeContractStartDate = "";
    this.employeeContractEndDate = "";
    this.employeeCitizenShipTypeToken = "";
    this.employeeCitizenShipTypeNameCurrent = "";
    this.employeeCitizenShipTypeNameAr = "";
    this.employeeCitizenShipTypeNameEn = "";
    this.employeeCitizenShipTypeNameUnd = "";
    this.employeeResidencyNumber = "";
    this.employeeResidencyStartDate = "";
    this.employeeResidencyEndDate = "";
    this.employeeTypeToken = "";
    this.employeeTypeNameCurrent = "";
    this.employeeTypeNameAr = "";
    this.employeeTypeNameEn = "";
    this.employeeTypeNameUnd = "";
    this.employeePassword = "";
    this.employeeNotes = "";
    this.employeeStartWorkTime = "";
    this.employeeEndWorkTime = "";
    this.employeeActiveStatus = true;
    this.employeeArchiveStatus = "";
  }
  fillData(data) {
    this.image = data.image;
    this.employeeToken = data.employeeToken;
    this.employeeCode = data.employeeCode;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeNameEn = data.employeeNameEn;
    this.employeeNameAr = data.employeeNameAr;
    this.employeeNameUnd = data.employeeNameUnd;
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.establishmentRoleNameCurrent = data.establishmentRoleNameCurrent;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.branchToken = data.branchToken;
    this.employeeSectorToken = data.employeeSectorToken;
    this.sectorNameCurrent = data.sectorNameCurrent;
    this.employeeJobToken = data.employeeJobToken;
    this.jobNameCurrent = data.jobNameCurrent;
    this.employeeAddressCurrent = data.employeeAddressCurrent;
    this.employeeAddressEn = data.employeeAddressEn;
    this.employeeAddressAr = data.employeeAddressAr;
    this.employeeAddressUnd = data.employeeAddressUnd;
    this.employeeCountryToken = data.employeeCountryToken;
    this.countryNameCurrent = data.countryNameCurrent;
    this.employeeGovernorateToken = data.employeeGovernorateToken;
    this.governorateNameCurrent = data.governorateNameCurrent;
    this.employeeCityToken = data.employeeCityToken;
    this.cityNameCurrent = data.cityNameCurrent;
    this.employeeDistrictToken = data.employeeDistrictToken;
    this.districtNameCurrent = data.districtNameCurrent;
    this.employeeQualificationToken = data.employeeQualificationToken;
    this.qualificationNameCurrent = data.qualificationNameCurrent;
    this.employeeBankToken = data.employeeBankToken;
    this.bankNameCurrent = data.bankNameCurrent;
    this.employeeSpecialSpecialityToken = data.employeeSpecialSpecialityToken;
    this.specialSpecialityNameCurrent = data.specialSpecialityNameCurrent;
    this.employeeBloodTypeToken = data.employeeBloodTypeToken;
    this.bloodTypeNameCurrent = data.bloodTypeNameCurrent;
    this.employeeScientificDegreeToken = data.employeeScientificDegreeToken;
    this.scientificDegreeNameCurrent = data.scientificDegreeNameCurrent;
    this.employeeCountryCodeName = data.employeeCountryCodeName;
    this.employeeCountryCode = data.employeeCountryCode;
    this.employeePhone = data.employeePhone;
    this.employeePhoneWithCC = data.employeePhoneWithCC;
    this.employeeBirthdate = data.employeeBirthdate;
    this.employeeUserName = data.employeeUserName;
    this.employeeEmail = data.employeeEmail;
    this.employeeDateTimeCreated = data.employeeDateTimeCreated;
    this.employeeNationalNumber = data.employeeNationalNumber;
    this.employeeNationalNumberExpireDate =
      data.employeeNationalNumberExpireDate;
    this.employeeGenderToken = data.employeeGenderToken;
    this.employeeGenderNameCurrent = data.employeeGenderNameCurrent;
    this.employeeGenderNameAr = data.employeeGenderNameAr;
    this.employeeGenderNameEn = data.employeeGenderNameEn;
    this.employeeGenderNameUnd = data.employeeGenderNameUnd;
    this.employeeImagePath = data.employeeImagePath;
    this.employeeDrivingLicenceNumber = data.employeeDrivingLicenceNumber;
    this.employeeDrivingLicenceExpireDate =
      data.employeeDrivingLicenceExpireDate;
    this.employeePassportNumber = data.employeePassportNumber;
    this.employeePassportExpireDate = data.employeePassportExpireDate;
    this.employeeMaritalToken = data.employeeMaritalToken;
    this.employeeMaritalNameCurrent = data.employeeMaritalNameCurrent;
    this.employeeMaritalNameAr = data.employeeMaritalNameAr;
    this.employeeMaritalNameEn = data.employeeMaritalNameEn;
    this.employeeMaritalNameUnd = data.employeeMaritalNameUnd;
    this.employeeMilitaryToken = data.employeeMilitaryToken;
    this.employeeMilitaryNameCurrent = data.employeeMilitaryNameCurrent;
    this.employeeMilitaryNameAr = data.employeeMilitaryNameAr;
    this.employeeMilitaryNameEn = data.employeeMilitaryNameEn;
    this.employeeMilitaryNameUnd = data.employeeMilitaryNameUnd;
    this.employeeMaritalStatusNotes = data.employeeMaritalStatusNotes;
    this.employeeMilitaryStatusNotes = data.employeeMilitaryStatusNotes;
    this.employeeContractStartDate = data.employeeContractStartDate;
    this.employeeContractEndDate = data.employeeContractEndDate;
    this.employeeCitizenShipTypeToken = data.employeeCitizenShipTypeToken;
    this.employeeCitizenShipTypeNameCurrent =
      data.employeeCitizenShipTypeNameCurrent;
    this.employeeCitizenShipTypeNameAr = data.employeeCitizenShipTypeNameAr;
    this.employeeCitizenShipTypeNameEn = data.employeeCitizenShipTypeNameEn;
    this.employeeCitizenShipTypeNameUnd = data.employeeCitizenShipTypeNameUnd;
    this.employeeResidencyNumber = data.employeeResidencyNumber;
    this.employeeResidencyStartDate = data.employeeResidencyStartDate;
    this.employeeResidencyEndDate = data.employeeResidencyEndDate;
    this.employeeTypeToken = data.employeeTypeToken;
    this.employeeTypeNameCurrent = data.employeeTypeNameCurrent;
    this.employeeTypeNameAr = data.employeeTypeNameAr;
    this.employeeTypeNameEn = data.employeeTypeNameEn;
    this.employeeTypeNameUnd = data.employeeTypeNameUnd;
    this.employeePassword = data.employeePassword;
    this.employeeNotes = data.employeeNotes;
    this.employeeStartWorkTime = data.employeeStartWorkTime;
    this.employeeEndWorkTime = data.employeeEndWorkTime;
    this.employeeActiveStatus = data.employeeActiveStatus;
    this.employeeArchiveStatus = data.employeeArchiveStatus;
  }

  async getAllEmployees(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    filter
  ) {
    try {
      let response = await axios.get(
        `/Employees/GetAllEmployees?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&establishmentRoleToken=${filter}&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEmployeeDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Employees/GetEmployeeDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeToken=${this.employeeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEmployees(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Employees/GetDialogOfEmployees?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEmployee(language, userAuthorizeToken) {
    if (this.employeeCCWithCCName) {
      let ccNameWithCC = this.employeeCCWithCCName.split(" ");
      this.employeeCountryCodeName = ccNameWithCC[0];
      this.employeeCountryCode = ccNameWithCC[1];
    }

    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      image: this.image,
      employeeToken: this.employeeToken,
      employeeCode: this.employeeCode,
      employeeNameCurrent: this.employeeNameCurrent,
      employeeNameEn: this.employeeNameEn,
      employeeNameAr: this.employeeNameAr,
      employeeNameUnd: this.employeeNameUnd,
      establishmentRoleToken: this.establishmentRoleToken,
      establishmentRoleNameCurrent: this.establishmentRoleNameCurrent,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      branchToken: this.branchToken,
      employeeSectorToken: this.employeeSectorToken,
      sectorNameCurrent: this.sectorNameCurrent,
      employeeJobToken: this.employeeJobToken,
      jobNameCurrent: this.jobNameCurrent,
      employeeAddressCurrent: this.employeeAddressCurrent,
      employeeAddressEn: this.employeeAddressEn,
      employeeAddressAr: this.employeeAddressAr,
      employeeAddressUnd: this.employeeAddressUnd,
      employeeCountryToken: this.employeeCountryToken,
      countryNameCurrent: this.countryNameCurrent,
      employeeGovernorateToken: this.employeeGovernorateToken,
      governorateNameCurrent: this.governorateNameCurrent,
      employeeCityToken: this.employeeCityToken,
      cityNameCurrent: this.cityNameCurrent,
      employeeDistrictToken: this.employeeDistrictToken,
      districtNameCurrent: this.districtNameCurrent,
      employeeQualificationToken: this.employeeQualificationToken,
      qualificationNameCurrent: this.qualificationNameCurrent,
      employeeBankToken: this.employeeBankToken,
      bankNameCurrent: this.bankNameCurrent,
      employeeSpecialSpecialityToken: this.employeeSpecialSpecialityToken,
      specialSpecialityNameCurrent: this.specialSpecialityNameCurrent,
      employeeBloodTypeToken: this.employeeBloodTypeToken,
      bloodTypeNameCurrent: this.bloodTypeNameCurrent,
      employeeScientificDegreeToken: this.employeeScientificDegreeToken,
      scientificDegreeNameCurrent: this.scientificDegreeNameCurrent,
      employeeCountryCodeName: this.employeeCountryCodeName,
      employeeCountryCode: this.employeeCountryCode,
      employeePhone: this.employeePhone,
      employeePhoneWithCC: this.employeePhoneWithCC,
      employeeBirthdate: this.employeeBirthdate,
      employeeUserName: this.employeeUserName,
      employeeEmail: this.employeeEmail,
      employeeDateTimeCreated: this.employeeDateTimeCreated,
      employeeNationalNumber: this.employeeNationalNumber,
      employeeNationalNumberExpireDate: date2slash(
        this.employeeNationalNumberExpireDate
      ),
      employeeGenderToken: this.employeeGenderToken,
      employeeGenderNameCurrent: this.employeeGenderNameCurrent,
      employeeGenderNameAr: this.employeeGenderNameAr,
      employeeGenderNameEn: this.employeeGenderNameEn,
      employeeGenderNameUnd: this.employeeGenderNameUnd,
      employeeImagePath: this.employeeImagePath,
      employeeDrivingLicenceNumber: this.employeeDrivingLicenceNumber,
      employeeDrivingLicenceExpireDate: date2slash(
        this.employeeDrivingLicenceExpireDate
      ),
      employeePassportNumber: this.employeePassportNumber,
      employeePassportExpireDate: date2slash(this.employeePassportExpireDate),
      employeeMaritalToken: this.employeeMaritalToken,
      employeeMaritalNameCurrent: this.employeeMaritalNameCurrent,
      employeeMaritalNameAr: this.employeeMaritalNameAr,
      employeeMaritalNameEn: this.employeeMaritalNameEn,
      employeeMaritalNameUnd: this.employeeMaritalNameUnd,
      employeeMilitaryToken: this.employeeMilitaryToken,
      employeeMilitaryNameCurrent: this.employeeMilitaryNameCurrent,
      employeeMilitaryNameAr: this.employeeMilitaryNameAr,
      employeeMilitaryNameEn: this.employeeMilitaryNameEn,
      employeeMilitaryNameUnd: this.employeeMilitaryNameUnd,
      employeeMaritalStatusNotes: this.employeeMaritalStatusNotes,
      employeeMilitaryStatusNotes: this.employeeMilitaryStatusNotes,
      employeeContractStartDate: date2slash(this.employeeContractStartDate),
      employeeContractEndDate: date2slash(this.employeeContractEndDate),
      employeeCitizenShipTypeToken: this.employeeCitizenShipTypeToken,
      employeeCitizenShipTypeNameCurrent:
        this.employeeCitizenShipTypeNameCurrent,
      employeeCitizenShipTypeNameAr: this.employeeCitizenShipTypeNameAr,
      employeeCitizenShipTypeNameEn: this.employeeCitizenShipTypeNameEn,
      employeeCitizenShipTypeNameUnd: this.employeeCitizenShipTypeNameUnd,
      employeeResidencyNumber: this.employeeResidencyNumber,
      employeeResidencyStartDate: date2slash(this.employeeResidencyStartDate),
      employeeResidencyEndDate: date2slash(this.employeeResidencyEndDate),
      employeeTypeToken: this.employeeTypeToken,
      employeeTypeNameCurrent: this.employeeTypeNameCurrent,
      employeeTypeNameAr: this.employeeTypeNameAr,
      employeeTypeNameEn: this.employeeTypeNameEn,
      employeeTypeNameUnd: this.employeeTypeNameUnd,
      employeePassword: this.employeePassword,
      employeeNotes: this.employeeNotes,
      employeeStartWorkTime: this.employeeStartWorkTime,
      employeeEndWorkTime: this.employeeEndWorkTime,
      employeeActiveStatus: this.employeeActiveStatus,
      employeeArchiveStatus: this.employeeArchiveStatus,
    };

    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.employeeToken == "" || this.employeeToken == undefined) {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Employees/AddEmployeeWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/Employees/AddEmployeeWithoutImage`,
            data
          );
        }
      } else {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Employees/UpdateEmployeeWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/Employees/UpdateEmployeeWithoutImage`,
            data
          );
        }
      }

      // console.log(response);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEmployee(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeToken: this.employeeToken,
    };

    try {
      let response = await axios.post(`Employees/ArchiveEmployee`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
