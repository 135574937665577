<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form class="my-edit-form" autocomplete="off" method="post" @submit.prevent>
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'placeToken'"
            :value="placePartData.placeToken"
            :options="placeTokenOptions"
            v-on:changeValue="placePartData.placeToken = $event"
            :title="$t('places.select')"
            :imgName="'places.svg'"
          />

          <!-- <CustomInput
            :className="'col-md-6'"
            :id="'placePartCode'"
            :value="placePartData.placePartCode"
            v-on:changeValue="placePartData.placePartCode = $event"
            :title="$t('placesParts.code')"
            :imgName="'code.svg'"
          /> -->
          <CustomInput
            :className="'col-md-6'"
            :id="'placePartNameAr'"
            :value="placePartData.placePartNameAr"
            v-on:changeValue="placePartData.placePartNameAr = $event"
            :title="$t('placesParts.nameAr')"
            :imgName="'PlacesParts.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'placePartNameEn'"
            :value="placePartData.placePartNameEn"
            v-on:changeValue="placePartData.placePartNameEn = $event"
            :title="$t('placesParts.nameEn')"
            :imgName="'PlacesParts.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'placePartNameUnd'"
            :value="placePartData.placePartNameUnd"
            v-on:changeValue="placePartData.placePartNameUnd = $event"
            :title="$t('placesParts.nameUnd')"
            :imgName="'PlacesParts.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'placePartDescriptionAr'"
            :value="placePartData.placePartDescriptionAr"
            v-on:changeValue="placePartData.placePartDescriptionAr = $event"
            :title="$t('placesParts.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'placePartDescriptionEn'"
            :value="placePartData.placePartDescriptionEn"
            v-on:changeValue="placePartData.placePartDescriptionEn = $event"
            :title="$t('placesParts.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'placePartDescriptionUnd'"
            :value="placePartData.placePartDescriptionUnd"
            v-on:changeValue="placePartData.placePartDescriptionUnd = $event"
            :title="$t('placesParts.descriptionUnd')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'placePartNotes'"
            :value="placePartData.placePartNotes"
            v-on:changeValue="placePartData.placePartNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button @click="editPartNow()" class="btn btn-submit">
          {{ $t("edit") }}
        </button>
        <router-link
          :to="{
            name: 'PlacesParts',
            params: { placeToken: placePartData.placeToken },
          }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";
import Place from "@/models/places/places/Place";
import createToastMixin from "@/utils/create-toast-mixin";

import { i18n } from "@/i18n/index.js";

export default {
  name: "PlacePartForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBox,
    TextArea,
  },
  data() {
    return {
      i18n,
      editing: true,
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      placeData: new Place(),
      placeTokenOptions: [],
    };
  },
  props: ["placePartData", "submitName", "itemObjectDetails"],
  methods: {
    async addOrUpdatePlacePart(placePartToken) {
      this.$emit("addOrUpdatePlacePart", placePartToken);
    },
    async editPartNow() {
      this.$emit("EditPlacePartsNow");
    },
    async getDialogOfPlaces() {
      this.isLoading = true;
      this.placeTokenOptions = [];
      // this.placeTokenOptions.push({
      //   value: "",
      //   text: this.$t("places.select"),
      // });
      try {
        const response = await this.placeData.getDialogOfPlaces(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.placeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text:
                this.language == "en"
                  ? itemsData[item]["itemNameEn"]
                  : itemsData[item]["itemNameAr"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.placeTokenOptions = null;
      }
      this.isLoading = false;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfPlaces();
    // console.log(this.placePartData);
  },
  cancel() {
    this.editing = false;
  },
};
</script>
