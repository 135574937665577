import axios from "axios";

import UsersPrivilegAdditionalSettings from "@/models/privileges/usersPrivileges/UsersPrivilegAdditionalSettings";
import UsersPrivilegeClients from "@/models/privileges/usersPrivileges/UsersPrivilegeClients";
import UsersPrivilegeEmployees from "@/models/privileges/usersPrivileges/UsersPrivilegeEmployees";
import UsersPrivilegeNews from "@/models/privileges/usersPrivileges/UsersPrivilegeNews";
import UsersPrivilegeOffers from "@/models/privileges/usersPrivileges/UsersPrivilegeOffers";
import UsersPrivilegeTransactionPointsHistory from "@/models/privileges/usersPrivileges/UsersPrivilegeTransactionPointsHistory";
import UsersPrivilegeUsers from "@/models/privileges/usersPrivileges/UsersPrivilegeUsers";
import UsersPrivilegeActivities from "@/models/privileges/usersPrivileges/UsersPrivilegeActivities";
import UsersPrivilegePlaces from "@/models/privileges/usersPrivileges/UsersPrivilegePlaces";
import UsersPrivilegePlacesMedia from "@/models/privileges/usersPrivileges/UsersPrivilegePlacesMedia";
import UsersPrivilegePlacesWorkPlans from "@/models/privileges/usersPrivileges/UsersPrivilegePlacesWorkPlans";
import UsersPrivilegePlacesWorkPlansDays from "@/models/privileges/usersPrivileges/UsersPrivilegePlacesWorkPlansDays";
import UsersPrivilegePlaceSchedule from "@/models/privileges/usersPrivileges/UsersPrivilegePlaceSchedule";
import UsersPrivilegeEstablishmentTreasuries from "@/models/privileges/usersPrivileges/UsersPrivilegeEstablishmentTreasuries";
import UsersPrivilegeEstablishmentExpenses from "@/models/privileges/usersPrivileges/UsersPrivilegeEstablishmentExpenses";
import UsersPrivilegeEstablishmentRevenues from "@/models/privileges/usersPrivileges/UsersPrivilegeEstablishmentRevenues";
import UsersPrivilegeEstablishmentTreasuryTransactions from "@/models/privileges/usersPrivileges/UsersPrivilegeEstablishmentTreasuryTransactions";
import UsersPrivilegeReservations from "@/models/privileges/usersPrivileges/UsersPrivilegeReservations";
import UsersPrivilegeReservationsSchedules from "@/models/privileges/usersPrivileges/UsersPrivilegeReservationsSchedules";
import UsersPrivilegeEnquiryReservations from "@/models/privileges/usersPrivileges/UsersPrivilegeEnquiryReservations";
import UsersPrivilegeReservationsSchedulesPayments from "@/models/privileges/usersPrivileges/UsersPrivilegeReservationsSchedulesPayments";
import UsersPrivilegeReservationsSchedulesCancle from "@/models/privileges/usersPrivileges/UsersPrivilegeReservationsSchedulesCancle";
import UsersPrivilegeReports from "@/models/privileges/usersPrivileges/UsersPrivilegeReports";

export default class UsersEstablishmentPrivilege {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentRoleToken = "";
    this.userEstablishmentPrivilegeToken = "";
    this.usersPrivilegeEmployees = new UsersPrivilegeEmployees();
    this.usersPrivilegAdditionalSettings =
      new UsersPrivilegAdditionalSettings();
    this.usersPrivilegeClients = new UsersPrivilegeClients();
    this.usersPrivilegeNews = new UsersPrivilegeNews();
    this.usersPrivilegeReservations = new UsersPrivilegeReservations();
    this.usersPrivilegeReservationsSchedules =
      new UsersPrivilegeReservationsSchedules();
    this.usersPrivilegeReservationsSchedulesPayments =
      new UsersPrivilegeReservationsSchedulesPayments();
    this.usersPrivilegeReservationsSchedulesCancle =
      new UsersPrivilegeReservationsSchedulesCancle();
    this.usersPrivilegeTransactionPointsHistory =
      new UsersPrivilegeTransactionPointsHistory();
    this.usersPrivilegeOffers = new UsersPrivilegeOffers();
    this.usersPrivilegeEnquiryReservations =
      new UsersPrivilegeEnquiryReservations();
    this.usersPrivilegeUsers = new UsersPrivilegeUsers();
    this.usersPrivilegeActivities = new UsersPrivilegeActivities();
    this.usersPrivilegePlaces = new UsersPrivilegePlaces();
    this.usersPrivilegePlacesMedia = new UsersPrivilegePlacesMedia();
    this.usersPrivilegePlacesWorkPlans = new UsersPrivilegePlacesWorkPlans();
    this.usersPrivilegePlacesWorkPlansDays =
      new UsersPrivilegePlacesWorkPlansDays();
    this.usersPrivilegePlaceSchedule = new UsersPrivilegePlaceSchedule();
    this.usersPrivilegeEstablishmentTreasuries =
      new UsersPrivilegeEstablishmentTreasuries();
    this.usersPrivilegeEstablishmentExpenses =
      new UsersPrivilegeEstablishmentExpenses();
    this.usersPrivilegeEstablishmentRevenues =
      new UsersPrivilegeEstablishmentRevenues();
    this.usersPrivilegeEstablishmentTreasuryTransactions =
      new UsersPrivilegeEstablishmentTreasuryTransactions();
    this.usersPrivilegeReports = new UsersPrivilegeReports();
  }
  fillData(data) {
    if (data) {
      this.establishmentRoleToken = data.establishmentRoleToken;
      this.userEstablishmentPrivilegeToken =
        data.userEstablishmentPrivilegeToken;
      this.usersPrivilegeEmployees.fillData(data.usersPrivilegeEmployees);
      this.usersPrivilegAdditionalSettings.fillData(
        data.usersPrivilegAdditionalSettings
      );
      this.usersPrivilegeClients.fillData(data.usersPrivilegeClients);
      this.usersPrivilegeNews.fillData(data.usersPrivilegeNews);
      this.usersPrivilegeReservations.fillData(data.usersPrivilegeReservations);
      this.usersPrivilegeReservationsSchedules.fillData(
        data.usersPrivilegeReservationsSchedules
      );
      this.usersPrivilegeReservationsSchedulesPayments.fillData(
        data.usersPrivilegeReservationsSchedulesPayments
      );
      this.usersPrivilegeReservationsSchedulesCancle.fillData(
        data.usersPrivilegeReservationsSchedulesCancle
      );
      this.usersPrivilegeTransactionPointsHistory.fillData(
        data.usersPrivilegeTransactionPointsHistory
      );
      this.usersPrivilegeOffers.fillData(data.usersPrivilegeOffers);
      this.usersPrivilegeEnquiryReservations.fillData(
        data.usersPrivilegeEnquiryReservations
      );
      this.usersPrivilegeUsers.fillData(data.usersPrivilegeUsers);
      this.usersPrivilegeActivities.fillData(data.usersPrivilegeActivities);
      this.usersPrivilegePlaces.fillData(data.usersPrivilegePlaces);
      this.usersPrivilegePlacesMedia.fillData(data.usersPrivilegePlacesMedia);
      this.usersPrivilegePlacesWorkPlans.fillData(
        data.usersPrivilegePlacesWorkPlans
      );
      this.usersPrivilegePlacesWorkPlansDays.fillData(
        data.usersPrivilegePlacesWorkPlansDays
      );
      this.usersPrivilegePlaceSchedule.fillData(
        data.usersPrivilegePlaceSchedule
      );
      this.usersPrivilegeEstablishmentTreasuries.fillData(
        data.usersPrivilegeEstablishmentTreasuries
      );
      this.usersPrivilegeEstablishmentExpenses.fillData(
        data.usersPrivilegeEstablishmentExpenses
      );
      this.usersPrivilegeEstablishmentRevenues.fillData(
        data.usersPrivilegeEstablishmentRevenues
      );
      this.usersPrivilegeEstablishmentTreasuryTransactions.fillData(
        data.usersPrivilegeEstablishmentTreasuryTransactions
      );
      this.usersPrivilegeReports.fillData(data.usersPrivilegeReports);
    } else {
      this.setInitialValue();
    }
  }

  async getUserEstablishmentPrivilege(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/UsersEstablishmentPrivilege//GetUserEstablishmentPrivilege?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentRoleToken=${this.establishmentRoleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async updateUserEstablishmentPrivilege(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRoleToken: this.establishmentRoleToken,
      usersPrivilegeEmployees: this.usersPrivilegeEmployees,
      usersPrivilegAdditionalSettings: this.usersPrivilegAdditionalSettings,
      usersPrivilegeClients: this.usersPrivilegeClients,
      usersPrivilegeNews: this.usersPrivilegeNews,
      usersPrivilegeReservations: this.usersPrivilegeReservations,
      usersPrivilegeReservationsSchedules:
        this.usersPrivilegeReservationsSchedules,
      usersPrivilegeReservationsSchedulesPayments:
        this.usersPrivilegeReservationsSchedulesPayments,
      usersPrivilegeReservationsSchedulesCancle:
        this.usersPrivilegeReservationsSchedulesCancle,
      usersPrivilegeTransactionPointsHistory:
        this.usersPrivilegeTransactionPointsHistory,
      usersPrivilegeOffers: this.usersPrivilegeOffers,
      usersPrivilegeEnquiryReservations: this.usersPrivilegeEnquiryReservations,
      usersPrivilegeUsers: this.usersPrivilegeUsers,
      usersPrivilegeActivities: this.usersPrivilegeActivities,
      usersPrivilegePlaces: this.usersPrivilegePlaces,
      usersPrivilegePlacesMedia: this.usersPrivilegePlacesMedia,
      usersPrivilegePlacesWorkPlans: this.usersPrivilegePlacesWorkPlans,
      usersPrivilegePlacesWorkPlansDays: this.usersPrivilegePlacesWorkPlansDays,
      usersPrivilegePlaceSchedule: this.usersPrivilegePlaceSchedule,
      usersPrivilegeEstablishmentTreasuries:
        this.usersPrivilegeEstablishmentTreasuries,
      usersPrivilegeEstablishmentExpenses:
        this.usersPrivilegeEstablishmentExpenses,
      usersPrivilegeEstablishmentRevenues:
        this.usersPrivilegeEstablishmentRevenues,
      usersPrivilegeEstablishmentTreasuryTransactions:
        this.usersPrivilegeEstablishmentTreasuryTransactions,
      usersPrivilegeReports: this.usersPrivilegeReports,
    };

    try {
      let response = await axios.post(
        `/UsersEstablishmentPrivilege//UpdateUserEstablishmentPrivilege`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
