<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div v-if="placesWorkPlansData">
      <PlacesWorkPlansTable
        :placesWorkPlansData="placesWorkPlansData"
        :placesWorkPlanData="placesWorkPlanData"
        v-on:setPlacesWorkPlanData="placesWorkPlanData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <PlacesWorkPlanInfo :placesWorkPlanData="placesWorkPlanData" />
      <PlacesWorkPlanDelete
        :placesWorkPlanData="placesWorkPlanData"
        v-on:refresh="getAllPlacesWorkPlans()"
      />
    </div>

    <PlacesWorkPlanBtns
      :textSearch="textSearch"
      :placeToken="filter.place"
      v-on:search="search"
    />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";

import PlacesWorkPlansTable from "@/components/places/placesWorkPlans/PlacesWorkPlansTable.vue";
import PlacesWorkPlanInfo from "@/components/places/placesWorkPlans/PlacesWorkPlanInfo.vue";
import PlacesWorkPlanDelete from "@/components/places/placesWorkPlans/PlacesWorkPlanDelete.vue";
import PlacesWorkPlanBtns from "@/components/places/placesWorkPlans/PlacesWorkPlanBtns.vue";
import PlacesWorkPlan from "@/models/places/placesWorkPlans/PlacesWorkPlan";

export default {
  name: "PlacesWorkPlans",
  mixins: [createToastMixin],
  components: {
    PlacesWorkPlansTable,
    PlacesWorkPlanInfo,
    PlacesWorkPlanDelete,
    PlacesWorkPlanBtns,
  },
  data() {
    return {
      placesWorkPlansData: null,
      placesWorkPlanData: new PlacesWorkPlan(),
      filter: {
        place: this.$route.params.placeToken,
      },
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllPlacesWorkPlans();
    },
    search(text, place) {
      this.textSearch = text;
      this.filter.place = place;
      this.getAllPlacesWorkPlans();
    },
    async getAllPlacesWorkPlans() {
      this.isLoading = true;
      try {
        const response = await this.placesWorkPlanData.getAllPlacesWorkPlans(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.textSearch,
          this.filter.place
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.placesWorkPlansData = response.data.placesWorkPlansData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.placesWorkPlansData = null;
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.placesWorkPlansData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.placesWorkPlansData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.placesWorkPlansData = null;
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllPlacesWorkPlans();
  },
};
</script>
