import axios from "axios";
import { PAGE_SIZE } from "@/utils/constants";

export default class PaymentMethode {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.paymentMethodeToken = "";
    this.paymentMethodeNameCurrent = "";
    this.paymentMethodeNameEn = "";
    this.paymentMethodeNameAr = "";
    this.paymentMethodeNameUnd = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.contractorToken = "";
    this.contractorNameCurrent = "";
    this.paymentMethodeDescriptionCurrent = "";
    this.paymentMethodeDescriptionEn = "";
    this.paymentMethodeDescriptionAr = "";
    this.paymentMethodeDescriptionUnd = "";
    this.paymentMethodeNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.paymentMethodeArchiveStatus = "";
  }
  fillData(data) {
    this.paymentMethodeToken = data.paymentMethodeToken;
    this.paymentMethodeNameCurrent = data.paymentMethodeNameCurrent;
    this.paymentMethodeNameEn = data.paymentMethodeNameEn;
    this.paymentMethodeNameAr = data.paymentMethodeNameAr;
    this.paymentMethodeNameUnd = data.paymentMethodeNameUnd;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.contractorToken = data.contractorToken;
    this.contractorNameCurrent = data.contractorNameCurrent;
    this.paymentMethodeDescriptionCurrent =
      data.paymentMethodeDescriptionCurrent;
    this.paymentMethodeDescriptionEn = data.paymentMethodeDescriptionEn;
    this.paymentMethodeDescriptionAr = data.paymentMethodeDescriptionAr;
    this.paymentMethodeDescriptionUnd = data.paymentMethodeDescriptionUnd;
    this.paymentMethodeNotes = data.paymentMethodeNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.paymentMethodeArchiveStatus = data.paymentMethodeArchiveStatus;
  }

  async getAllPaymentMethodes(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch
  ) {
    try {
      let response = await axios.get(
        `/PaymentMethodes/GetAllPaymentMethodes/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }
  async getPaymentMethodeDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PaymentMethodes/GetPaymentMethodeDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paymentMethodeToken=${this.paymentMethodeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }
  async getDialogOfPaymentMethodes(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/PaymentMethodes/GetDialogOfPaymentMethodes?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdatePaymentMethode(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      paymentMethodeToken: this.paymentMethodeToken,
      paymentMethodeNameCurrent: this.paymentMethodeNameCurrent,
      paymentMethodeNameEn: this.paymentMethodeNameEn,
      paymentMethodeNameAr: this.paymentMethodeNameAr,
      paymentMethodeNameUnd: this.paymentMethodeNameUnd,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      contractorToken: this.contractorToken,
      contractorNameCurrent: this.contractorNameCurrent,
      paymentMethodeDescriptionCurrent: this.paymentMethodeDescriptionCurrent,
      paymentMethodeDescriptionEn: this.paymentMethodeDescriptionEn,
      paymentMethodeDescriptionAr: this.paymentMethodeDescriptionAr,
      paymentMethodeDescriptionUnd: this.paymentMethodeDescriptionUnd,
      paymentMethodeNotes: this.paymentMethodeNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      paymentMethodeArchiveStatus: this.paymentMethodeArchiveStatus,
    };

    try {
      let response = "";
      if (
        this.paymentMethodeToken == "" ||
        this.paymentMethodeToken == undefined
      ) {
        response = await axios.post(`/PaymentMethodes/AddPaymentMethode`, data);
      } else {
        response = await axios.post(
          `/PaymentMethodes/UpdatePaymentMethode`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archivePaymentMethode(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      paymentMethodeToken: this.paymentMethodeToken,
    };

    try {
      let response = await axios.post(
        `/PaymentMethodes/ArchivePaymentMethode`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
