var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.ReservationsSchedules",modifiers:{"ReservationsSchedules":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("pages.ReservationsSchedules"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"ReservationsSchedules"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeReservationsSchedules.reservationsSchedulesApprove
            ),expression:"\n              usersPrivilegeReservationsSchedules.reservationsSchedulesApprove\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"reservationsSchedulesApprove"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeReservationsSchedules.reservationsSchedulesApprove
            )?_vm._i(
              _vm.usersPrivilegeReservationsSchedules.reservationsSchedulesApprove
            ,null)>-1:(
              _vm.usersPrivilegeReservationsSchedules.reservationsSchedulesApprove
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeReservationsSchedules.reservationsSchedulesApprove
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeReservationsSchedules, "reservationsSchedulesApprove", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeReservationsSchedules, "reservationsSchedulesApprove", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeReservationsSchedules, "reservationsSchedulesApprove", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"reservationsSchedulesApprove"}},[_vm._v(_vm._s(_vm.$t("approved")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }