export default class UsersPrivilegeEstablishmentTreasuries {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentTreasuryView = false;
    this.establishmentTreasuryAdd = false;
    this.establishmentTreasuryUpdate = false;
    this.establishmentTreasuryArchive = false;
    this.establishmentTreasuryRestore = false;
    this.establishmentTreasuryDelete = false;
  }
  fillData(data) {
    if (data) {
      this.establishmentTreasuryView = data.establishmentTreasuryView;
      this.establishmentTreasuryAdd = data.establishmentTreasuryAdd;
      this.establishmentTreasuryUpdate = data.establishmentTreasuryUpdate;
      this.establishmentTreasuryArchive = data.establishmentTreasuryArchive;
      this.establishmentTreasuryRestore = data.establishmentTreasuryRestore;
      this.establishmentTreasuryDelete = data.establishmentTreasuryDelete;
    } else {
      this.setInitialValue();
    }
  }
}
