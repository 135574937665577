<template>
  <div v-if="insuranceCompaniesData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("insuranceCompanies.name") }}</th>
            <th>{{ $t("insuranceCompanies.address") }}</th>
            <th>{{ $t("insuranceCompanies.description") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("edit") }}</th>
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(InsuranceCompany, index) in insuranceCompaniesData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>
              {{ isDataExist(InsuranceCompany.insuranceCompanyNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(InsuranceCompany.insuranceCompanyAddressCurrent) }}
            </td>
            <td>
              {{
                isDataExist(InsuranceCompany.insuranceCompanyDescriptionCurrent)
              }}
            </td>

            <td>
              <button
                v-b-modal.InsuranceCompanyInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setInsuranceCompanyData(InsuranceCompany)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <router-link
                :to="`/edit-insurance-company/${InsuranceCompany.insuranceCompanyToken}`"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.InsuranceCompanyDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setInsuranceCompanyData(InsuranceCompany)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { setDataMultiLang, timeToLang, isDataExist } from "@/utils/functions";

export default {
  name: "InsuranceCompaniesTable",
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["insuranceCompaniesData", "insuranceCompanyData"],
  methods: {
    setInsuranceCompanyData(insuranceCompanyData) {
      this.$emit("setInsuranceCompanyData", insuranceCompanyData);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
  },
  computed: {},
  async created() {},
};
</script>

<style lang="scss"></style>
