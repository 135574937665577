<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div v-if="countriesData">
      <CountriesTable
        :countriesData="countriesData"
        :countryData="countryData"
        v-on:setCountryData="countryData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />
      <CountryInfo :countryData="countryData" />
      <CountryDelete
        :countryData="countryData"
        v-on:refresh="getAllCountries()"
      />
    </div>

    <CountryBtns :textSearch="textSearch" v-on:search="search" />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import { STATUS } from "@/utils/constants";

import CountriesTable from "@/components/settings/settingsOther/countries/CountriesTable.vue";
import CountryInfo from "@/components/settings/settingsOther/countries/CountryInfo.vue";
import CountryDelete from "@/components/settings/settingsOther/countries/CountryDelete.vue";
import CountryBtns from "@/components/settings/settingsOther/countries/CountryBtns.vue";
import Country from "@/models/settings/settingsOther/countries/Country";

export default {
  name: "Countries",
  mixins: [createToastMixin],
  components: {
    CountriesTable,
    CountryInfo,
    CountryDelete,
    CountryBtns,
  },
  data() {
    return {
      countriesData: null,
      countryData: new Country(),
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllCountries();
    },
    search(text) {
      this.filterStatus = true;
      this.textSearch = text;
      this.getAllCountries();
    },
    async getAllCountries() {
      this.isLoading = true;
      try {
        const response = await this.countryData.getAllCountries(
          this.language,
          this.userAuthorizeToken,
          this.paginationData.selfPage,
          this.filterStatus,
          this.textSearch
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.countriesData = response.data.countriesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.countriesData = null;
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.countriesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.countriesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.countriesData = null;
        this.errorCatch();
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllCountries();
  },
};
</script>
