<template>
  <div class="limiter">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div class="container-login100">
      <div class="wrap-login100">
        <div class="login100-pic js-tilt" data-tilt>
          <img src="@/assets/images/sign-in.svg" alt="IMG" />
        </div>

        <form
          class="login100-form validate-form"
          method="post"
          @submit.prevent="signUp"
        >
          <div class="container-logo">
            <img src="@/assets/images/logo.png" alt="LOGO" class="logo" />
            <span class="title">{{ $t("clientSignUp") }}</span>
          </div>

          <div
            v-if="language == 'en'"
            class="form-label-group validate-input"
            data-validate="Valid email is required: ex@abc.xyz"
          >
            <input
              name="clientNameEn"
              id="clientNameEn"
              type="text"
              class="form-control"
              placeholder=" "
              maxlength="20"
              v-model="clientNameEn"
            />
            <label for="clientNameEn">
              {{ $t("userNameEn") }}
            </label>
            <img src="@/assets/images/man.svg" />
          </div>
          <div
            v-if="language == 'ar'"
            class="form-label-group validate-input"
            data-validate="Valid email is required: ex@abc.xyz"
          >
            <input
              name="clientNameAr"
              id="clientNameAr"
              type="text"
              class="form-control"
              placeholder=" "
              maxlength="20"
              v-model="clientNameAr"
            />
            <label for="clientNameAr">
              {{ $t("userNameAr") }}
            </label>
            <img src="@/assets/images/man.svg" />
          </div>
          <div
            class="form-label-group validate-input"
            data-validate="Valid email is required: ex@abc.xyz"
          >
            <input
              name="clientPhone"
              id="clientPhone"
              type="text"
              class="form-control"
              placeholder=" "
              maxlength="20"
              v-model="clientPhone"
            />
            <label for="clientPhone">
              {{ $t("phoneNumber") }}
            </label>
            <img src="@/assets/images/phone.svg" />
          </div>
          <div
            class="form-label-group validate-input"
            data-validate="Valid email is required: ex@abc.xyz"
          >
            <input
              name="email"
              id="email"
              type="text"
              class="form-control"
              placeholder=" "
              maxlength="20"
              v-model="clientEmail"
            />
            <label for="email">
              {{ $t("email") }}
            </label>
            <img src="@/assets/images/email.svg" />
          </div>

          <div
            class="form-label-group validate-input"
            data-validate="Password is required"
          >
            <input
              name="clientPassword"
              id="clientPassword"
              v-bind:type="[showPassword ? 'text' : 'password']"
              class="form-control password"
              placeholder=" "
              v-model="clientPassword"
            />
            <label for="clientPassword">{{ $t("password") }}</label>
            <img src="@/assets/images/passwordlock.svg" />
            <span class="toggle-password">
              <img
                :src="
                  require('@/assets/images/eye-' +
                    [showPassword ? 'show' : 'hide'] +
                    '.svg')
                "
                @click="showPassword = !showPassword"
              />
            </span>
          </div>

          <div class="container-login100-form-btn">
            <button name="signUp" type="submit" class="login100-form-btn">
              {{ $t("signUp") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS, USER_VERSION } from "@/utils/constants";
import axios from "axios";
import createToastMixin from "@/utils/create-toast-mixin";
import { USER_TYPE, HOME_PAGE } from "@/utils/constants";

export default {
  name: "SignUpForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
  },
  data() {
    return {
      isLoading: false,
      isSendVerifyCode: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      clientNameEn: "",
      clientNameAr: "",
      clientCountryCodeName: "EG",
      clientCountryCode: "+2",
      clientPhone: "",
      clientEmail: "",
      clientPassword: "",
      showPassword: false,
      filedType: "password",
    };
  },
  methods: {
    async signUp() {
      // send verification
      // await this.sendVerifyCode();
      // if (this.isSendVerifyCode) {
      //   //open verify code model
      // }
      this.isLoading = true;
      const data = {
        language: this.language,
        userPlatFormToken: USER_VERSION.userPlatFormToken,
        userFirebaseToken: USER_VERSION.userFirebaseToken,
        userVersionNumber: USER_VERSION.userVersionNumber,
        deviceNameEn: USER_VERSION.deviceNameEn,
        deviceVersion: USER_VERSION.deviceVersion,
        establishmentToken: "ESTB-1",

        clientNameEn: this.clientNameEn,
        clientNameAr: this.clientNameAr,
        clientCountryCodeName: this.clientCountryCodeName,
        clientCountryCode: this.clientCountryCode,
        clientPhone: this.clientPhone,
        clientEmail: this.clientEmail,
        clientPassword: this.clientPassword,
      };

      try {
        const response = await axios.post(`/Clients/ClientSignUp`, data);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$store.dispatch(
            "updateUserData",
            response.data.clientProfileData
          );
          this.$store.dispatch("updateUserType", USER_TYPE.client);
          this.$router.push(HOME_PAGE);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }

      this.isLoading = false;
    },
    togglePassword() {
      if (this.filedType === "password") {
        this.filedType = "text";
      } else {
        this.filedType = "password";
      }
    },
    async sendVerifyCode() {
      this.isLoading = true;
      const data = {
        language: this.language,
        verifyEmail: this.clientEmail,
      };
      try {
        const response = await axios.post(`/Verifies/SendVerifyCode`, data);
        if (response.data.status == STATUS.SUCCESS) {
          this.isSendVerifyCode = true;
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  computed: {},
};
</script>

