import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class District {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.districtToken = "";
    this.cityToken = "";
    this.cityNameCurrent = "";
    this.governorateToken = "";
    this.governorateNameCurrent = "";
    this.countryToken = "";
    this.countryNameCurrent = "";
    this.districtNameCurrent = "";
    this.districtNameEn = "";
    this.districtNameAr = "";
    this.districtNameUnd = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.districtDescriptionCurrent = "";
    this.districtDescriptionEn = "";
    this.districtDescriptionAr = "";
    this.districtDescriptionUnd = "";
    this.districtNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.districtArchiveStatus = "";
  }
  fillData(data) {
    this.districtToken = data.districtToken;
    this.cityToken = data.cityToken;
    this.cityNameCurrent = data.cityNameCurrent;
    this.governorateToken = data.governorateToken;
    this.governorateNameCurrent = data.governorateNameCurrent;
    this.countryToken = data.countryToken;
    this.countryNameCurrent = data.countryNameCurrent;
    this.districtNameCurrent = data.districtNameCurrent;
    this.districtNameEn = data.districtNameEn;
    this.districtNameAr = data.districtNameAr;
    this.districtNameUnd = data.districtNameUnd;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.districtDescriptionCurrent = data.districtDescriptionCurrent;
    this.districtDescriptionEn = data.districtDescriptionEn;
    this.districtDescriptionAr = data.districtDescriptionAr;
    this.districtDescriptionUnd = data.districtDescriptionUnd;
    this.districtNotes = data.districtNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.districtArchiveStatus = data.districtArchiveStatus;
  }

  async getAllDistricts(
    language,
    userAuthorizeToken,
    selfPage,
    textSearch,
    countryToken = "",
    governorateToken = "",
    cityToken = ""
  ) {
    try {
      let response = await axios.get(
        `/Districts/GetAllDistricts?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${selfPage}&paginationStatus=&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}&sortToken=&countryToken=${countryToken}&governorateToken=${governorateToken}&cityToken=${cityToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDistrictDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Districts/GetDistrictDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&districtToken=${this.districtToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfDistricts(language, userAuthorizeToken, cityToken = "") {
    try {
      let response = await axios.get(
        `/Districts/GetDialogOfDistricts?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&cityToken=${cityToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateDistrict(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      districtToken: this.districtToken,
      cityToken: this.cityToken,
      cityNameCurrent: this.cityNameCurrent,
      governorateToken: this.governorateToken,
      governorateNameCurrent: this.governorateNameCurrent,
      countryToken: this.countryToken,
      countryNameCurrent: this.countryNameCurrent,
      districtNameCurrent: this.districtNameCurrent,
      districtNameEn: this.districtNameEn,
      districtNameAr: this.districtNameAr,
      districtNameUnd: this.districtNameUnd,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      districtDescriptionCurrent: this.districtDescriptionCurrent,
      districtDescriptionEn: this.districtDescriptionEn,
      districtDescriptionAr: this.districtDescriptionAr,
      districtDescriptionUnd: this.districtDescriptionUnd,
      districtNotes: this.districtNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      districtArchiveStatus: this.districtArchiveStatus,
    };
    try {
      let response = "";
      if (this.districtToken == "" || this.districtToken == undefined) {
        response = await axios.post(`/Districts/AddDistrict`, data);
      } else {
        response = await axios.post(`/Districts/UpdateDistrict`, data);
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveDistrict(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      districtToken: this.districtToken,
    };

    try {
      let response = await axios.post(`/Districts/ArchiveDistrict`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
