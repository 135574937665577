<template>
  <div class="">
    <b-button v-b-toggle.activities class="btn btn-lg btn-collapse">
      {{ $t("setting.others.activities") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="activities">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="activityView"
              v-model="usersPrivilegeActivities.activityView"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="activityView">{{ $t("view") }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="activityAdd"
              v-model="usersPrivilegeActivities.activityAdd"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="activityAdd">{{ $t("add") }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="activityUpdate"
              v-model="usersPrivilegeActivities.activityUpdate"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="activityUpdate">{{
              $t("edit")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="activityArchive"
              v-model="usersPrivilegeActivities.activityArchive"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="activityArchive">{{
              $t("archive")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="activityDelete"
              v-model="usersPrivilegeActivities.activityDelete"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="activityDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="activityRestore"
              v-model="usersPrivilegeActivities.activityRestore"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="activityRestore">{{
              $t("restore")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegeActivities",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeActivities"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
