import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class Activity {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.activityToken = "";
    this.establishmentToken = "ESTB-1";
    this.establishmentNameCurrent = "";
    this.contractorToken = "";
    this.contractorNameCurrent = "";
    this.activityCode = "";
    this.activityNameCurrent = "";
    this.activityNameEn = "";
    this.activityNameAr = "";
    this.activityNameUnd = "";
    this.activityImagePath = "";
    this.activityDescriptionCurrent = "";
    this.activityDescriptionEn = "";
    this.activityDescriptionAr = "";
    this.activityDescriptionUnd = "";
    this.activityNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.activityArchiveStatus = "";
  }
  fillData(data) {
    this.image = data.image;
    this.activityToken = data.activityToken;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.contractorToken = data.contractorToken;
    this.contractorNameCurrent = data.contractorNameCurrent;
    this.activityCode = data.activityCode;
    this.activityNameCurrent = data.activityNameCurrent;
    this.activityNameEn = data.activityNameEn;
    this.activityNameAr = data.activityNameAr;
    this.activityNameUnd = data.activityNameUnd;
    this.activityImagePath = data.activityImagePath;
    this.activityDescriptionCurrent = data.activityDescriptionCurrent;
    this.activityDescriptionEn = data.activityDescriptionEn;
    this.activityDescriptionAr = data.activityDescriptionAr;
    this.activityDescriptionUnd = data.activityDescriptionUnd;
    this.activityNotes = data.activityNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.activityArchiveStatus = data.activityArchiveStatus;
  }

  async getAllActivities(language, userAuthorizeToken, selfPage, textSearch) {
    try {
      let response = await axios.get(
        `Activities/GetAllActivities/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&page=${selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getActivityDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `Activities/GetActivityDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&activityToken=${this.activityToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfActivities(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Activities/GetDialogOfActivities?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateActivity(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      image: this.image,
      activityToken: this.activityToken,
      establishmentToken: this.establishmentToken,
      establishmentNameCurrent: this.establishmentNameCurrent,
      contractorToken: this.contractorToken,
      contractorNameCurrent: this.contractorNameCurrent,
      activityCode: this.activityCode,
      activityNameCurrent: this.activityNameCurrent,
      activityNameEn: this.activityNameEn,
      activityNameAr: this.activityNameAr,
      activityNameUnd: this.activityNameUnd,
      activityImagePath: this.activityImagePath,
      activityDescriptionCurrent: this.activityDescriptionCurrent,
      activityDescriptionEn: this.activityDescriptionEn,
      activityDescriptionAr: this.activityDescriptionAr,
      activityDescriptionUnd: this.activityDescriptionUnd,
      activityNotes: this.activityNotes,
      actionDateTime: this.actionDateTime,
      actionDate: this.actionDate,
      actionTime: this.actionTime,
      activityArchiveStatus: this.activityArchiveStatus,
    };
    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.activityToken == "" || this.activityToken == undefined) {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Activities/AddActivityWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/Activities/AddActivityWithoutImage`,
            data
          );
        }
      } else {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Activities/UpdateActivityWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/Activities/UpdateActivityWithoutImage`,
            data
          );
        }
      }

      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveActivity(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      activityToken: this.activityToken,
    };

    try {
      let response = await axios.post(`/Activities/ArchiveActivity`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
