import { render, staticRenderFns } from "./UserRoleDelete.vue?vue&type=template&id=21eff6f1&scoped=true&"
import script from "./UserRoleDelete.vue?vue&type=script&lang=js&"
export * from "./UserRoleDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21eff6f1",
  null
  
)

export default component.exports