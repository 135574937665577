export default class UsersPrivilegeEstablishmentRevenues {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentRevenuesView = false;
    this.establishmentRevenuesAdd = false;
    this.establishmentRevenuesRefund = false;
  }
  fillData(data) {
    if (data) {
      this.establishmentRevenuesView = data.establishmentRevenuesView;
      this.establishmentRevenuesAdd = data.establishmentRevenuesAdd;
      this.establishmentRevenuesRefund = data.establishmentRevenuesRefund;
    } else {
      this.setInitialValue();
    }
  }
}
