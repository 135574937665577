<template>
  <div class="delete-model-container w-50">
    <header id="PlacePartInfo___BV_modal_header_" class="modal-header">
      <h5 id="PlacePartInfo___BV_modal_title_" class="modal-title">
        <h3>
          <img src="/img/alert.761d5cd9.svg" class="icon-lg" />
          <span> {{ $t("alert") }}</span>
        </h3>
      </h5>
    </header>
    <div class="my-edit-form-div">
      <button
        @click="CloseDelete()"
        type="button"
        aria-label="Close"
        class="close bg-danger close-delete-model p-2 rounded-circle m-2"
      >
        ×
      </button>

      <h4 data-v-46a79a47="" class="text-center">
        {{ $t("areYouSureToDelete") }}
      </h4>
      <div class="btn-delete-cancel-container">
        <button @click="CloseDelete()" class="btn btn-primary">
          {{ $t("no") }}
        </button>
        <button @click="deletePlacePart(placePartToken)" class="btn btn-danger">
          {{ $t("yes") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { i18n } from "@/i18n/index.js";
export default {
  name: "PlacePartsDelete",
  data() {
    return {
      i18n,
    };
  },
  props: {
    props: {
      deletePlaceParts: {
        type: Boolean,
      },
    },
  },
  methods: {
    deletePlacePart() {
      const placePartToken = this.placePartToken; // Get the placePartToken from the component's data property
      this.$emit("deletePlacePart", placePartToken);
    },
    CloseDelete() {
      this.$emit("CloseDelete");
    },
  },
};
</script>
<style lang="scss">
.my-edit-form-div {
  //   height: 200px;
  padding: 10%;
  border: 1px solid gray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.delete-model-container {
  position: relative;
  position: absolute;
  top: 20%;
  left: 20%;
  background-color: rgb(255, 255, 255);
  z-index: 999;
}
.close-delete-model {
  color: white !important;
  background-color: red !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.btn-delete-cancel-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & button {
    // margin: 20% auto;
    // width: 40%;
    // height: 100%;
    color: #fff;
    display: inline-block;
    padding: 5px 20px;
    margin-top: 20px;
    -webkit-margin-start: 5px;
    margin-inline-start: 5px;
    border-radius: 25px 5px;
    transition: 0.5s;
    width: 46%;
  }
}
</style>
