export default class UsersPrivilegeEstablishmentTreasuryTransactions {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentTreasuryTransactionsView = false;
    this.establishmentTreasuryTransactionsAdd = false;
  }
  fillData(data) {
    if (data) {
      this.establishmentTreasuryTransactionsView =
        data.establishmentTreasuryTransactionsView;
      this.establishmentTreasuryTransactionsAdd =
        data.establishmentTreasuryTransactionsAdd;
    } else {
      this.setInitialValue();
    }
  }
}
