export default class UsersPrivilegeReservationsSchedulesCancle {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationsSchedulesCancelView = false;
    this.reservationsSchedulesCancelAdd = false;
    this.reservationsSchedulesCancelApprov = false;
    this.reservationsSchedulesCancelActionCancel = false;
  }
  fillData(data) {
    if (data) {
      this.reservationsSchedulesCancelView =
        data.reservationsSchedulesCancelView;
      this.reservationsSchedulesCancelAdd = data.reservationsSchedulesCancelAdd;
      this.reservationsSchedulesCancelApprov =
        data.reservationsSchedulesCancelApprov;
      this.reservationsSchedulesCancelActionCancel =
        data.reservationsSchedulesCancelActionCancel;
    } else {
      this.setInitialValue();
    }
  }
}
