<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="offersData">
      <div class="container-fluid">
        <div v-if="paginationData.selfPage == 1" class="row first-row">
          <div
            v-if="offersData.length > 0"
            class="col-12 col-sm-12 col-md-12 col-lg-8 col main"
          >
            <OfferCard
              :offerData="offersData[0]"
              v-on:setOfferData="offerData.fillData($event)"
            />
          </div>
          <div
            v-if="offersData.length >= 1"
            class="col-12 col-sm-12 col-md-12 col-lg-4 no-padding col"
          >
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-12 col half-height">
                <OfferCard
                  :offerData="offersData[1]"
                  v-on:setOfferData="offerData.fillData($event)"
                />
              </div>
              <div
                v-if="offersData.length >= 2"
                class="col-12 col-sm-12 col-md-6 col-lg-12 half-height second"
              >
                <OfferCard
                  :offerData="offersData[2]"
                  v-on:setOfferData="offerData.fillData($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="offersData.length >= 3" class="row">
          <div
            v-for="(item, index) in offersData.slice(3)"
            :key="index"
            class="col-12 col-sm-12 col-md-6 col-lg-4 col"
          >
            <OfferCard
              :offerData="item"
              v-on:setOfferData="offerData.fillData($event)"
            />
          </div>
        </div>
      </div>

      <Pagination
        v-if="!isLoading"
        :paginationData="paginationData"
        v-on:changePage="changePage"
      />

      <OfferDelete :offerData="offerData" v-on:refresh="getAllOffers()" />
    </div>

    <OfferBtns
      :textSearch="textSearch"
      :placeToken="filter.place"
      v-on:search="search"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import OfferCard from "@/components/offers/OfferCard.vue";
import OfferDelete from "@/components/offers/OfferDelete.vue";
import OfferBtns from "@/components/offers/OfferBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Offer from "@/models/offers/Offer";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "Offers",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    OfferCard,
    OfferDelete,
    OfferBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      listMediaTypes: this.$store.getters.userData.constantsLists.listMediaType,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      filter: {
        place: this.$route.params.placeToken,
      },
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      offersData: null,
      offerData: new Offer(),
    };
  },
  methods: {
    changePage(page) {
      this.paginationData.selfPage = page;
      this.getAllOffers();
    },
    search(text, place) {
      this.textSearch = text;
      this.filter.place = place;
      this.getAllOffers();
    },
    async getAllOffers() {
      this.isLoading = true;
      try {
        const response = await this.offerData.getAllOffers(
          this.language,
          this.userAuthorizeToken,
          this.selfPage,
          this.textSearch,
          this.filter.place
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.paginationData = {
            totalPages: response.data.pagination.totalPages,
            totalItems: response.data.pagination.totalItems,
            countItemsInPage: response.data.pagination.countItemsInPage,
            selfPage: response.data.pagination.selfPage,
          };
          this.offersData = response.data.offersData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.offersData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.offersData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.offersData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.offersData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllOffers();
  },
};
</script>
